<template>
  <Modal
    title="We're Sorry"
    subTitle="Jurisdiction Unavailable"
    cancelLabel="Close"
    @close="close"
  >
    <div class="message">
      <p>MoonPay cannot allow transactions within your jurisdiction at this time. However, they are working hard to gain compliance. You can check <a href="https://support.moonpay.com/hc/en-gb/articles/360009279877-What-are-your-supported-countries-states-and-territories-" target="_blank">here</a> for availability.</p>
    </div>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  name: 'MoonPayUnavailableModal',
  methods: {
    ...mapMutations([
      'setGlobalState'
    ]),
    close () {
      this.setGlobalState({ target: 'showingMoonPayUnsupported', val: false })
    }
  }
}
</script>

<style scoped lang="scss">
.modal {
  ::v-deep .central .message {
    margin: 0 !important;
  }
}
</style>
