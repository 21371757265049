<template>
  <Modal
    class="login-modal"
    @close="setShowingLogin(false)"
    title="Sign Up/In"
    :subTitle="subtitle"
    hideActions
  >
    <div class="central-content">
      <template v-if="!emailSent || reenterEmail">
        <div class="email-container">
          <input
            v-model="email"
            type="email"
            name="email"
            placeholder="email"
            required
            autocomplete="on"
            autocompletetype="email"
            @keyup="handleKeyUp"
          />
          <div v-if="reenterEmail" class="message">
            <img class="icon" src="@/assets/icons/rejected.svg" alt="Warning" />
            <p>
              Please ensure that this is the same browser you used to send the
              initial login email.
            </p>
          </div>
          <div class="message legal">
            <v-checkbox v-model="agreed" :ripple="false" hide-details dark />
            <p>
              By Signing Up/In you are agreeing to the
              <router-link to="/terms" @click.native="closeLogin">Terms of Service</router-link> and <router-link to="/privacy" @click.native="closeLogin">Privacy Policy</router-link>.
            </p>
          </div>
          <button
            class="submit-button standard option"
            :disabled="!canSubmit"
            @click="loginAction"
          >
            <v-progress-circular
              v-if="isSendingEmail"
              indeterminate
            />
            <span :class="{ visible: !isSendingEmail }">SUBMIT</span>
          </button>
        </div>
      </template>
      <template v-else>
        <div class="resend-container">
          <p class="message sent-text">
            An email with your personal login will be sent to the email address
            you provided.
          </p>
          <br />
          <p class="having-trouble">Having Trouble?</p>
          <button
            :disabled="resent"
            class="submit-button standard option"
            @click="loginAction"
          >
            {{ resent ? "Email Resent" : "Resend Email" }}
          </button>
          <p class="action-link" @click="emailSent = false">
            Try a different email.
          </p>
        </div>
      </template>
    </div>
  </Modal>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'LoginModal',
  data () {
    return {
      email: '',
      agreed: false,
      emailSent: false,
      resent: false,
      isSendingEmail: false
    }
  },
  computed: {
    ...mapGetters(['userProfile', 'reenterEmail']),
    subtitle () {
      if (this.emailSent) {
        return 'Check your inbox'
      } else if (this.reenterEmail) {
        return 'Reenter your email below'
      } else {
        return 'Enter your email below'
      }
    },
    canSubmit () {
      return this.agreed && this.email && !this.isSendingEmail
    }
  },

  methods: {
    ...mapActions([
      'loginWithLink'
    ]),
    ...mapMutations([
      'setShowingLogin',
      'setLoginEmail'
    ]),
    loginAction () {
      this.reenterEmail
        ? this.loginWithLink(this.email)
        : this.passwordlessLogin()
    },

    /**
     * AUTH STEP 1
     * Send a login email with auth token via SendGrid API.
     */
    async passwordlessLogin () {
      this.isSendingEmail = true
      try {
        await this.fbCall('sendgrid-sendSignInLinkToEmail', {
          email: this.email,
          redirectUrl: window.location.href,
          // add timestamp as the last line of the email to prevent gmail from collapsing
          // and trimming this email with ellipsis in the chain
          timestamp: new Date().toLocaleString()
        })
        if (this.emailSent) this.resent = true
        this.emailSent = true
        window.localStorage.setItem('emailForSignIn', this.email)
      } catch (err) {
        console.log('Error sending login email:', err)
        this.addNotification(err.message, 'error')
      }
      this.isSendingEmail = false
    },

    handleKeyUp (e) {
      if (e.key === 'Enter') {
        e.preventDefault()
        if (this.canSubmit) {
          console.log('Login with enter key.')
          this.loginAction()
        }
      }
    },

    closeLogin () {
      this.setShowingLogin(false)
    }
  }
}
</script>

<style lang="scss" scoped>
.modal .central {
  .central-content {
    width: 100%;

    @include media-height(720px) {
      .message {
        font-size: 10px;
      }
    }

    .legal {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      text-align: left;

      #checkbox {
        margin-top: 3px;
        margin-right: 10px;
        background: none;
      }
    }

    .email-container,
    .resend-container {
      input {
        font-family: $font-title;
        text-transform: uppercase;
        color: $blk-white;
      }
    }

    .email-container {
      margin: $space-l 0;
      @include media-height(720px) {
        margin: $space-m 0 0;
      }
      .v-input--checkbox {
        margin: 0;
        padding: 0;
      }
      input[type="email"] {
        width: 100%;
      }
      input {
        border-bottom: solid 1px $blk-white;
        text-align: center;
        text-transform: none;
        font-size: 18px;
        &::placeholder {
          font-family: $font-body;
          text-transform: uppercase;
        }
      }

      input:focus,
      input:active {
        background: none;
        outline: none;
      }
      a {
        color: $blk-white;
      }
    }

    .resend-container {
      .having-trouble {
        color: $blk-grey-3;
        font-size: 14px;
        margin: 20px;
        text-transform: uppercase;
      }
    }
  }
  .v-icon {
    color: $blk-white !important;
  }
  .submit-button {
    position: relative;

    span {
      opacity: 0;
      transition: 0.3s all;

      &.visible {
        opacity: 1;
        transition: 0.3s all;
      }
    }

    .v-progress-circular {
      @include center-in-parent;
    }

    &:disabled {
      pointer-events: none;
    }
  }
}
</style>
