<template>
  <div class="rich-text-editor">
    <BubbleMenu v-if="editor" :editor="editor">
      <button @click="editor.chain().focus().toggleBold().run()">
        <v-icon>mdi-format-bold</v-icon>
      </button>
      <button @click="editor.chain().focus().toggleItalic().run()">
        <v-icon>mdi-format-italic</v-icon>
      </button>
      <button @click="editor.chain().focus().toggleBulletList().run()">
        <v-icon>mdi-format-list-bulleted</v-icon>
      </button>
      <button @click="toggleLink()">
        <v-icon>mdi-link</v-icon>
      </button>
    </BubbleMenu>
    <EditorContent :editor="editor" />
  </div>
</template>

<script>
import { Editor, EditorContent, BubbleMenu } from '@tiptap/vue-2'
import Link from '@tiptap/extension-link'
import StarterKit from '@tiptap/starter-kit'
export default {
  name: 'RichTextEditor',
  components: {
    EditorContent,
    BubbleMenu
  },

  props: {
    value: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      editor: null
    }
  },

  methods: {
    toggleLink () {
      const previousUrl = this.editor.getAttributes('link').href
      const url = window.prompt('URL', previousUrl)

      // cancelled
      if (url === null) {
        return
      }

      this.editor.chain().focus().toggleLink({ href: url }).run()
    }
  },

  watch: {
    value (value) {
      // const isSame = this.editor.getHTML === value

      // if (isSame) return

      // if (!this.editor.focused) {
      //   this.editor.commands.setContent(value, false)
      // }
    }
  },

  mounted () {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        StarterKit,
        Link
      ],
      onUpdate: () => {
        this.$emit('input', this.editor.getHTML())
      }
    })
  },

  beforeDestroy () {
    this.editor.destroy()
  }
}
</script>

<style lang="scss" scoped>
.rich-text-editor {
  margin-top: $space-m;
}
.tippy-box {
  button {
    background-color: $blk-green;
    border: solid 2px black;
    border-radius: 4px;
    padding: 4px;
    margin: 2px;
    .v-icon {
      color: $blk-black;
    }
  }
}
// Address white space removal issue
::v-deep .ProseMirror {
  white-space: normal !important;
  outline: none;
}
</style>
