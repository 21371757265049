<!--
  Global navigation displayed in site header.
-->
<template>
  <div class="nav-container">
    <div class="max-container">
      <router-link :to="homeLink" class="home">
        <img
          v-if="globalSettings && globalSettings.useLogo"
          :src="logoUrl()"
          :alt="globalSettings.marketName || 'Market Name'"
          class="logo"
        />
        <h1 v-else class="logo">
          {{ globalSettings && globalSettings.marketName }}
        </h1>
      </router-link>
      <div v-if="siteEnabled" id="nav">
        <nav>
          <router-link
            v-if="globalSettings.featuredEvent"
            :to="globalSettings.featuredEvent.url"
            :class="{ active: $route.path == globalSettings.featuredEvent.url }"
          >
            {{ globalSettings.featuredEvent.name }}
          </router-link>
          <router-link
            v-bind:class="{ active: isCurrentRoute('drops') }"
            to="/drops"
            >Drops</router-link
          >
          <router-link
            v-bind:class="{ active: isCurrentRoute('market') }"
            to="/market"
          >
            Market
          </router-link
          >
          <router-link
            v-if="userProfile"
            v-bind:class="{ active: isCurrentRoute('portfolio') }"
            to="/portfolio"
            >Portfolio</router-link
          >
        </nav>
        <div
          class="profile-icon"
          @mouseover="showingModal = true"
          @mouseleave="showingModal = false"
        >
          <div v-if="userProfile" class="profile-hitbox" @click="goToProfile" />
          <template v-if="userProfile">
            <CryptoFiat
              :crypto="profileBalance"
              class="balance"
              hideUsd
            />
            <Avatar
              :image="userProfile.image ? dynamicLink(userProfile.image, 'ar_1.0,c_fill,w_40') : ''"
              :seed="userProfile.nearId || null"
            >
              <div
                class="menu-modal"
                :class="{ visible: showingModal }"
              >
                <NavigationLinks />
              </div>
            </Avatar>
          </template>
          <nav v-else>
            <div class="sign-up" @click="initiateLogin">
              SIGN UP/IN
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import NavigationLinks from '@/components/NavigationLinks.vue'
import { isCurrentRoute } from '@/common/utils'
export default {
  components: {
    NavigationLinks
  },
  data () {
    return {
      showingModal: false
    }
  },
  computed: {
    ...mapGetters([
      'globalSettings',
      'userProfile',
      'profileBalance',
      'siteEnabled'
    ]),
    homeLink () {
      return this.globalSettings && this.globalSettings.useCollectionGroups ? (this.globalSettings.marketHome || '/collection-groups') : '/market'
    }
  },
  methods: {
    ...mapActions([
      'initiateLogin'
    ]),
    isCurrentRoute (linkName) {
      return isCurrentRoute(this.$route.name, linkName)
    },
    goToProfile () {
      if (!this.isCurrentRoute('profile')) {
        this.$router.push('/profile')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-container {
  z-index: $z-nav;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: transparent;
  height: 80px;
  padding: 50px 40px;

  @include media($bp-desktop-s) {
    display: none;
  }

  .max-container {
    position: relative;
    display: flex;
    align-items: center;
    max-width: $navbar-max;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 0 $space-mm;

    @include media($bp-tablet) {
      padding: 0 $space-ms;
    }
  }
}
img.logo {
  height: 31px;
}
h1.logo {
  font-family: $font-title;
  font-weight: normal;
  padding: $space-mm 0;
}
a.home {
  text-decoration: none;
}
#nav {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  nav {
    a {
      display: inline-block;
      padding-right: 48px;
      @include nav-link;

      @include media($bp-desktop-m) {
        padding-right: 24px;
      }
    }
    .sign-up {
      @include nav-link;
    }

    .active {
      text-decoration: line-through;
      text-decoration-thickness: 4px;
    }
  }

  .profile-icon {
    position: relative;
    display: inline-block;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    text-align: right;

    &::after {
      display: none;
    }

    .profile-hitbox {
      @include center-in-parent;

      z-index: $z-ui-front;
      width: 100%;
      height: 34px;
    }

    .balance {
      font-family: Drive Mono;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 130%;
      margin-right: $space-s;
      text-transform: uppercase;
    }

    .avatar {
      display: inline-block;
      vertical-align: middle;
      width: 34px;
      height: 34px;
    }
  }

  .menu-modal {
    background-color: $bg-black;
    border: 1px solid #ffffff;
    border-radius: 4px;
    bottom: -$space-mm;
    right: 105px;
    color: $blk-grey-2;
    font-family: $font-body;
    pointer-events: none;
    opacity: 0;
    padding: 25px;
    position: absolute;
    text-align: left;
    transform: translateX(50%) translateY(110%);
    transition: .3s all;
    width: 210px;

    .links-container {
      a {
        color: $blk-grey-2;
      }
    }

    &.visible {
      opacity: 1;
      pointer-events: all;
      transform: translateX(50%) translateY(100%);
      transition: .3s all;
    }

    &::before {
      content: '';
      position: absolute;
      width: 60%;
      height: $space-xl;
      top: -$space-xl;
      right: 0;
    }

    &::after {
      display: none;
      background-color: $blk-grey-6;
      position: absolute;
      margin-left: -15px;
      left: 50%;
      width: 30px;
      height: 30px;
      top: 0px;
      content: '';

      @media screen and (max-width: 1280px) {
        left: 100%;
        opacity: 0;
      }
    }
  }
}

.theme--light {
  .nav-container {
    background-color: transparent;
  }
}
</style>
