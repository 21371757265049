<template>
  <Modal
    class="purchase-modal"
    @close="closeModal"
    cancelLabel="Cancel"
    confirmLabel="Confirm"
    :confirmDisabled="insufficientFunds"
    :onConfirm="confirmPurchase"
    :subTitle="displayedSubtitle"
    :hideActions="loading"
  >
    <template v-if="targetTypeData" v-slot:title>
      <h4>{{ `#${displayedId} of ${targetTypeData.cap}` }}</h4>
      <h5>{{ marketCollection.name }}</h5>
      <h5>{{ targetTypeData.name }}</h5>
    </template>
    <template v-slot:subtitle-content>
      <div class="balance">
        Balance: <CryptoFiat :crypto="profileBalance" hideUsd />
      </div>
    </template>
    <template v-if="displayedSubtitleIcon" v-slot:subtitle-icon>
      <Avatar
        :size="25"
        :seed="displayedSubtitleIcon.seed"
        :image="displayedSubtitleIcon.image"
      />
    </template>
    <template v-if="userProfile && userProfile.nearId !== owner.nearId">
      <!-- Confirmation Table -->
      <table class="data-table confirm">
        <tr>
          <td>Owner</td>
          <td class="owner" :title="owner.name">
            <Avatar
              :size="20"
              :seed="owner.nearId"
              :image="owner.image ? dynamicLink(owner.image, 'ar_1.0,c_fill,w_40') : ''"
            />
            <div class="owner-name">{{ owner.name }}</div>
          </td>
        </tr>
        <tr>
          <td>Ask</td>
          <td>
            <CryptoFiat :crypto="targetPurchase.prices[0].price" hideUsd digits-after-decimal="4"></CryptoFiat>
          </td>
        </tr>
        <tr>
          <td>Market Fee</td>
          <td>
            <CryptoFiat :crypto="marketFeeFormatted" hideUsd digits-after-decimal="4"></CryptoFiat>
          </td>
        </tr>
        <tr>
          <td>Total Payment</td>
          <td class="large-text" :class="{ error: insufficientFunds }">
            <CryptoFiat :crypto="calculatedTotal" hideUsd digits-after-decimal="4"></CryptoFiat>
          </td>
        </tr>
      </table>

      <p v-if="insufficientFunds" class="balance-warning">
        You don't have enough funds to cover this purchase.
        Click here to <span><MoonPayTopUpButton /></span>
      </p>
    </template>
    <template v-else>
      <button class="standard option" @click="viewInPortfolio">
        Portfolio
      </button>
    </template>
    <LoaderCover v-if="loading" center invert />
  </Modal>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import BigNumber from 'bignumber.js'
import { utils } from 'near-api-js'

export default {
  name: 'PurchaseModal',

  async created () {
    console.log('Market fee', utils.format.parseNearAmount(this.blkdrpSettings?.marketFee || '0'))
    try {
      await this.getTargetTypeData({
        type: this.targetToken.tokenType,
        contract: this.$route.params.collection
      })
    } catch (err) {
      console.log(err)
      this.addNotification('Error getting token info.', 'error')
    }
    try {
      console.log(this.targetToken)
      const user = await this.getUserByNearId(this.targetToken.seller)
      this.owner = user
    } catch (err) {
      this.addNotification('Error getting owner info.', 'error')
    }
    this.loading = false
  },

  data () {
    return {
      loading: true,
      purchasing: false,
      owner: ''
    }
  },

  computed: {
    ...mapGetters([
      'targetPurchase',
      'targetToken',
      'targetTypeData',
      'marketCollection',
      'profileBalance',
      'userProfile',
      'userNearSignature',
      'blkdrpSettings'
    ]),

    displayedId () {
      if (this.targetToken && this.targetToken.tokenId) {
        return this.formatIssuance(this.targetToken.tokenId)
      } else {
        return ''
      }
    },

    displayedSubtitle () {
      if (this.userProfile && this.userProfile.nearId === this.owner.nearId) return 'You own this token'
      return 'Pay with profile'
    },

    displayedSubtitleIcon () {
      if (this.userProfile && this.userProfile.nearId === this.owner.nearId) {
        return null
      }

      return {
        seed: this.userProfile.nearId,
        image: this.userProfile.image ? this.dynamicLink(this.userProfile.image, 'ar_1.0,c_fill,w_40') : ''
      }
    },

    marketFeeFormatted () {
      return utils.format.parseNearAmount(this.blkdrpSettings?.marketFee || '0')
    },

    calculatedTotal () {
      const bnPrice = new BigNumber(this.targetPurchase.prices[0].price)
      const bnFee = new BigNumber(this.marketFeeFormatted)
      const priceWithFee = bnPrice.plus(bnFee)
      console.log('With Fee', priceWithFee.toFixed())

      return priceWithFee.toFixed()
    },

    purchaseData () {
      const { chainId, marketContract, tokenContract, tokenId } = this.targetPurchase
      return {
        chainId: chainId,
        marketContract: marketContract,
        tokenContract: tokenContract,
        tokenId: tokenId,
        buyer: this.userProfile.nearId,
        price: {
          ftContract: this.targetPurchase.prices[0].ftContract,
          price: this.targetPurchase.prices[0].price
        }
      }
    },

    insufficientFunds () {
      if (this.purchasing) {
        return false
      }
      const funds = this.profileBalance
      return new BigNumber(funds).isLessThan(new BigNumber(this.calculatedTotal))
    }
  },

  methods: {
    ...mapActions([
      'cancelPurchase',
      'getUserByNearId',
      'getTargetTypeData'
    ]),

    ...mapMutations([
      'setLoading',
      'setShowingTokenDetails'
    ]),

    closeModal () {
      console.log('Close purchase modal.')
      this.cancelPurchase()
      this.owner = {}
    },

    viewInPortfolio () {
      this.closeModal()
      this.$router.push(`/portfolio/${this.targetToken.nftContract}/${this.targetToken.tokenId}`)
    },

    confirmPurchase () {
      this.purchaseWithProfile()
    },

    async purchaseWithProfile () {
      this.purchasing = true
      this.setLoading(true)
      console.log(this.purchaseData)
      try {
        const res = await this.fbCall('purchaseToken', { purchase: this.purchaseData, signature: this.userNearSignature })
        console.log(res)
        this.finalizePurchase()
      } catch (err) {
        this.purchasing = false
        this.setLoading(false)
        console.log(err)
        this.addNotification('Error purchasing token.', 'error')
      }
    },

    finalizePurchase () {
      this.closeModal()
      this.setShowingTokenDetails(false)
      this.addNotification(`Successfully purchased token ${this.purchaseData.tokenId}.`, 'success')
      this.$router.push('/portfolio')
    }
  }
}
</script>

<style lang="scss">
.modal.purchase-modal {
  .banner {
    margin: 0 20px;

    @include media($bp-phone-l) {
      margin: 0;
    }
  }

  .actions {
    @include media($bp-phone-l) {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
  }

  .balance {
    font-size: $font-size-ms;
  }

  .content .central {
    button.standard.option {
      margin-bottom: 10px;
      padding: 16px 20px;
    }

    .left {
      text-transform: capitalize;
      font-size: 12px;

      .avatar {
        display: inline-block;
        margin-right: 15px;
      }
    }

    .metamask-icon {
      display: inline-block;
      height: 25px;
      width: 25px;
      margin-right: 15px;
    }

    .profile-balance {
      .button-content {
        display: flex;
        align-items: center;

        .left {
          display: flex;
          align-items: center;
        }
      }
    }

    .metamask {
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: capitalize;
      font-size: 12px;

      .left {
        display: flex;
        align-items: center;
      }
      .right {
        font-size: 18px;
      }
    }

  }

  .v-input--switch {
    margin: 0 0 0 5px;
    padding: 0;
    .v-label {
      color: $blk-white;
      font-size: $font-size-xs;
    }
    .v-input--switch__track.theme--dark {
      background-color: $blk-grey-2;
    }
    .v-input--switch__thumb.theme--dark {
      background-color: $blk-white;
      box-shadow: -1px 1px 2px rgba(0, 0, 0, 0.25);
    }

    &.v-input--is-dirty {
      .v-input--switch__track.theme--dark {
        background-color: $blk-green;
        opacity: 1.0;
      }
    }
  }
  .data-table.confirm {
    margin-bottom: 0;
    .large-text {
      font-size: $font-size-m;
      &.error {
        color: red;
      }
    }
    tr {
      td {
        &:first-child {
          width: 66%;
          padding: 10px 0;
          border: none;
        }

        &:last-child {
          padding: 10px 0;
          border-bottom: 1px solid $blk-white;
        }
      }

      &:last-child {
        td {
          border: none;
        }
      }
    }
  }

  .balance-warning {
    color: red;
    font-size: $font-size-xs;
    margin-top: $space-ml;
    text-transform: uppercase;

    @include media-height(720px) {
      margin-top: $space-s;
    }

    a {
      color: red;
    }
    > span {
      display: inline-block;
    }

    button.moonpay {
      padding: 4px;
      margin-top: 0;
      border: none;
      img {
        width: 100px;
      }
    }
  }

  .data-table {
    .owner {
      .avatar {
        margin-right: $space-s;
      }
      .avatar, .owner-name {
        display: inline-block;
        vertical-align: middle;
      }
      .owner-name {
        max-width: calc(100% - 30px);
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  h5 {
    @include caption;
    font-weight: normal;
  }
  .back {
    cursor: pointer;
  }
  .content .central {
    min-height: 100px;
  }

  .transaction-status {
    margin-top: $space-l;
  }
}
</style>
