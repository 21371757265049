<template>
  <Modal
    class="backup-wallet-modal"
    :noClose="!agreed"
    title="Welcome"
    subTitle="Important Back up Reminder"
    hideActions
    @attemptClose="attemptedClose = true"
    @close="updateUser"
  >
    <div class="message">
      <p>
        YOU CONTROL A NEAR WALLET TIED TO THE EMAIL ADDRESS YOU
        SIGNED IN WITH. IT IS IMPORTANT TO PROTECT YOUR EMAIL ACCOUNT AND SAVE A
        COPY OF YOUR RECOVERY PHRASE SHOULD YOU EVER NEED IT.
        <a href="" target="_blank">LEARN MORE</a>
      </p>
    </div>
    <div class="message legal" :class="{ accented: attemptedClose && !agreed }">
      <v-checkbox
        v-model="agreed"
        :ripple="false"
        hide-details
        dark
      />
      <p>I understand it is my responsibility to back up my wallet</p>
    </div>
    <button
      class="standard option"
      :disabled="!canBackup"
      @click="backupWallet"
    >
      Back Up My Wallet Now
    </button>
    <p
      class="action-link"
      :class="{ disabled: !agreed }"
      @click="updateUser"
    >
      No thanks, I'll do it later
    </p>
  </Modal>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
export default {
  data () {
    return {
      attemptedClose: false,
      agreed: false
    }
  },
  computed: {
    canBackup () {
      return this.agreed
    }
  },
  methods: {
    ...mapMutations(['setGlobalState']),
    ...mapActions(['updateUserProfile']),
    async backupWallet () {
      await this.updateUser()
      this.$router.push({
        path: 'profile'
      })
    },
    async updateUser () {
      this.setGlobalState({ target: 'showingBackupWallet', val: false })
      await this.updateUserProfile({
        confirmations: {
          shownBackup: Date.now()
        }
      })
    }
  }
}
</script>

<style lang="scss">
.modal.backup-wallet-modal .central {
  .message.legal {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    text-align: left;

    &.accented {
      p {
        color: red;
      }
      .v-input--checkbox .v-icon {
        color: red;
      }
    }

    .v-input--checkbox {
      margin: 0;
      padding: 0;
    }

    p {
      color: $blk-grey-3;
    }
  }
}
</style>
