/**
 * Manages market settings.
 * i.e. Market name, logo, etc...
 */

import fb from '../../../firebase_config/firebase'
import { getDoc, doc, updateDoc } from 'firebase/firestore'

const state = () => ({
  globalSettings: null,
  blkdrpSettings: {}
})

const getters = {
  globalSettings: state => state.globalSettings,
  blkdrpSettings: state => state.blkdrpSettings,
  siteEnabled: (state, getters, rootState) => {
    if (rootState.User.userRole === 'admin' || !state.globalSettings?.siteDisabled) {
      return true
    }
    return false
  }
}

const mutations = {
  setGlobalSettings (state, val) {
    state.globalSettings = val
  },
  setBlkDrpSettings (state, val) {
    state.blkdrpSettings = val
  }
}

const actions = {
  async getGlobalSettings ({ commit }) {
    const globalSettings = await getDoc(doc(fb.db, 'market-settings', 'global'))
    if (!globalSettings.exists()) {
      console.log('Unable to retrieve global settings.')
    } else {
      // console.log('Global settings object: ', globalSettings.data())
      commit('setGlobalSettings', globalSettings.data())
    }
  },
  async updateGlobalSettings ({ commit }, updatedSettings) {
    const res = await updateDoc(doc(fb.db, 'market-settings', 'global'), updatedSettings)
    return res
  },
  async getBlkDrpSettings ({ commit }) {
    const blkdrpSettings = await getDoc(doc(fb.db, 'market-settings', 'blkdrp'))
    if (!blkdrpSettings.exists()) {
      console.log('Unable to retrieve BLKDRP settings.')
    } else {
      commit('setBlkDrpSettings', blkdrpSettings.data())
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
