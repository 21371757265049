var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-container"},[_c('TextInput',{staticClass:"small",attrs:{"icon":"magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{staticClass:"blkmnt token-table",attrs:{"headers":_vm.computedHeaders,"items":_vm.data && _vm.data.length > 0 ? _vm.data : [],"search":_vm.search,"show-select":_vm.selectable,"light":_vm.light,"footer-props":{
      itemsPerPageOptions: _vm.itemsPerPageOptions
    }},on:{"update:page":_vm.updatePage,"update:items-per-page":_vm.updateItemsPerPage},scopedSlots:_vm._u([{key:"item.active",fn:function(ref){
    var item = ref.item;
return [_c('v-checkbox',{on:{"click":function($event){return _vm.updateActive(item)}},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})]}},{key:"item.featured",fn:function(ref){
    var item = ref.item;
return [_c('v-checkbox',{on:{"click":function($event){return _vm.updateFeatured(item)}},model:{value:(item.featured),callback:function ($$v) {_vm.$set(item, "featured", $$v)},expression:"item.featured"}})]}},{key:"item.image",fn:function(ref){
    var item = ref.item;
return [_c('div',{directives:[{name:"lazy",rawName:"v-lazy:background-image",value:(item.image),expression:"item.image",arg:"background-image"}],staticClass:"thumbnail"})]}},{key:"item.created",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.created ? _vm.$moment(item.created.toDate()).format('MM.DD.yyyy') : 'Unavailable')+" ")]}},{key:"item.claimedTimestamp",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.claimedTimestamp ? _vm.$moment(item.claimedTimestamp.toDate()).format('MM.DD.yyyy, hh:mm') : '-')+" ")]}},{key:"item.internalNotes",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"internal-notes"},[_vm._v(_vm._s(item.internalNotes))])]}},{key:"item.details",fn:function(ref){
    var item = ref.item;
return [_c('button',{staticClass:"text small",on:{"click":function($event){return _vm.viewItem(item)}}},[_vm._v(_vm._s(_vm.actionText))])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }