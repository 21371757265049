<template>
  <div class="market-wallets">
    <h4>Current Wallets</h4>
    <p v-if="!globalSettings || !globalSettings.marketWallet" class="warning">The market wallet has not been set.</p>
    <p v-if="!blkdrpSettings || !blkdrpSettings.feeWallet" class="warning">The BLKDRP wallet has not been set.</p>
    <p v-if="usingTestnetWallet" class="warning">
      It looks like you're using a testnet wallet.
      Please make sure that all royalty wallets are properly set via the <router-link to="/admin">/admin</router-link> view.
    </p>
    <p>Market Royalty Wallet: <strong>{{ globalSettings.marketWallet }}</strong></p>
    <p>BLKDRP Royalty Wallet: <strong>{{ blkdrpSettings.feeWallet }}</strong></p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'WalletsData',
  computed: {
    ...mapGetters([
      'globalSettings',
      'blkdrpSettings'
    ]),

    // Used to display a warning if either of the royalty wallets aren't NEAR mainnet
    usingTestnetWallet () {
      const marketWallet = this.globalSettings.marketWallet
      if (!marketWallet) return false
      const mwLast = marketWallet.split('.').pop()

      const blkdrpWallet = this.blkdrpSettings.feeWallet
      if (!blkdrpWallet) return false
      const bwLast = blkdrpWallet.split('.').pop()

      if (mwLast !== 'near' || bwLast !== 'near') {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.market-wallets {
  h4 {
    margin-bottom: $space-l;
  }
  p {
    margin-bottom: $space-s;
  }
}
.warning {
  color: red;
  a {
    color: red;
  }
}
</style>
