<template>
  <BlkIcon v-bind="$attrs" viewBox="0 0 50 50" stroke>
    <path d="M37.5939 37.5939L11.6667 11.6667" stroke="#F4F4F4" stroke-width="3.33333" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M11.6667 37.5939L37.5939 11.6667" stroke="#F4F4F4" stroke-width="3.33333" stroke-linecap="round" stroke-linejoin="round" />
  </BlkIcon>
</template>

<script>
export default { inheritAttrs: false }
</script>
