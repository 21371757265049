<!--
  Navigation links shared between Navigation.vue and Navigation_Mobile.vue
-->
<template>
  <div class="links-container">
    <template v-if="userRole && userRole === 'admin'">
      <router-link class="link" to="/admin">
        <SettingsIcon :size=size :color="iconColor" />
        <span class="text">
          Admin
        </span>
      </router-link>
      <router-link class="link" to="/distro">
        <ActivityIcon :size=size :color="iconColor" />
        <span class="text">
          Distro
        </span>
      </router-link>
      <router-link class="link" to="/new-collection-group">
        <ProfileIcon :size=size :color="iconColor" />
        <span class="text">
          Groups
        </span>
      </router-link>
      <router-link class="link" to="/new-series">
        <AddIcon :size=size :color="iconColor" />
        <span class="text">
          Series
        </span>
      </router-link>
      <router-link class="link" to="/mint-collection">
        <AddIcon :size=size :color="iconColor" />
        <span class="text">
          Mint
        </span>
      </router-link>
      <router-link class="link" to="/promotions">
        <CampaignIcon :size=size :color="iconColor" />
        <span class="text">
          Promotions
        </span>
      </router-link>
      <div class="divider"></div>
    </template>
    <router-link class="link" to="/profile">
      <ProfileIcon :size=size :color="iconColor" />
      <span class="text">
        Profile
      </span>
    </router-link>
    <span class="link" @click="logout">
      <LogoutIcon :size=20 :color="iconColor" />
      <span class="text">
        Logout
      </span>
    </span>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  props: {
    /**
     * The width and height of the navigation icons, in pixels.
     */
    size: {
      type: Number,
      default: 21
    },
    /**
     * The color of the icons associated with each navigation item.
     */
    iconColor: {
      type: String,
      default: '#ffffff'
    }
  },
  computed: {
    ...mapGetters([
      'userRole'
    ])
  },
  methods: {
    ...mapActions([
      'logout'
    ])
  }
}
</script>

<style lang="scss" scoped>
.divider {
  border-bottom: solid 1px $blk-grey-2;
  margin-bottom: $space-s;
}
.link {
  display: flex;
  align-items: center;
  padding-bottom: $space-s;
  color: #ffffff;
  text-decoration: none;

  .text {
    display: inline-block;
    margin-left: 12px;
    text-transform: uppercase;
  }

  &:last-child {
    padding-bottom: 0;
  }
}

.theme--light {
  .link {
    color: $blk-grey-2;

    &:hover {
      .text {
        text-decoration: underline;
      }
    }
  }
}
</style>
