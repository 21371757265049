<template>
  <Modal
    hideActions
    noTitle
    class="token-detail-modal"
    large
    @close="closeDetails"
  >
    <div class="summary">
      <template v-if="targetData && marketCollection">
        <h4>{{ marketCollection.name }}</h4>
        <h5>{{ targetData.name }}</h5>
        <h5>#{{ displayedId }} of {{ targetData.cap }}</h5>
      </template>
    </div>
    <TokenHistoryTable
      v-if="tokenId && mode !== 'portfolio'"
      ref="historyTable"
      :token="tokenId"
      :contract="contractId"
      class="token-history-table"
      :numShown="numShown"
    >
      <template v-slot:[`footer.prepend`]>
        <div class="navigation">
          <a :href="`${nearExplorer()}/accounts/${contractId}`" target="_blank">
            View on explorer.near.org
            <v-icon>mdi-open-in-new</v-icon>
          </a>
        </div>
      </template>
    </TokenHistoryTable>
  </Modal>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
  name: 'TokenProvenanceModal',
  props: {
    mode: {
      type: String,
      default: 'market'
    }
  },
  async created () {
    window.addEventListener('resize', () => this.onResize())
    this.onResize()

    this.tokenId = this.provenanceToken.tokenId
    this.contractId = this.provenanceToken.contractId
    if (this.tokenId === '' || this.contractId === '') return

    try {
      // const primary = this.$route.params.primary || null
      let secondary = this.$route.params.secondary || null
      // If viewing all of a primary type, default to the lowest rarity variant
      if (secondary === 'all' && this.marketCollection.variants.secondary) {
        secondary = this.marketCollection.variants.secondary[0].id
      }

      // if (this.mode !== 'portfolio') {
      //   this.typeId = primary + (secondary !== '-' ? '.' + secondary : '')
      // } else if (this.tokenId) {
      const segments = this.tokenId.split('.')
      this.typeId = segments[0] + '.' + segments[1]
      // }
      console.log({ collection: this.$route.params.collection, contractid: this.contractId })

      await this.getData()

      await this.getTargetTypeData({
        type: this.typeId,
        contract: this.contractId
      })
    } catch (err) {
      console.log(err)
      this.addNotification('Error getting token data.', 'error')
    }
  },
  destroyed () {
    window.removeEventListener('resize', () => this.onResize())
  },
  data () {
    return {
      typeId: '',
      contractId: '',
      tokenId: '',
      numShown: '5'
    }
  },
  methods: {
    ...mapActions([
      'getTargetTypeData'
    ]),
    ...mapMutations([
      'setShowingTokenProvenance'
    ]),

    async getData () {
      if (this.tokenId && this.mode !== 'collection') {
        try {
          const token = await this.fbCall('getToken', { tokenContract: this.contractId, token: this.tokenId })
          this.token = token.data
        } catch (err) {
          console.log(err)
        }
      } else if (this.mode === 'collection') this.token = {}
    },
    closeDetails () {
      this.setShowingTokenProvenance(false)
    },
    onResize () {
      if (window.innerHeight > 900) this.numShown = 5
      else this.numShown = 3
    }
  },
  computed: {
    ...mapGetters([
      'marketCollection',
      'targetTypeData',
      'provenanceToken'
    ]),
    targetData () {
      if (this.mode === 'collection') {
        return this.marketCollection.metadata
      } else {
        return this.targetTypeData
      }
    },
    displayedId () {
      if (this.tokenId) {
        return this.formatIssuance(this.tokenId)
      } else {
        return ''
      }
    }
  }
}
</script>

<style lang="scss">
.modal.token-detail-modal .central {
  padding: $space-ms !important;

  .summary {
    font-size: $font-size-ms;
    line-height: 150%;
    margin-bottom: $space-ms;
  }

  .navigation {
    font-family: Drive Mono;
    font-size: $font-size-xs;
    cursor: pointer;

    a {
      text-decoration: none;
    }

    .v-icon {
      color: $blk-white;
    }
  }

  .token-history-table {
    // Fixes a strange see-through header issue on Chrome
    .v-data-table-header {
      &::before {
        z-index: 10;
        content: "";
        position: absolute;
        top: -1px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: $blk-grey-6;
      }
    }
    .v-data-footer {
      margin-top: $space-xs;
      &__pagination {
        font-size: $font-size-xs;
      }
      &__icons-before,
      &__icons-after {
        button {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
</style>
