<template>
  <BlkIcon v-bind="$attrs" viewBox="0 0 30 25">
    <path
      d="M12.6635 15.4175L12.4193 11.7704C12.3712 11.0366 12.1212 9.85106 12.6819 9.25488C13.1084 8.79627 14.1185 8.71717 14.4246 9.35348C14.6848 10.0999 14.7422 10.9024 14.5908 11.6786L14.2641 15.4324V15.4335C14.2503 15.7866 14.1735 16.134 14.0359 16.4608C13.9258 16.6752 13.7057 16.8116 13.4649 16.8139C13.223 16.8174 13.0006 16.6855 12.8859 16.4722C12.7564 16.1352 12.6819 15.7786 12.6635 15.4174L12.6635 15.4175ZM13.5085 20.4277L13.5074 20.4289C12.9077 20.4255 12.4136 19.9554 12.3792 19.3569C12.3448 18.7573 12.7816 18.2345 13.3778 18.1622C13.6896 18.1244 14.0026 18.2173 14.2434 18.4191C14.4853 18.6197 14.6321 18.9121 14.6493 19.2262C14.6676 19.5392 14.5553 19.8465 14.3386 20.0746C14.123 20.3016 13.8215 20.43 13.5073 20.4289L13.5085 20.4277Z"
      fill="#999999"/>
    <path
      d="M23.7663 24.0177H3.23433C2.25289 24.0028 1.35172 23.4708 0.865608 22.6178C0.378322 21.7648 0.378322 20.7191 0.864462 19.8661L11.1432 3.39049C11.6511 2.57417 12.5442 2.07885 13.5051 2.08002C14.467 2.08231 15.359 2.58104 15.8635 3.39853L26.1249 19.8466C26.6191 20.6996 26.6259 21.7498 26.1398 22.6074C25.6548 23.465 24.7525 24.0016 23.7665 24.0176L23.7663 24.0177ZM12.7103 4.34219L2.43275 20.819C2.27911 21.1033 2.28485 21.4473 2.4488 21.7271C2.61275 22.0057 2.9097 22.1788 3.23418 22.1834H23.7661C24.094 22.1788 24.3944 22.0011 24.5561 21.7168C24.7189 21.4324 24.7189 21.0839 24.5561 20.7995C24.5561 20.7995 14.2947 4.34798 14.29 4.3424C14.118 4.07182 13.8199 3.909 13.5 3.909C13.1801 3.909 12.882 4.07181 12.7101 4.3424L12.7103 4.34219Z"
      fill="#999999"/>
  </BlkIcon>
</template>

<script>
export default { inheritAttrs: false }
</script>
