<!--
  This notification modal displays when activated in the Firestore database,
  and is generally used to notify the user that a particular promotion has been fully issued.
-->

<template>
  <Modal
    :title="landingNotification.title || 'Token Type'"
    :subTitle="landingNotification.subTitle || 'Drop Complete'"
    cancelLabel="Close"
    @close="closeModal()"
  >
    <p v-if="landingNotification.message">
      {{ landingNotification.message }}
    </p>
    <!-- The default landing notification message assumes this notice is related to a fully issued promo. -->
    <p v-else>
      The <template v-if="landingNotification.title">{{ landingNotification.title }}</template> promotion has been fully issued. Join the <a href="https://discord.gg/84K5MU5KuR" target="_blank">Discord</a> and <router-link to="newsletter" @click.native="closeModal()">sign up for the email list</router-link> to receive updates on future promotions.
    </p>
  </Modal>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'LandingNotificationModal',
  computed: {
    ...mapGetters([
      'globalSettings'
    ]),
    landingNotification () {
      return this.globalSettings.landingNotification || {}
    }
  },
  methods: {
    ...mapMutations([
      'setGlobalState'
    ]),
    closeModal () {
      this.setGlobalState({ target: 'showingLandingNotification', val: false })
      window.sessionStorage.setItem('shownLandingNotification', this.landingNotification.id || 'default')
    }
  }
}
</script>
