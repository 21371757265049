/**
 * This file manages the applications various routes / views (pages)
 * Some views are included conditionally based on environment variables.
 */

import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '../store/index'
import { httpsCallable } from 'firebase/functions'
import fb from '../../firebase_config/firebase'
Vue.use(VueRouter)

const routes = []

/** SECURITY: The Utility view should NOT be included in production builds. */
if (process.env.VUE_APP_INCLUDE_UTILITIES === 'true') {
  routes.push({
    path: '/utilities',
    name: 'utilities',
    component: () => import(/* webpackChunkName: "utilities" */ '../views/Utilities.vue'),
    meta: {
      displayedTitle: 'Utilities'
    }
  },
  {
    path: '/3d-tests',
    name: '3d-tests',
    component: () => import(/* webpackChunkName: "3d_tests" */ '../views/3DTests.vue'),
    meta: {
      displayedTitle: '3D Tests'
    }
  },
  {
    path: '/genome-builder',
    name: 'genome-builder',
    component: () => import(/* webpackChunkName: "genome_builder" */ '../views/GenomeBuilder.vue'),
    meta: {
      displayedTitle: 'Genome Builder'
    }
  })
}
if (process.env.VUE_APP_INCLUDE_MINTING === 'true') {
  routes.push({
    path: '/mint-collection',
    name: 'mint-collection',
    component: () => import(/* webpackChunkName: "mint_collection" */ '../views/Mint_Collection.vue'),
    meta: {
      displayedTitle: 'Mint Collection',
      requiresAuth: true,
      requiredRole: 'admin',
      hideTitle: true
    }
  },
  {
    path: '/mint-token',
    name: 'mint-token',
    component: () => import(/* webpackChunkName: "mint_token" */ '../views/Mint_Token.vue'),
    meta: {
      displayedTitle: 'Mint Token',
      requiresAuth: true,
      requiredRole: 'admin',
      hideTitle: true
    }
  })
}
if (process.env.VUE_APP_USE_EMAIL_SIGNUP === 'true') {
  routes.push({
    path: '/newsletter',
    name: 'newsletter',
    meta: {
      hideNavigation: true
    },
    component: () => import(/* webpackChunkName: "newsletter" */ '../views/EmailSignup.vue')
  })
}
routes.push(
  {
    path: '/admin',
    name: 'admin',
    meta: {
      requiresAuth: true,
      requiredRole: 'admin'
    },
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin.vue')
  },
  {
    path: '/promotions',
    name: 'promotions',
    meta: {
      requiresAuth: true,
      requiredRole: 'admin'
    },
    component: () => import(/* webpackChunkName: "promotions" */ '../views/ManagePromotions.vue')
  },
  {
    path: '/live-claims/:promo',
    name: 'live-claims',
    component: () => import(/* webpackChunkName: "live_claims" */ '../views/ClaimForm.vue'),
    meta: {
      displayedTitle: 'Movement 22' // TODO: Make this variable
    }
  },
  {
    path: '/movement-22',
    name: 'movement-22',
    meta: {
      displayedTitle: 'Movement 22'
    },
    component: () => import(/* webpackChunkName: "movement_22" */ '../views/events/Movement_22.vue')
  },
  {
    path: '/afishionado',
    name: 'Afishionado',
    meta: {
      displayedTitle: 'Afishionado'
    },
    component: () => import(/* webpackChunkName: "afishionado_launch" */ '../views/events/Afishionado_Launch.vue')
  },
  {
    path: '/distro',
    name: 'distro',
    meta: {
      requiresAuth: true,
      requiredRole: 'admin'
    },
    component: () => import(/* webpackChunkName: "distro" */ '../views/Distro.vue')
  },
  {
    path: '/distro/collections/:collection',
    name: 'distro-collection',
    meta: {
      requiresAuth: true,
      requiredRole: 'admin',
      displayedTitle: 'Distro'
    },
    component: () => import(/* webpackChunkName: "collection_distro" */ '../views/Distro_Collection.vue')
  },
  {
    path: '/distro/collections/:collection/:type',
    name: 'distro-type',
    meta: {
      requiresAuth: true,
      requiredRole: 'admin',
      displayedTitle: 'Distro'
    },
    component: () => import(/* webpackChunkName: "type_distro" */ '../views/Distro_Type.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },

  {
    path: '/privacy',
    name: 'privacy',
    component: () => import(/* webpackChunkName: "privacy" */ '../views/Privacy.vue')
  },

  {
    path: '/terms',
    name: 'terms',
    component: () => import(/* webpackChunkName: "terms" */ '../views/Terms.vue')
  },
  /** Shows the current users owned tokens and unminted claims. */
  {
    path: '/portfolio',
    name: 'portfolio',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "portfolio" */ '../views/Portfolio.vue')
  },

  /** Provides a detail view for a token in the current user's portfolio */
  {
    path: '/portfolio/:collection/:token',
    name: 'portfolio-single',
    meta: {
      requiresAuth: true,
      displayedTitle: 'Portfolio',
      titleLink: () => { return '/portfolio' }
    },
    component: () => import(/* webpackChunkName: "portfolio_single" */ '../views/Portfolio_Single.vue')
  },

  {
    path: '/viewer/:collection/:token',
    name: 'viewer',
    meta: {
      hideNavigation: true,
      hideFooter: true
    },
    component: () => import(/* webpackChunkName: "viewer" */ '../views/Viewer.vue')
  },

  {
    path: '/drops',
    name: 'drops',
    component: () => import(/* webpackChunkName: "drops" */ '../views/Drops.vue')
  },

  {
    path: '/1xfund',
    name: '1xfund.near',
    component: () => import(/* webpackChunkName: "1xfund" */ '../views/1xfund.vue')
  },

  /** The root market view. Displays all types in all collections. */
  {
    path: '/market',
    name: 'market',
    component: () => import(/* webpackChunkName: "market" */ '../views/Market_Home.vue'),
    meta: {
    }
  },

  /** Displays a token contract type and all the instances of that type */
  {
    path: '/market/:collection/:primary/:secondary',
    name: 'market-contract',
    component: () => import(/* webpackChunkName: "market_contract" */ '../views/Market_Contract.vue'),
    meta: {
      displayedTitle: 'Market'
    }
  },

  /** Displays an individual token on the market */
  {
    path: '/market/:collection/:primary/:secondary/:issuance',
    name: 'market-token',
    component: () => import(/* webpackChunkName: "market_single" */ '../views/Market_Single.vue'),
    meta: {
      displayedTitle: 'Market'
    }
  },
  /** Displays all of the collection groups associated with this market */
  {
    path: '/collection-groups',
    alias: '/artists',
    name: 'collection-groups',
    component: () => import(/* webpackChunkName: "collection_groups" */ '../views/Collection_Groups.vue'),
    meta: {
      displayedTitle: 'Market',
      hideTitle: true
    }
  },
  {
    path: '/collection-groups/:id',
    alias: '/artists/:id',
    name: 'collection-group',
    component: () => import(/* webpackChunkName: "collection_group" */ '../views/Collection_Group.vue'),
    meta: {
      displayedTitle: 'Market',
      hideTitle: true
    }
  },
  {
    path: '/new-collection-group',
    name: 'new-collection-group',
    component: () => import(/* webpackChunkName: "collection_group_form" */ '../views/Collection_Group_Form.vue'),
    meta: {
      requiresAuth: true,
      requiredRole: 'admin'
    }
  },
  {
    path: '/new-series',
    name: 'new-series',
    component: () => import(/* webpackChunkName: "series_form" */ '../views/Series_Form.vue'),
    meta: {
      requiresAuth: true,
      requiredRole: 'admin'
    }
  },

  /**
   * Displays the current user's profile.
   * Provides update functions for username and profile image, as well as balance data.
   */
  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '*',
    redirect: process.env.VUE_APP_DEFAULT_HOME || '/market'
  },
  {
    path: '/offline',
    name: 'offline',
    component: () => import(/* webpackChunkName: "offline" */ '../views/SiteDisabled.vue')
  }
)

async function passwordCheck () {
  const currentPassword = window.localStorage.accessCode
  console.log(`Current password: ${currentPassword}`)
  const res = await httpsCallable(fb.functions, 'passwordCheck')(currentPassword)
  if (currentPassword && res.data === 'accepted') {
    return true
  } else {
    const password = prompt('Password:')
    localStorage.setItem('accessCode', password)
    console.log(window.localStorage.accessCode)
    return false
  }
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // Scroll to top after page transition
  scrollBehavior (to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      if (to.name !== from.name) { // Filter out switching token types
        setTimeout(() => {
          resolve({ x: 0, y: 0 })
        }, 300)
      }
    })
  }
})

// Called before navigating to a new route
router.beforeEach(async (to, from, next) => {
  let globalSettings = store.state.MarketSettings.globalSettings
  if (!globalSettings) {
    await store.dispatch('getGlobalSettings')
    globalSettings = store.state.MarketSettings.globalSettings
  }

  if (!store.getters.siteEnabled && to.path !== '/offline') {
    console.log('The site is disabled. Redirecting...')
    next('/offline')
  }
  store.commit('setGlobalState', { target: 'hideNavigation', val: to.meta?.hideNavigation || false })
  store.commit('setGlobalState', { target: 'hideFooter', val: to.meta?.hideFooter || false })
  // Check to see if there's a target collection.
  // When present, this collection data is used to populate various parts of the interface (i.e. Token Detail Modal)
  if (to.params && to.params.collection) {
    const targetCollection = to.params.collection
    await store.dispatch('marketCollectionCheck', targetCollection)
  }

  if (!process.env.VUE_APP_DISABLE_PASSWORD_PROTECTION) {
    const checkResult = await passwordCheck()
    console.log(`CHECK: ${checkResult}`)
    if (!checkResult) {
      next(globalSettings.marketHome || '/market')
    }
  }

  // Check to see if the target route requires an authenticated user.
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const requiredRole = to.meta.requiredRole
  if (requiresAuth || requiredRole) {
    if (requiredRole) {
      console.log('This route requires role:', requiredRole)
    }
    try {
      const msg = await store.dispatch('userAuthCheck', requiredRole || undefined)
      console.log('Auth check result:', msg)
      next()
    } catch (err) {
      console.log('Auth check error ', err)
      console.log(globalSettings)
      // If the user isn't logged in, redirect them to the market.
      if (requiresAuth && to.path !== (globalSettings.marketHome || '/market')) {
        console.log('Redirecting to market home page.')
        next(globalSettings.marketHome || '/market')
      } else {
        next()
      }
    }
  } else {
    next()
  }
})

// Called after navigating to a new route
router.afterEach((to, from) => {
  // Persistent UI resets
  store.commit('setGlobalState', { target: 'previousRoute', val: from.path })
  store.commit('setGlobalState', { target: 'navOverlay', val: false })
  store.commit('setGlobalState', { target: 'inPurchaseFlow', val: false })
  store.commit('setGlobalState', { target: 'loadingStatus', val: '' }) // Clear any set loading status messages
  store.commit('setGlobalState', { target: 'unobtrusiveLoader', val: false }) // Loader should be "obtrusive" by default

  // Change themes based on route metadata
  if (router.app.$vuetify) {
    if (to.meta.theme === 'light') {
      router.app.$vuetify.theme.dark = false
    } else {
      router.app.$vuetify.theme.dark = true
    }
  }

  // Set the page title's navigation link
  if (to.meta.titleLink) {
    store.commit('setTitleLink', to.meta.titleLink(to))
  } else {
    store.commit('setTitleLink', '')
  }

  // What type of minting is the user currently doing?
  if (to.path === '/mint-collection' || to.path === '/mint-token') {
    if (to.path === '/mint-collection') {
      store.commit('setMinting', 'collection')
    }
    if (to.path === '/mint-token') {
      store.commit('setMinting', 'token')
    }
  } else {
    store.commit('setMinting', null)
  }

  if (to.path === '/mint') {
    store.commit('setTargetCollection', {})
  }
})

export default router
