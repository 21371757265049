var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"token-history-table",class:{ 'compact': _vm.compact }},[_c('v-data-table',{staticClass:"blkmnt",attrs:{"dark":_vm.inverted,"loading":"","items":_vm.items,"headers":_vm.computedHeaders,"mobile-breakpoint":"0","disable-pagination":_vm.showAll,"fixed-header":_vm.showAll,"height":_vm.showAll ? _vm.tableHeight : '',"hide-default-footer":_vm.showAll,"footer-props":{
      itemsPerPageOptions: [_vm.numShown]
    }},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.date)+" ")]}},{key:"item.price",fn:function(ref){
    var item = ref.item;
return [(item.price)?_c('CryptoFiat',{attrs:{"crypto":item.price,"hideUsd":true}}):_c('span',[_vm._v("-")])]}},{key:"item.by",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"owner-wrapper"},[_c('Avatar',{attrs:{"size":30,"seed":item.by.nearId,"image":item.by.image ? _vm.dynamicLink(item.by.image, 'ar_1.0,c_fill,w_40') : ''}}),_c('div',{staticClass:"owner-name"},[_vm._v(_vm._s(item.by.name))])],1)]}},{key:"footer.prepend",fn:function(){return [_vm._t("footer.prepend")]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }