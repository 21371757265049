<template>
  <div class="field-container" :class="{ error: error }">
    <label v-if="label" :for="fieldName">{{ label + (required ? '*' : '') }}</label>
    <input
      v-model="text"
      :type="type"
      :class="{ small: small, right: right, left: left, mono: mono }"
      :name="fieldName"
      :placeholder="placeholder"
      :disabled="disabled"
      :required="required"
      :min="min"
      :max="max"
      @input="update"
    >
    <v-icon v-if="icon && value.length < 1">
      mdi-{{ icon }}
    </v-icon>
  </div>
</template>

<script>
export default {
  name: 'TextInput',
  props: {
    fieldName: {
      type: String,
      default: 'field-name'
    },
    label: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    },
    small: {
      type: Boolean,
      default: false
    },
    right: {
      type: Boolean,
      default: false
    },
    left: {
      type: Boolean,
      default: false
    },
    mono: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    urlFriendly: {
      type: Boolean,
      default: false
    },
    min: {
      type: Number,
      default: null
    },
    max: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      text: this.value || ''
    }
  },
  methods: {
    update () {
      this.$emit('input', this.type === 'number' ? parseFloat(this.text) : this.text)
    }
  },
  watch: {
    value: function (val) {
      this.text = val
    }
  }
}
</script>

<style lang="scss" scoped>
.field-container {
  position: relative;
  margin-bottom: $space-m;
  &.error {
    label {
      color: red;
    }
  }

  .v-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance:textfield;
  }
}
</style>
