<!--
  Overlay navigation displayed in mobile view.
-->

<template>
  <div v-if="siteEnabled" class="mobile-navigation">
    <transition name="side-slide">
      <div v-if="navOverlay" class="overlay">
        <NavigationLinksMobile />
      </div>
    </transition>
    <div>
      <div
        v-if="pageName"
        class="back-container"
        :class="{'nav-blur': scrollPosition > 0}"
      >
        <Typography variant="pc-title-32 mobile-title-32">{{ pageName }}</Typography>
      </div>
      <div
        class="hamburger"
        :class="{ close: navOverlay }"
        @click="toggleOverlay"
      >
        <div /><div /><div />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import NavigationLinksMobile from '@/components/NavigationLinksMobile.vue'
export default {
  mounted () {
    this.$root.$on('hideNavigation', () => {
      this.overlayShown = false
    })
    window.addEventListener('scroll', this.updateScroll)
  },
  beforeDestroy () {
    // Remove the listener, otherwise it will be triggered multiple times upon returning.
    this.$root.$off('hideNavigation')
  },
  components: {
    NavigationLinksMobile
  },
  data () {
    return {
      currentPath: null,
      scrollPosition: null
    }
  },
  computed: {
    ...mapGetters([
      'globalSettings',
      'navOverlay',
      'siteEnabled',
      'previousRoute'
    ]),
    homePath () {
      return this.globalSettings?.marketHome || '/market'
    },
    backPath () {
      let backPath = this.homePath
      if (!this.currentPath) return null
      const routeParams = this.$route.params

      if (this.currentPath === this.homePath) {
        backPath = null
      }

      if (this.currentPath.indexOf('/market/') > -1) {
        backPath = '/market'
        if (routeParams.primary || routeParams.secondary) backPath = `/market/${routeParams.collection}`
      }

      if (this.currentPath.indexOf('/portfolio/') > -1) {
        backPath = '/portfolio'
      }

      if (this.currentPath.indexOf('/distro/') > -1) {
        backPath = '/distro'
      }

      return backPath
    },
    pageName () {
      const routeName = this.$route.meta?.displayedTitle || this.$route.name
      return routeName
    }
  },
  methods: {
    ...mapMutations([
      'setGlobalState'
    ]),
    toggleOverlay () {
      this.setGlobalState({ target: 'navOverlay', val: !this.navOverlay })
    },
    updateScroll () {
      this.scrollPosition = window.scrollY
    }
  },
  watch: {
    $route: function (to) {
      this.currentPath = to.path
    }
  }
}
</script>

<style lang="scss" scoped>
.mobile-navigation {
  position: fixed;
  width: 100%;
  height: $header-height;
  z-index: $z-nav;
  pointer-events: none;
  display: none;

  @include media($bp-desktop-s) {
    display: flex;
  }

  .sign-up {
    pointer-events: all;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: $space-ms;
  }
}

.overlay {
  @include fill-screen;
  background: black;
  pointer-events: auto;
  z-index: $z-ui-front;

  .nav-content {
    @include center-in-parent;
    pointer-events: all;
    width: 100%;

    .logo {
      font-family: $font-title;
      font-weight: normal;
      color: $blk-grey-2;
      margin-bottom: $space-xl;
    }
  }
}
.back-container {
  pointer-events: all;
  cursor: pointer;
  width: 100vw;
  padding: 22px 27px;
  i {
    font-size: 32px;
  }
  @include media($bp-tablet) {
    padding: 22px $space-ms;
  }
}
.hamburger {
  pointer-events: all;
  display: none;
  position: fixed;
  z-index: $z-nav;
  right: 25px;
  top: 28px;
  width: $space-ml;
  height: $space-ml;
  cursor: pointer;

  @include media($bp-desktop-s) {
    display: block;
  }

  > div {
    position: absolute;
    height: 2px;
    width: $space-ml;
    background-color: #ffffff;
    right: 0;
    transition: .3s all;
    &:first-child {
      transform-origin: center center;
      top: 0;
    }
    &:nth-child(2) {
      top: 10px;
      opacity: 1;
    }
    &:last-child {
      top: 20px;
      transform-origin: center center;
    }
  }
  &.close {
    > div {
      z-index: 100;
      transition: .3s all;
      background: white !important;
      &:first-child {
        transform: rotateZ(45deg);
        top: 10px;
      }
      &:nth-child(2) {
        opacity: 0;
      }
      &:last-child {
        transform: rotateZ(-45deg);
        top: 10px;
      }
    }
  }
}

.theme--light {
  .overlay {
    background-color: $blk-white;
  }
  .hamburger {
    > div {
      background-color: $blk-black;
    }
  }

  .link {
    color: $blk-grey-2;

    &:hover {
      .text {
        text-decoration: underline;
      }
    }
  }
}
.nav-blur {
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(50px);
}
</style>
