<template>
  <div class="near-address-input">
    <h4 v-if="title" class="near-address-input__title">{{ title }}</h4>
    <div class="near-address-input__recipient" :class="{ 'side-by-side': sideBySide }">
      <p v-if="label" class="near-address-input__label">{{ label }}</p>
      <input
        v-model="text"
        type="text"
        name="Near Address Input"
        class="small"
        :id="uid"
        :placeholder="`i.e. user.${requiredNetwork}`"
        :disabled="disabled"
        @input="update"
      >
    </div>
    <div class="errors">
      <p v-if="sendError" class="error small">{{ sendError }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'NearAddressInput',
  props: {
    title: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    sideBySide: {
      type: Boolean,
      default: false
    },
    uid: {
      type: String,
      default: 'uid'
    }
  },

  data () {
    return {
      text: this.value || '',
      sendError: '',
      checkingAddress: false,
      validAddress: null,
      sendTimeout: null
    }
  },

  watch: {
    checkingAddress: function (val) {
      this.$emit('checkingAddress', val)
    },
    validAddress: function (val) {
      this.$emit('validAddress', val)
    }
  },

  computed: {
    ...mapGetters([
      'nearConnection'
    ]),

    /**
     * Returns the appropriate NEAR network based on environment variables.
     */
    requiredNetwork () {
      return process.env.VUE_APP_NEAR_NETWORK === 'mainnet' ? 'near' : process.env.VUE_APP_NEAR_NETWORK
    }
  },

  methods: {
    /**
     * When updating, emit the content for v-model, and check that the address is valid
     */
    update () {
      this.$emit('input', this.text)

      this.validAddress = false
      this.checkingAddress = true
      if (this.sendTimeout) clearTimeout(this.sendTimeout)
      this.sendTimeout = setTimeout(() => {
        this.validateRecipient()
      }, 1500)
    },

    /**
     * Checks that the target user address:
     * - has at least 2 strings separated by '.'
     * - is on the same NEAR network as the platform's contracts
     * - is an active account on the relevant network
     */
    async validateRecipient () {
      console.log('Validating recipient address...')
      const parts = this.value.split('.')
      if (parts.length < 2) {
        this.validAddress = false
        this.sendError = 'Please ensure that the NEAR address is properly formatted.'
        this.checkingAddress = false
        return
      }
      if (parts.length && parts[parts.length - 1] !== this.requiredNetwork) {
        this.validAddress = false
        this.sendError = `Please ensure you're sending to a .${this.requiredNetwork} address.`
        this.checkingAddress = false
        return
      }
      // Finally, query the target address against the NEAR network
      try {
        const res = await this.nearConnection.connection.provider.query({
          request_type: 'view_account',
          finality: 'final',
          account_id: this.text
        })
        console.log('The address is valid.', res)
        this.validAddress = true
        this.sendError = ''
      } catch (err) {
        console.log('NEAR Address error', err)
        this.validAddress = false
        this.sendError = 'It looks like this isn\'t an active NEAR address.'
      }
      this.checkingAddress = false
    }
  }
}
</script>

<style lang="scss" scoped>
.near-address-input {
  position: relative;
  text-align: left;

  &__title {
    margin-bottom: $space-ms;
  }

  &__label {
    flex: 1;
    font-size: $font-size-ms;
    text-transform: uppercase;
  }

  input {
    flex: 1;
    font-family: $font-title;
  }

  &__recipient {
    margin-bottom: $space-l;
    &.side-by-side {
      display: flex;
      align-items: center;

      input {
        text-align: right;
      }
    }
  }

  .errors {
    position: absolute;
    top: 100%;
    left: 0;
    margin: $space-xs 0;
  }
}
</style>
