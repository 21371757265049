<template>
  <BlkIcon v-bind="$attrs" viewBox="0 0 30 25">
    <path
      d="M8.5791 1.38672C5.29468 1.38672 2.50053 3.49537 1.52598 6.42676L0.838707 5.20496C0.751932 5.04442 0.575775 4.95331 0.394416 4.97588C0.24516 4.99237 0.114135 5.08435 0.0473131 5.21886C-0.0203724 5.35337 -0.0151653 5.51216 0.0611972 5.64232L1.283 7.82212C1.39928 8.03125 1.66047 8.11109 1.87306 8.00261L4.04591 6.89189C4.15612 6.84156 4.24116 6.74784 4.28021 6.63415C4.32013 6.51961 4.31145 6.39379 4.25678 6.28618C4.20211 6.17858 4.10579 6.09788 3.98952 6.06231C3.8741 6.0276 3.74915 6.04061 3.64328 6.10049L2.35206 6.75999C3.19203 4.16356 5.65736 2.27532 8.57902 2.27532C12.1967 2.27532 15.1114 5.15721 15.1114 8.71754C15.1114 12.2779 12.1967 15.1598 8.57902 15.1598C5.48453 15.1598 2.9012 13.039 2.2201 10.2032C2.20448 10.0783 2.13593 9.96635 2.03267 9.89433C1.92941 9.82318 1.80097 9.79801 1.67861 9.82665C1.55626 9.85529 1.45127 9.93512 1.39051 10.0445C1.32977 10.1547 1.31849 10.2857 1.35927 10.4046C2.13592 13.6379 5.07755 16.0484 8.57901 16.0484C12.6705 16.0484 16 12.764 16 8.7176C16 4.67122 12.6705 1.3868 8.57901 1.3868L8.5791 1.38672ZM8.62076 4.71202C8.39168 4.73718 8.22071 4.93243 8.22507 5.16324V8.71757C8.22507 8.85207 8.28668 8.97963 8.39168 9.06467L10.6131 10.8418C10.8049 10.9946 11.0843 10.9642 11.2379 10.7724C11.3915 10.5806 11.3603 10.3004 11.1685 10.1476L9.11365 8.50238V5.1633C9.11625 5.03488 9.06419 4.91252 8.9696 4.82575C8.87502 4.73898 8.74833 4.69819 8.62077 4.71208L8.62076 4.71202Z"
      fill="white"
    />
  </BlkIcon>
</template>

<script>
export default { inheritAttrs: false }
</script>
