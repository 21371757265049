<template>
  <v-menu v-model="pickerVisible" :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <input
        v-model="date"
        type="text"
        class="small"
        :placeholder="placeholder"
        readonly
        v-bind="attrs"
        v-on="on"
      >
    </template>
    <v-date-picker v-model="date" @input="chooseDate"></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: 'DatePicker',
  props: {
    placeholder: {
      type: String,
      default: 'Date'
    },
    value: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      pickerVisible: false,
      date: this.value || ''
    }
  },
  methods: {
    chooseDate () {
      this.pickerVisible = false
      this.$emit('input', this.date)
    }
  }
}
</script>

<style lang="scss">
.v-picker {
  .v-picker__body {
    background: $blk-grey-5;
  }
}
</style>
