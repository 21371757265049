<!--
  Used to display a user's profile image in various locations throughout the app.
  If no profile image is available, the component falls back to displaying an Identicon from the provided string,
  currently, the target user's NEAR ID.
-->

<template>
  <div
    class="avatar"
    @click="$emit('click')"
    :style="`width: ${size}px; height: ${size}px; background-image: url(${image || identicon})`"
  >
    <slot></slot>
  </div>
</template>

<script>
import md5 from 'crypto-js/md5'
const Identicon = require('identicon.js')
export default {
  name: 'Avatar',
  props: {
    /**
     * The image to display.
     */
    image: {
      type: String,
      default: null
    },
    /**
     * The string used to generate the Identicon, if no image is provided.
     */
    seed: {
      type: String,
      default: 'testuser'
    },
    /**
     * The width and height of the displayed image, in pixels.
     */
    size: {
      type: Number,
      default: 34
    }
  },
  computed: {
    /**
     * Returns the Identicon generated from the MD5 hash of the provided seed property.
     */
    identicon () {
      // Use MD5 to generate a hash from the provided seed String.
      const hash = md5(this.seed).toString()
      const data = new Identicon(hash, {
        background: [255, 255, 255, 0],
        size: this.size,
        margin: 0.2
      }).toString()
      return `data:image/png;base64,${data}`
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar {
  @include bg-cover-center;
  border-radius: 50%;
  border: solid 1px $blk-grey-3;
}
</style>
