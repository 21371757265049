<template>
  <div class="token-history-table" :class="{ 'compact': compact }">
    <v-data-table
      class="blkmnt"
      :dark="inverted"
      loading
      :items="items"
      :headers="computedHeaders"
      mobile-breakpoint="0"
      :disable-pagination="showAll"
      :fixed-header="showAll"
      :height="showAll ? tableHeight : ''"
      :hide-default-footer="showAll"
      :footer-props="{
        itemsPerPageOptions: [numShown]
      }"
    >
      <template v-slot:[`item.date`]="{ item }">
        {{ item.date }}
      </template>
      <template v-slot:[`item.price`]="{ item }">
        <CryptoFiat v-if="item.price" :crypto="item.price" :hideUsd="true"/>
        <span v-else>-</span>
      </template>
      <template v-slot:[`item.by`]="{ item }">
        <div class="owner-wrapper">
          <Avatar
            :size="30"
            :seed="item.by.nearId"
            :image="item.by.image ? dynamicLink(item.by.image, 'ar_1.0,c_fill,w_40') : ''"
          />
          <div class="owner-name">{{ item.by.name }}</div>
        </div>
      </template>
      <template v-slot:[`footer.prepend`]>
        <slot name="footer.prepend"></slot>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'
export default {
  name: 'TokenHistoryTable',
  created () {
    this.init()
  },
  props: {
    token: {
      type: String,
      default: null
    },
    contract: {
      type: String,
      default: null
    },
    inverted: {
      type: Boolean,
      default: false
    },
    compact: {
      type: Boolean,
      default: false
    },
    numShown: {
      type: Number,
      default: 5
    },
    showAll: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      headers: [
        {
          align: 'left',
          text: 'Date',
          value: 'date',
          hide: 'smAndDown'
        },
        {
          align: 'left',
          text: 'Action',
          value: 'method'
        },
        {
          align: 'left',
          text: 'Amount',
          value: 'price'
        },
        {
          align: 'left',
          text: 'By',
          value: 'by'
        }
      ],
      history: null,
      items: []
    }
  },

  computed: {
    computedHeaders () {
      return this.headers.filter(h => !this.$vuetify.breakpoint[h.hide])
    },
    tableHeight () {
      return window.innerHeight * 0.3
    }
  },

  methods: {
    ...mapActions([
      'getUserByNearId'
    ]),
    async init () {
      try {
        this.history = await this.fbCall('getTransactionHistory', {
          tokenContract: this.contract,
          tokenId: this.token
        })
        console.log(this.history)
      } catch (err) {
        console.log(err)
        this.addNotification('Error retrieving token history.', 'error')
      }
      this.formatHistory(this.history.data)
    },
    async formatHistory (hist) {
      const formatted = []
      this.$el.style.display = 'block'
      for (let i = 0; i < hist.length; i++) {
        const userId = hist[i].args.receiver_id || hist[i].caller
        let user = ''
        try {
          user = await this.getUserByNearId(userId)
        } catch (err) {
          console.log(err)
        }
        let action = hist[i].method

        // Special action cases
        // "nft_approve" with no msg arguments is a token transfer
        if (hist[i].method === 'nft_approve' && !hist[i].args.msg) {
          action = 'nft_transfer'
        }

        // "nft_transfer" with a receiver arg is someone receiving a token after transfer
        if (hist[i].method === 'nft_transfer' && hist[i].args.receiver_id) {
          action = 'nft_receive'
        }

        formatted.push({
          date: this.getDateFormat(Number(hist[i].timestamp)),
          method: this.getActionFormat(action),
          price: hist[i].args.balance || this.getPrice(hist[i].args),
          by: user
        })
      }
      this.items = formatted.reverse()
    },
    getActionFormat (action) {
      const map = {
        nft_mint: 'Claim',
        nft_approve: 'List',
        nft_transfer: 'Transfer',
        nft_receive: 'Receive',
        nft_transfer_payout: 'Buy'
      }
      return map[action]
    },

    getDateFormat (timestamp) {
      // const ts = new Date(timestamp / 1000000)
      // return ts.toLocaleString()
      // return `${ts.getMonth()}-${ts.getDay()}`
      return moment(timestamp / 1000000).format('MM.DD.YY')
    },

    getPrice (args) {
      let price = null
      if (args.msg) {
        const msgFormatted = (args.msg).replaceAll('\\', '')
        price = JSON.parse(msgFormatted).sale_conditions.near
      }
      return price
    }
  }
}
</script>

<style lang="scss">
@mixin compact {
  .usd {
    display: none;
  }
  .owner-wrapper {
    align-items: center;
    // justify-content: flex-end;
  }
  .v-data-footer {
    .v-data-footer__select {
      display: none;
    }
  }
}

.token-history-table {
  position: relative;
  @include media($bp-phone-l) {
    @include compact;
  }
  &.compact {
    @include compact;
    .owner-name {
      display: none;
    }
  }

  .owner-name {
    max-width: 80px;
    @include media($bp-tablet) {
      display: none;
    }
  }
}
</style>
