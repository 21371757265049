<template>
  <div class="notification" :style="`bottom: ${bottom + 6}px`" ref="notification">
    <div class="icon-wrapper"><img class="icon" :src="icon" alt=""></div>
    <div class="info">
      <p class="message">{{ message }}</p>
      <p v-if="externalLink" class="link">
        <a :href="externalLink" target="_blank">{{ linkText || externalLink }}</a>
      </p>
      <p v-if="routerLink" class="link">
        <router-link :to="routerLink">{{ linkText || routerLink }}</router-link>
      </p>
      <p class="time">{{ time }}</p>
    </div>
    <div class="close" @click="remove"><img src="@/assets/icons/close.svg" alt="close"></div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  name: 'Notification',
  created () {
    this.time = this.formatTime(new Date())
    if (this.expires) {
      setTimeout(() => {
        this.remove()
      }, 3000)
    }
  },
  mounted () {
    this.$emit('reportHeight', this.index, this.$refs.notification.clientHeight)
  },
  props: {
    message: {
      type: String,
      default: 'Notification.'
    },
    linkText: {
      type: String,
      default: ''
    },
    routerLink: {
      type: String,
      default: ''
    },
    externalLink: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'info'
    },
    index: {
      type: Number,
      default: 0
    },
    expires: {
      type: Boolean,
      default: false
    },
    bottom: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      time: ''
    }
  },

  computed: {
    icon () {
      let file = ''
      switch (this.type) {
        case 'success':
          file = 'check'
          break
        case 'error':
          file = 'rejected'
          break
        default:
          file = 'info'
      }
      return require(`@/assets/icons/${file}.svg`)
    }
  },

  methods: {
    ...mapMutations(['removeNotification']),
    formatTime (date) {
      var hours = date.getHours()
      var minutes = date.getMinutes()
      var ampm = hours >= 12 ? 'pm' : 'am'
      hours = hours % 12
      minutes = minutes < 10 ? '0' + minutes : minutes
      var strTime = hours + ':' + minutes + ' ' + ampm
      return strTime
    },
    remove () {
      this.$emit('remove', this.index)
      this.removeNotification(this.index)
    }
  }
}
</script>

<style lang="scss" scoped>
.notification {
  z-index: $z-notification;
  position: fixed;
  bottom: 90px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  width: 400px;
  text-align: center;
  padding: 20px 25px;
  background: #1b1b1b;
  display: flex;
  border: solid 1px transparentize($blk-white, 0.66);
  border-radius: 15px;
  font-family: $font-body;
  font-size: 16px;
  line-height: 140%;

  @include media(420px) {
    width: calc(100% - #{$space-xs} * 2);
    margin: 0 $space-xs;
  }

  .icon-wrapper {
    display: flex;
    align-items: flex-start;

    img {
      width: 30px;
    }
  }

  .info {
    color:white;
    margin-left: 20px;
    margin-right: 20px;
    text-align: left;
    max-width: calc(100% - 70px);

    .message {
      max-width: 100%;
      max-height: 7em;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .time {
      margin-top: 5px;
      color: #999999;
    }
  }

  .close {
    cursor: pointer;
    position: absolute;
    top: $space-m;
    right: $space-m;
    width: 17px;
    width: 17px;

    img {
      width: 17px;
    }
  }
}
</style>
