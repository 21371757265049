<template>
  <div class="container">
    <div v-if="userProfile" class="userInfo">
      <Avatar
        :size="160"
        :image="displayedAvatar"
        :seed="userProfile.nearId || null"
      ></Avatar>

      <p class="username">{{userProfile.name}}</p>
      <div class="address-wrapper">
        <p class="email">{{ currentUser.email }}&nbsp;</p>
        <img src="@/assets/icons/lock-icon.svg" alt="lock">
      </div>
    </div>
    <div class="nav mt-9 mb-9">
      <router-link
        v-if="globalSettings.featuredEvent"
        :to="globalSettings.featuredEvent.url"
        :class="{ active: $route.path == globalSettings.featuredEvent.url }"
      >
        {{ globalSettings.featuredEvent.name }}
      </router-link>
      <router-link
        v-bind:class="{ active: isCurrentRoute('market') }"
        to="/market"
      >
        Market
      </router-link
      >
      <router-link
        v-if="userProfile"
        v-bind:class="{ active: isCurrentRoute('portfolio') }"
        to="/portfolio"
        >Portfolio</router-link
      >
      <router-link
        v-bind:class="{ active: isCurrentRoute('drops') }"
        to="/drops"
        >Drops</router-link
      >
    </div>

    <div v-if="userProfile" class="nav">
      <router-link to="/profile" :class="{ active: isCurrentRoute('profile') }">
        Profile
      </router-link>
      <span class="link" @click="logout">
        Logout
      </span>
    </div>
    <div v-else class="nav">
      <span class="link" @click="initiateLogin">
        Sign Up/In
      </span>
    </div>

    <div class="nav-footer">
      <MarketSocials />
      <FooterNavigation />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { isCurrentRoute } from '@/common/utils'

export default {
  name: 'NavigationLinksMobile',
  computed: {
    ...mapGetters([
      'currentUser',
      'globalSettings',
      'userProfile'
    ]),
    homeLink () {
      return this.globalSettings && this.globalSettings.useCollectionGroups ? (this.globalSettings.marketHome || '/collection-groups') : '/market'
    },
    displayedAvatar () {
      if (this.userProfile.customImage) return this.dynamicLink(this.userProfile.image, 'ar_1.0,c_fill,w_260')
      if (this.userProfile) return this.userProfile.image
      return null
    }
  },
  methods: {
    ...mapActions([
      'initiateLogin',
      'logout'
    ]),
    isCurrentRoute (linkName) {
      return isCurrentRoute(this.$route.name, linkName)
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 85%;

  .userInfo {
    display: flex;
    align-items: center;
    flex-direction: column;

    .avatar {
      margin-bottom: 20px;
    }
    .username {
      @include nav-link;
    }

    .address-wrapper {
      display: flex;
      color: $blk-grey-4;
    }
  }
}

.nav {
  &.mt-9 {
    @include media-height (720px) {
      margin: $space-m 0 !important;
    }
  }

  a {
    display: block;
    @include nav-link;
  }

  .link {
    @include nav-link;
  }

  a, .link {
    @include media-height(720px) {
      font-size: $font-size-m;
    }
  }

  .active {
    text-decoration: line-through;
    text-decoration-thickness: 4px;
  }
}

.nav-footer {
  position: absolute;
  bottom: 50px;

  @include media-height(720px) {
    bottom: $space-m;
  }
}
</style>
