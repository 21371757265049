<template>
  <div class="variants-editor-container">
    <h6 v-if="!hideLabel" class="label">{{ label }}</h6>
    <div v-for="(variant, index) in variants" :key="`variant-${index}`" class="variants-editor">
      <div class="row input-row">
        <input type="text" class="small" v-model="variant.name" placeholder="Variant Name" @input="update">
        <input type="text" class="small" v-model="variant.id" placeholder="Variant ID" @input="update">
        <button v-if="!disabled" type="button" class="del" @click="remove(index)">
          <CloseIcon color="#f4f4f4" />
        </button>
      </div>
      <MediaPicker
        v-if="mode === 'primary'"
        label="Primary Type Media"
        v-model="variant.image"
        :uid="variant.id"
        :requiredWidth="540"
        :requiredHeight="540"
      />
    </div>
    <div class="btn-container">
      <button
        type="button"
        v-if="saveButton && dataToSave"
        class="standard save-btn"
        @click="save"
      >
        Save
      </button>
      <button type="button" class="text small add" @click="addVariant">
        Add {{ label || '' }} Variant<AddIcon />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VariantsEditor',
  props: {
    label: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: ''
    },
    hideLabel: {
      type: Boolean,
      default: false
    },
    saveButton: {
      type: Boolean,
      default: false
    },
    value: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      variants: this.value || []
    }
  },
  computed: {
    dataToSave () {
      if (this.variants.length > 0 && this.variants[0].name && this.variants[0].id) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    update () {
      this.$emit('input', this.variants)
    },
    addVariant () {
      this.variants.push({})
    },
    remove (index) {
      this.variants.splice(index, 1)
    },
    save () {
      this.$emit('save')
    }
  },
  watch: {
    value: function (val) {
      this.variants = val
    }
  }
}
</script>

<style lang="scss">
.variants-editor-container {
  margin-bottom: $space-m;
  .btn-container {
    margin-top: $space-s;
  }
  .media-picker {
    margin: $space-s 0 $space-ms;
  }
  .save-btn {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
.variants-editor {
  .row {
    margin: 0;
    input {
      flex: 1;
    }
  }
}
</style>
