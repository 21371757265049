<template>
  <div class="properties-container">
    <h4>{{ title }}</h4>
    <div class="active-properties">
      <slot></slot>
      <div
        v-for="(property, index) in activeProperties"
        :key="`active-property-${index}-${propIds[index]}`"
        class="property-single input-container"
        :class="property.id"
      >
        <div class="row">
          <h6 class="label">{{ property.name }}</h6>

          <div class="fields">
            <template v-if="property.id == 'series'">
              <DatePicker v-model="property.publishDate" placeholder="Publish Date" @input="update" />
              <DatePicker v-model="property.closeDate" placeholder="Close Date" @input="update" />
            </template>

            <template v-if="property.id == 'classification'">
              <input
                v-model="property.value"
                type="text"
                class="small"
                disabled
                placeholder="Private / Public"
              >
            </template>

            <template v-if="property.id == 'ipfs-file'">
              <input
                v-model="property.description"
                type="text"
                class="small"
                placeholder="Description"
                :disabled="disabled"
                @change="update"
              >
              <input
                v-model="property.link"
                type="text"
                class="small"
                placeholder="Link"
                :disabled="disabled"
                @change="update"
              >
            </template>

            <template v-if="property.id == 'mint-fee'">
              <input
                v-model="property.value"
                type="text"
                class="small"
                @change="update"
                disabled
              >
            </template>

            <template
              v-if="property.id == 'creator-royalty' || property.id == 'blkdrp-royalty' || property.id == 'market-royalty'"
            >
              <input
                v-model="property.value"
                type="text"
                class="small"
                :placeholder="`% ${targetWallet(property.id)}`"
                @change="update"
              >
            </template>

            <template v-if="property.id == 'royalty'">
              <NearAddressInput
                v-model="property.recipient"
                @input="update"
                :uid="`address-input-${index}`"
              />
              <input
                v-model="property.value"
                type="number"
                class="small"
                placeholder="%"
                :disabled="disabled"
                @change="update"
              >
            </template>

            <button
              v-if="!disabled && !property.required"
              type="button"
              class="del"
              @click="removeProperty(index)"
            >
              <CloseIcon color="#f4f4f4" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!disabled && mode == 'token'" class="btn-container">
      <button type="button" class="text small" @click="showProperties">
        Add Property<AddIcon />
      </button>
    </div>

    <Modal
      v-if="showingModal && mode == 'token'"
      title="Additional Properties"
      subTitle="Choose Property"
      class="properties-modal"
      @close="showingModal = false"
    >
      <div
        v-for="(property, index) in additionalProperties"
        :key="`property-${index}`"
        class="property-info"
        @click="addProperty(property)"
      >
        <h4>{{ property.name }}</h4>
        <p>{{ property.info }}</p>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Properties',
  props: {
    mode: {
      type: String,
      default: 'token'
    },
    title: {
      type: String,
      default: 'Properties'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showingModal: false,
      // Array of properties that can be added by the user.
      properties: [
        {
          name: 'Classification',
          id: 'classification',
          info: 'Is this collection private or public?',
          type: 'collection',
          value: 'private',
          required: true
        },
        {
          name: 'Series',
          info: 'Determines the opening and closing dates of the collection.',
          id: 'series',
          type: 'collection',
          required: true
        },
        {
          name: 'IPFS File',
          id: 'ipfs-file',
          info: 'Embed an on-chain link to an IPFS asset.',
          type: 'token'
        },
        {
          name: 'Mint Fee',
          info: 'This fee will go to the minting service.',
          id: 'mint-fee',
          type: 'token',
          value: 1.0
        },
        {
          name: 'Creator Royalty',
          info: 'This royalty will be paid to the creator on any future sales.',
          id: 'creator-royalty',
          type: 'token',
          required: false
        },
        {
          name: 'Market Royalty',
          info: 'This royalty will be paid to the default market wallet on any future sales.',
          id: 'market-royalty',
          type: 'token',
          required: true
        },
        {
          name: 'BLKDRP Royalty',
          info: 'This royalty will be paid to BLKDRP on any future sales.',
          id: 'blkdrp-royalty',
          type: 'token',
          required: true
        },
        {
          name: 'Royalty',
          info: 'Deposit a percentage of sales to a target wallet address.',
          id: 'royalty',
          type: 'token',
          required: false
        },
        {
          name: 'Charity Royalty',
          info: 'Deposit a percentage of sales to a target wallet address.',
          id: 'royalty',
          tag: 'charity',
          type: 'token',
          required: false
        }
      ],
      // Properties that have been added by the user.
      activeProperties: [],
      // Used to add random IDs to properties so array updates properly after deletions, etc...
      propIds: []
    }
  },
  computed: {
    ...mapGetters([
      'globalSettings',
      'blkdrpSettings'
    ]),
    /**
     * Available properties that will display in the modal.
     */
    additionalProperties () {
      const additional = []
      this.properties.forEach((property) => {
        if (!property.required && property.type === this.mode) {
          additional.push(property)
        }
      })
      return additional
    }
  },
  mounted () {
    this.properties.forEach((property) => {
      const propCopy = property
      if (propCopy.type === this.mode && propCopy.required) {
        this.activeProperties.push(propCopy)
        this.propIds.push(Math.random())
      }
    })
  },
  methods: {
    targetWallet (type) {
      if (type === 'blkdrp-royalty') return `(${this.blkdrpSettings.feeWallet})`
      if (type === 'market-royalty') return `(${this.globalSettings.marketWallet})`
      return ''
    },
    showProperties () {
      this.showingModal = true
    },
    addProperty (property) {
      this.activeProperties.push({ ...property })
      this.propIds.push(Math.random())
      this.showingModal = false
      this.update()
    },
    removeProperty (index) {
      this.$delete(this.activeProperties, index)
      this.$delete(this.propIds, index)
      this.update()
    },
    setProperties (properties) {
      this.clearProperties()
      properties.forEach((prop) => {
        this.addProperty(prop)
      })
    },
    clearProperties () {
      this.activeProperties = []
      this.propIds = []
      this.update()
    },
    closeModal () {
      this.showingModal = false
    },
    update () {
      this.$emit('input', this.activeProperties)
    }
  }
}
</script>

<style lang="scss">
.properties-container {
  h4 {
    margin: $space-xl 0 $space-ml 0;
  }
  .properties-modal {
    .central {
      text-align: left;
    }
    .property-info {
      cursor: pointer;
      border-bottom: solid 1px $blk-grey-3;
      padding: $space-s;
      &:hover {
        background-color: $blk-grey-5;
      }
      h4 {
        margin: 0 0 $space-s;
        font-size: $font-size-ms;
      }
      p {
        font-size: 12px;
      }
    }
  }

  .property-single {
    .fields {
      .near-address-input {
        width: 400%;

        &__recipient {
          margin-bottom: 0;
        }
      }
    }
    &.royalty {
      margin-bottom: $space-l;
    }
  }
}
</style>
