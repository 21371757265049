<template>
  <div class="blk-icon fill info-icon-container">
    <v-tooltip v-if="tooltip" bottom max-width="350">
      <template v-slot:activator="{ on, attrs }">
        <svg v-on="on" v-bind="attrs" class="icon-info" width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M25.5 22C26.3284 22 27 22.6716 27 23.5V33C27 33.8284 26.3284 34.5 25.5 34.5C24.6716 34.5 24 33.8284 24 33V23.5C24 22.6716 24.6716 22 25.5 22Z" :fill="color"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M25.3333 40.8333C34.0778 40.8333 41.1667 33.7445 41.1667 25C41.1667 16.2555 34.0778 9.16663 25.3333 9.16663C16.5888 9.16663 9.5 16.2555 9.5 25C9.5 33.7445 16.5888 40.8333 25.3333 40.8333ZM25.3333 43.3333C35.4586 43.3333 43.6667 35.1252 43.6667 25C43.6667 14.8747 35.4586 6.66663 25.3333 6.66663C15.2081 6.66663 7 14.8747 7 25C7 35.1252 15.2081 43.3333 25.3333 43.3333Z" :fill="color"/>
          <path d="M27 18.5C27 19.3284 26.3284 20 25.5 20C24.6716 20 24 19.3284 24 18.5C24 17.6716 24.6716 17 25.5 17C26.3284 17 27 17.6716 27 18.5Z" :fill="color"/>
        </svg>
      </template>
      <span class="tooltip-content">{{ tooltip }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    tooltip: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: '#F4F4F4'
    }
  }
}
</script>

<style lang="scss" scoped>
.info-icon-container {
  display: inline-block;
  vertical-align: middle;
}
.icon-info {
  width: 24px;
  height: 24px;
  vertical-align: top;
}

.tooltip-content {
  font-family: $font-title;
  font-size: 12px;
  line-height: 20px;
  position: relative;
  z-index: 1000;
}
</style>
