/**
 * Handles operations related to Web3 onboarding and maintaining current Signers / Providers.
 * Also used to get information about the user's NEAR wallet.
 */
import fb from '../../../firebase_config/firebase'
import { httpsCallable } from 'firebase/functions'
import { utils } from 'near-api-js'
const { BigNumber } = require('bignumber.js')

const state = () => ({
  externalAccount: null,
  profileBalance: null,
  profileBalanceFormatted: null,
  connectedWallet: null,
  showingMoonPay: false,
  moonPayMode: 'buy'
})

const getters = {
  externalAccount: state => state.externalAccount,
  profileBalance: state => state.profileBalance,
  profileBalanceFormatted: state => state.profileBalanceFormatted,
  connectedWallet: state => state.connectedWallet,
  showingMoonPay: state => state.showingMoonPay,
  moonPayMode: state => state.moonPayMode
}

const actions = {
  async initMetaMask ({ dispatch }) {
    await window.ethereum.enable()
    dispatch('setMetaMaskState')
  },

  /**
   * Get the balance for a particular token for the currently authenticated user.
   * @param {String} erc20 - the address of the fungible token for which the balance should be retrieved (leaving empty will return NEAR)
   * @returns the user's balance for the target token (default NEAR)
   */
  async getBalance ({ commit, getters }, erc20) {
    if (!getters.userProfile) return new Error('No user profile present.')
    const nearId = getters.userProfile.nearId
    try {
      const getBalance = httpsCallable(fb.functions, 'getBalance')
      const res = await getBalance({ user: nearId, ...erc20 && { erc20Address: erc20 } })
      // Hide the initial amount the user is given on account creation.
      const greaterThan = new BigNumber(res.data).isGreaterThan(utils.format.parseNearAmount('0.01'))
      const bal = greaterThan ? res.data : '0'
      commit('setBalance', bal)
      commit('setBalanceFormatted', utils.format.formatNearAmount(bal))
    } catch (err) {
      console.log(err)
      console.log('Unable to get balance.')
    }
  }
}

const mutations = {
  setExternalAccount (state, val) {
    state.externalAccount = val
  },
  setBalance (state, val) {
    state.profileBalance = val
  },
  setBalanceFormatted (state, val) {
    state.profileBalanceFormatted = val
  },
  setConnectedWallet (state, val) {
    state.connectedWallet = val
  },
  setShowingMoonPay (state, val) {
    if (val) {
      state.moonPayMode = val
      state.showingMoonPay = true
    } else {
      state.showingMoonPay = false
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
