<template>
  <div class="field-container">
    <label for="">{{ label }}</label>
    <textarea v-model="text" :name="name" @input="update" :disabled="disabled" :style="`height: ${height}`"></textarea>
    <p class="count">{{ count }}</p>
  </div>
</template>

<script>
export default {
  name: 'TextArea',
  props: {
    name: {
      type: String,
      default: 'field-name'
    },
    label: {
      type: String,
      default: 'Label'
    },
    value: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: '150px'
    }
  },
  data () {
    return {
      text: this.value || ''
    }
  },
  computed: {
    count () {
      return this.text.length
    }
  },
  methods: {
    update () {
      this.$emit('input', this.text)
    }
  },
  watch: {
    value: function (val) {
      this.text = val
    }
  }
}
</script>

<style lang="scss" scoped>
.field-container {
  margin-top: $space-l;
  margin-bottom: $space-m;
  position: relative;
}
.count {
  @include small-text-book;
  position: absolute;
  top: 0;
  right: 0;
}
textarea {
  margin-top: $space-s;
}
</style>
