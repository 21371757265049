<template>
  <div class="spatial-viewer-container" :class="{ 'background': background }">
    <div class="spatial-viewer" ref="spatial-viewer">
    </div>
    <div v-if="placeholder && !loaded" class="temp-image" :style="`background-image:url(${placeholder})`"></div>
    <transition name="fade">
      <LoaderCover v-if="!loaded" transparentFull center status="Loading Models..." />
    </transition>
    <div class="overlay-content">
      <v-icon class="rotate-indicator">mdi-rotate-3d</v-icon>
    </div>
  </div>
</template>

<script>
import {
  AnimationMixer,
  BoxGeometry,
  CircleGeometry,
  Clock,
  Color,
  EquirectangularReflectionMapping,
  HalfFloatType,
  LinearFilter,
  LinearToneMapping,
  Mesh,
  MeshBasicMaterial,
  PerspectiveCamera,
  PointLight,
  RGBAFormat,
  Scene,
  sRGBEncoding,
  Vector2,
  WebGLRenderer,
  WebGLRenderTarget
} from 'three'

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader'
import { Reflector } from 'three/examples/jsm/objects/Reflector'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass'
import { GammaCorrectionShader } from 'three/examples/jsm/shaders/GammaCorrectionShader'

import default3dViewerSettings from '../../common/default3dViewerSettings'

export default {
  props: {
    src: {
      type: String,
      default: ''
    },
    settings: {
      type: Object,
      default: () => default3dViewerSettings
    },
    placeholder: {
      type: String,
      default: ''
    },
    square: {
      type: Boolean,
      default: false
    },
    background: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loaded: false,
      animationId: null,
      scene: null,
      renderer: null,
      composer: null,
      loader: null,
      camera: null,
      model: null,
      width: null,
      height: null,
      groundGeom: null,
      groundMirror: null,
      overlayGeom: null,
      matStdFloor: null,
      mshStdFloor: null,
      animationMixer: null,
      clock: null,
      lights: []
    }
  },
  computed: {
    controlsEnabled () {
      return !this.settings?.controls?.disabled
    },
    groundEnabled () {
      return !this.settings?.ground?.disabled
    }
  },
  async mounted () {
    console.log('DEFAULT VIEWER SETTINGS', default3dViewerSettings)
    await this.init()
  },
  methods: {
    /**
     * Applies the external settings object to the various items in the scene
     */
    applySettings () {
      console.log('Applying 3D viewer settings.')
      this.scene.background = new Color(parseInt(this.settings.backgroundColor, 16))

      if (this.model) {
        this.model.scale.x = this.settings.model.scale
        this.model.scale.y = this.settings.model.scale
        this.model.scale.z = this.settings.model.scale
      }
      this.camera.fov = this.settings.camera.fov
      this.camera.near = this.settings.camera.near
      this.camera.far = this.settings.camera.far
      this.camera.aspect = this.width / this.height
      this.camera.position.set(this.settings.camera.position.x, this.settings.camera.position.y, this.settings.camera.position.z)

      if (this.groundEnabled && this.groundMirror) {
        // Note that mirror color isn't not affected here. (Need to figure out how to set reflector color)
        this.groundMirror.position.y = this.settings.ground.position.y

        this.matStdFloor.opacity = this.settings.ground.overlay.opacity

        this.mshStdFloor.position.y = this.settings.ground.position.y - 0.04
        this.mshStdFloor.material.color.setHex(parseInt(this.settings.ground.overlay.color, 16))
      }

      if (this.controlsEnabled && this.controls) {
        this.controls.minDistance = this.settings.controls.minDistance
        this.controls.maxDistance = this.settings.controls.maxDistance
        if (this.settings.controls?.disableZoom) {
          this.controls.enableZoom = false
          this.controls.minDistance = this.controls.maxDistance
        }
        if (this.settings.controls?.disablePan) {
          this.controls.enablePan = false
        }
        this.controls.update()
      }

      this.settings.lights.forEach((setting, index) => {
        if (this.lights[index]) {
          this.lights[index].color.set(parseInt(setting.color, 16))
          this.lights[index].intensity = setting.intensity
          this.lights[index].distance = setting.distance
        }
      })
      this.camera.updateProjectionMatrix()
      this.render()
    },

    /**
     * Frees up resources from the renderer / scene
     */
    resetRenderer () {
      this.loaded = false
      const renderElement = this.$refs['spatial-viewer']
      while (renderElement.lastChild) renderElement.removeChild(renderElement.lastChild)
      this.scene = null
      this.camera = null
      this.model = null
      this.controls = null
      this.renderer = null
      this.composer = null
      this.loader = null

      if (this.groundGeom) {
        this.groundGeom.dispose()
        this.groundGeom = null
      }
      if (this.groundMirror) {
        this.groundMirror = null
        this.overlayGeom.dispose()
      }
      if (this.overlayGeom) {
        this.overlayGeom = null
        this.matStdFloor.dispose()
      }

      this.matStdFloor = null
      this.mshStdFloor = null
      this.animationMixer = null
      this.lights = []
      window.removeEventListener('resize', this.onResize)
      cancelAnimationFrame(this.animationId)
      this.animationId = null
    },

    initRenderer () {
      this.scene = new Scene()
      this.renderer = new WebGLRenderer({ antialias: true })
      const renderElement = this.$refs['spatial-viewer']
      renderElement.appendChild(this.renderer.domElement)
      this.width = this.background ? renderElement.clientWidth : Math.min(renderElement.clientWidth, 1000)
      this.height = this.square ? renderElement.clientWidth : (this.background ? renderElement.clientHeight : window.innerHeight * 0.6)
      this.renderer.setPixelRatio(window.devicePixelRatio)
      this.renderer.setSize(this.width, this.height)
      this.renderer.toneMapping = LinearToneMapping
      this.renderer.toneMappingExposure = 1.3
      this.renderer.outputEncoding = sRGBEncoding
    },

    initCamera () {
      this.camera = new PerspectiveCamera()
    },

    async loadAssets () {
      console.log('Load 3D assets.')
      return new Promise((resolve) => {
        new RGBELoader()
          .setPath('/textures/equirectangular/')
          .load('AdobeStock_349026077_5.hdr', (texture) => {
            texture.mapping = EquirectangularReflectionMapping
            this.scene.environment = texture

            this.render()

            this.loader = new GLTFLoader()
            this.loader.load(this.src, (gltf) => {
              this.model = gltf.scene
              this.model.renderOrder = 2
              console.log('SETTINGS', this.settings)
              if (this.settings?.animation?.enabled) {
                this.animationMixer = new AnimationMixer(gltf.scene)
                if (gltf.animations) {
                  const defaultAnimation = this.animationMixer.clipAction(gltf.animations[this.settings.animation.index || 0])
                  defaultAnimation.play()
                }
              }
              this.scene.add(this.model)

              this.render()
              resolve()
            })
          })
      })
    },

    /**
     * Set up all lights in the scene.
     */
    setLights () {
      console.log('Initializing lights.')
      this.settings.lights.forEach((setting, index) => {
        this.lights[index] = new PointLight(parseInt(setting.color, 16), setting.intensity, setting.distance)
        this.scene.add(this.lights[index])
      })
    },

    /**
     * Set up controls for user initiated movement of the 3D scene.
     * Note that this function sets constraints on zoom, pan, etc...
     */
    setControls () {
      this.controls = new OrbitControls(this.camera, this.renderer.domElement)
      this.controls.addEventListener('change', this.render)
      this.controls.minPolarAngle = 0
      this.controls.maxPolarAngle = Math.PI * (this.settings.controls.maxPolarAngle || 0.54) // 0.54 for legacy Naturel card / box
      this.controls.target.set(0, 0, 0)
    },

    /**
     * Subroutine which builds the floor geometry and mirror reflector.
     */
    setupGround () {
      console.log('Initialize ground geometry.')
      // Build mirror
      this.groundGeom = new CircleGeometry(40, 64)
      this.groundMirror = new Reflector(this.groundGeom, {
        clipBias: 0.003,
        textureWidth: this.width,
        textureHeight: this.height,
        color: new Color(parseInt(this.settings.ground.mirror.color, 16))
      })
      this.groundMirror.rotateX(-Math.PI / 2)
      this.groundMirror.renderOrder = 0
      this.scene.add(this.groundMirror)

      // Build floor "overlay"
      this.overlayGeom = new BoxGeometry(2000, 0.1, 2000)
      this.matStdFloor = new MeshBasicMaterial({
        color: parseInt(this.settings.ground.overlay.color, 16)
      })
      this.matStdFloor.transparent = true
      this.matStdFloor.flatShading = true

      this.mshStdFloor = new Mesh(this.overlayGeom, this.matStdFloor)
      this.mshStdFloor.renderOrder = 1
      this.scene.add(this.mshStdFloor)
    },

    initPostProcessing () {
      console.log('Initialize post-processing')
      this.composer = new EffectComposer(this.renderer, new WebGLRenderTarget(2048, 2048, {
        minFilter: LinearFilter,
        magFilter: LinearFilter,
        format: RGBAFormat,
        type: HalfFloatType,
        stencilBuffer: false
      }))

      const renderPass = new RenderPass(this.scene, this.camera)

      this.composer.addPass(renderPass)

      if (this.settings?.postProcessing?.bloom?.enabled) {
        console.log('Bloom enabled.')
        const bloomPass = new UnrealBloomPass(new Vector2(window.innerWidth, window.innerHeight), 1.5, 0.4, 0.85)
        const { threshold, strength, radius } = this.settings.postProcessing.bloom
        bloomPass.threshold = threshold || 0
        bloomPass.strength = strength || 0
        bloomPass.radius = radius || 0

        this.composer.addPass(bloomPass)
      }

      if (this.settings?.postProcessing?.gammaCorrection?.enabled) {
        console.log('Gamma correction enabled.')
        const gammaCorrectionPass = new ShaderPass(GammaCorrectionShader)
        this.composer.addPass(gammaCorrectionPass)
      }
    },

    /**
     * Aggregate all subroutines and set up listeners.
     */
    async init () {
      console.log('Initializing scene.')
      this.initRenderer()
      this.initCamera()
      await this.loadAssets()
      this.setLights()
      if (this.groundEnabled) {
        this.setupGround()
      }
      if (this.controlsEnabled) {
        this.setControls()
      }
      this.clock = new Clock()
      this.applySettings()
      if (this.settings?.postProcessing?.enabled) {
        this.initPostProcessing()
      }
      this.animate()
      window.addEventListener('resize', this.onResize)
      this.loaded = true
    },

    onResize () {
      const width = this.background ? window.innerWidth : this.width
      const height = this.background ? window.innerHeight : this.height
      this.camera.aspect = width / height
      this.camera.updateProjectionMatrix()
      this.renderer.setSize(width, height)
      if (this.composer) {
        this.composer.setSize(width, height)
      }
      this.render()
    },

    render () {
      if (this.renderer?.render) {
        this.renderer.render(this.scene, this.camera)
      }
    },

    animateLights () {
      const time = Date.now() * 0.0005
      this.lights.forEach((light, index) => {
        const lightSettings = this.settings.lights[index]
        if (lightSettings.animate) {
          light.position.x = Math.sin(time * lightSettings.animationRate.x) * lightSettings.animationScale.x
          light.position.y = Math.sin(time * lightSettings.animationRate.y) * lightSettings.animationScale.y
          light.position.z = Math.sin(time * lightSettings.animationRate.z) * lightSettings.animationScale.z
        }
      })
    },

    animate () {
      this.animationId = requestAnimationFrame(this.animate)
      this.animateLights()
      if (this.loaded && this.settings.autoRotate) {
        this.model.rotation.y += 0.002
      }
      if (this.animationMixer) {
        const delta = this.clock.getDelta()
        this.animationMixer.update(delta)
      }
      this.render()
      if (this.settings?.postProcessing?.enabled && this.composer) {
        this.composer.render()
      }
    }
  },

  watch: {
    src: async function () {
      console.log('Spatial viewer src updated.')
      this.resetRenderer()
      await this.init()
    },
    settings: {
      async handler () {
        if (this.loaded) {
          console.log('Spatial viewer settings updated.')
          // this.applySettings()
          this.resetRenderer()
          await this.init()
        }
      },
      deep: true
    }
  },

  beforeDestroy () {
    this.resetRenderer()
  }
}
</script>

<style lang="scss" scoped>
.spatial-viewer-container {
  position: relative;
  width: 100%;

  .temp-image {
    @include fill-parent;
    @include bg-cover-center;
  }

  .overlay-content {
    @include fill-parent;
    pointer-events: none;

    .rotate-indicator {
      position: absolute;
      bottom: $space-m;
      right: $space-m;
    }
  }

  &.background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    .overlay-content {
      display: none;
    }
    .spatial-viewer {
      border: none;
      height: 100%;
    }
  }
}

.spatial-viewer {
  cursor: grab;
  border: solid 1px white;
  width: 100%;
  overflow: hidden;
}
</style>
