<template>
  <BlkIcon v-bind="$attrs" viewBox="0 0 17 17">
    <path d="M0.867187 7.50682C0.867186 11.3668 4.00719 14.5068 7.86719 14.5068C8.05093 14.5068 8.20031 14.3574 8.20031 14.1737C8.20031 13.9893 8.05093 13.84 7.86719 13.84C4.37535 13.84 1.53407 10.9987 1.53407 7.50684C1.53407 4.015 4.37535 1.17372 7.86719 1.17372C11.359 1.17372 14.2003 4.015 14.2003 7.50684C14.2003 7.69058 14.3497 7.83996 14.5334 7.83996C14.7172 7.83996 14.8665 7.69058 14.8665 7.50684C14.8672 3.64684 11.7272 0.506837 7.86719 0.506836C4.00719 0.506836 0.867187 3.64684 0.867187 7.50684L0.867187 7.50682Z" />
    <path d="M7.8673 2.60059C7.68356 2.60059 7.53418 2.74996 7.53418 2.93371L7.53418 7.50683C7.53418 7.69057 7.68355 7.83995 7.8673 7.83995L11.0191 7.83995C11.2029 7.83995 11.3523 7.69057 11.3523 7.50683C11.3523 7.32308 11.2029 7.17371 11.0191 7.17371L8.20042 7.17371L8.20042 2.93435C8.20042 2.74935 8.05105 2.6006 7.8673 2.6006L7.8673 2.60059Z" />
    <path d="M16.0931 11.2807L15.5006 11.2807L15.5006 10.8069C15.5006 9.90632 14.7675 9.17383 13.8668 9.17383C12.9662 9.17383 12.2331 9.90695 12.2331 10.8069L12.2331 11.2807L11.6406 11.2807C11.2131 11.2807 10.8662 11.6363 10.8662 12.0726L10.8662 15.7132C10.8675 16.1513 11.215 16.507 11.6418 16.507L16.093 16.507C16.5199 16.507 16.8674 16.1513 16.8674 15.7145L16.8674 12.0738C16.8674 11.6363 16.5199 11.2807 16.093 11.2807L16.0931 11.2807ZM12.9 10.8069C12.9 10.2738 13.3337 9.84007 13.8675 9.84007C14.4006 9.84007 14.835 10.2732 14.835 10.8069L14.835 11.2807L12.9006 11.2807L12.9 10.8069ZM16.2006 15.7145C16.2006 15.7838 16.1531 15.8401 16.0931 15.8401L11.6419 15.8401C11.5819 15.8401 11.5344 15.7838 11.5344 15.7145L11.5344 12.0738C11.5344 12.0045 11.5825 11.9482 11.6425 11.9482L16.0937 11.9482C16.1531 11.9482 16.2019 12.0051 16.2019 12.0738L16.2019 15.7145L16.2006 15.7145Z" />
    <path d="M13.8672 13.3379C13.5603 13.3379 13.3115 13.5866 13.3115 13.8935C13.3115 14.2004 13.5603 14.4492 13.8672 14.4492C14.174 14.4492 14.4228 14.2004 14.4228 13.8935C14.4228 13.5866 14.174 13.3379 13.8672 13.3379Z" />
  </BlkIcon>
</template>

<script>
export default { inheritAttrs: false }
</script>
