<template>
  <svg
    class="blk-icon"
    :class="type"
    :style="`width: ${size}px; height: ${size}px; ${mirror && 'transform: scaleX(-1);'}`"
    :viewBox="viewBox"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <slot></slot>
  </svg>
</template>

<script>
export default {
  created () {
    this.setColor()
    this.setStroke()
  },
  beforeUpdate () {
    this.setColor()
    this.setStroke()
  },
  props: {
    type: {
      type: String,
      default: ''
    },
    size: {
      type: Number,
      default: 24
    },
    viewBox: {
      type: String,
      default: '0 0 24 24'
    },
    color: {
      type: String,
      default: '#101010'
    },
    fill: {
      type: Boolean,
      default: true
    },
    stroke: {
      type: Boolean,
      default: false
    },
    strokeWidth: {
      type: Number,
      default: null
    },
    mirror: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    setColor () {
      this.$slots.default.forEach((vNode) => {
        vNode.data.attrs.stroke = this.stroke && this.color
        vNode.data.attrs.fill = this.fill && this.color
      })
    },
    setStroke () {
      if (this.strokeWidth) {
        this.$slots.default.forEach((vNode) => {
          vNode.data.attrs['stroke-width'] = this.strokeWidth
        })
      }
    }
  }
}
</script>

<style>
.blk-icon {
  vertical-align: middle;
}
</style>
