/**
 * Handles operations related to retrieving information about Token Contracts and Token Types
 * from the Firestore database.
 * Many of these operations rely on off-chain data related to minted Token Contracts and Types,
 * which should be added to the Firestore model on mint.
 */

import fb from '../../../firebase_config/firebase'
import {
  doc,
  collection,
  collectionGroup,
  getDoc,
  getDocs,
  query,
  where,
  orderBy,
  onSnapshot
} from 'firebase/firestore'

const state = () => ({
  tokenContracts: null,
  contractsListenerUnsub: null,
  targetCollectionTypes: null,
  targetTypeData: null
})

const getters = {
  tokenContracts: state => state.tokenContracts,
  targetCollectionTypes: state => state.targetCollectionTypes,
  targetCollectionPrimary: (state) => {
    const output = []
    const added = []
    // Reverse array first, to default to rarest type
    // state.targetCollectionTypes.slice().reverse().forEach((type) => {
    state.targetCollectionTypes.slice().forEach((type) => {
      type.title = type.typePrimary.name
      // Only add one of each type
      if (!added.includes(type.typePrimary.id)) {
        output.push(type)
        added.push(type.typePrimary.id)
      }
    })
    return output
  },
  targetCollectionSecondary: (state) => (id) => {
    const output = []
    state.targetCollectionTypes.forEach((type) => {
      const segments = type.id.split('.')
      type.title = type.name
      console.log(type)
      if (segments[0] === id) output.push(type)
    })
    return output
  },
  targetTypeData: state => state.targetTypeData
}

const mutations = {
  setTokenContracts (state, val) {
    state.tokenContracts = val
  },
  setContractsListenerUnsub (state, val) {
    state.contractsListenerUnsub = val
  },
  setTargetCollectionTypes (state, val) {
    state.targetCollectionTypes = val
  },
  setTargetTypeData (state, val) {
    state.targetTypeData = val
  }
}

const actions = {
  /**
   * Get all Token Contract (Collection) documents from the Firestore database,
   * then save to state
   */
  async getTokenContracts ({ commit }, activeOnly = true) {
    const tokenContracts = []
    let q
    if (activeOnly) {
      q = query(fb.tokenContracts, where('active', '==', true), orderBy('created', 'desc'))
    } else {
      q = query(fb.tokenContracts, orderBy('name'))
    }
    const contracts = await getDocs(q)

    contracts.forEach((contract) => {
      const copy = contract.data()
      copy.id = contract.id
      tokenContracts.push(copy)
    })
    commit('setTokenContracts', tokenContracts)
  },

  async tokenContractsListener ({ commit, state }) {
    if (state.contractsListenerUnsub != null) {
      console.log('Listener for token contracts set up. Returning.')
      return
    }
    const tokenContracts = []
    const unsub = onSnapshot(fb.tokenContracts, (contractsSnapshot) => {
      contractsSnapshot.forEach((contract) => {
        const copy = contract.data()
        copy.id = contract.id
        tokenContracts.push(copy)
      })
      commit('setTokenContracts', tokenContracts)
    })
    commit('setContractsListenerUnsub', unsub)
  },

  /**
   * Gets all types associated with a particular Token Contract (Collection) from the Firestore database.
   * @param {String} payload - { contract, mode }
   */
  async getTokenContractTypes ({ commit, state, rootState }, payload) {
    const tokenTypes = []
    const collectionRef = collection(fb.db, 'token-contracts', payload.contract, 'types')
    const q = payload.mode === 'all'
      ? query(collectionRef, orderBy('name'))
      : query(collectionRef, where('active', '==', true), orderBy('name'))
    const types = await getDocs(q)
    types.forEach((type) => {
      const copy = type.data()
      copy.id = type.id
      copy.tokenAddress = payload.contract
      const variants = rootState.marketCollection.variants
      const index = variants && variants.secondary ? variants.secondary.map((e) => { return e.id }).indexOf(copy.typeSecondary.id) : 0
      copy.order = index
      tokenTypes.push(copy)
    })
    // Sort according to order and then primary ID
    tokenTypes.sort((a, b) => {
      return a.order - b.order
    })
    tokenTypes.sort((a, b) => {
      return b.typePrimary.id.localeCompare(a.typePrimary.id)
    })
    commit('setTargetCollectionTypes', tokenTypes)
    return tokenTypes
  },

  /**
   * Gets all the types in a target contract, either all those with order 0, or all those marked "featured"
   * Currently returns all featured by default
   * @param {object} payload - { contract, mode }
   * @returns an array of the token type objects
   */
  async getTargetTypes ({ commit }, payload) {
    const tokenTypes = []
    const collectionRef = collection(fb.db, 'token-contracts', payload.contract, 'types')
    const orderZeroQuery = query(collectionRef, where('order', '==', 0), where('active', '==', true))
    const featuredQuery = query(collectionRef, where('featured', '==', true), where('active', '==', true))

    const q = payload.mode === 'orderZero' ? orderZeroQuery : featuredQuery
    const types = await getDocs(q)
    types.forEach((type) => {
      const copy = type.data()
      copy.id = type.id
      copy.tokenAddress = payload.contract
      tokenTypes.push(copy)
    })
    // Sort by series order
    // TODO: This is a short term solution, and won't work properly once there are multiple series in a collection
    tokenTypes.sort((a, b) => { return a.seriesOrder - b.seriesOrder })
    return tokenTypes
  },

  /**
   * Get data about a target Token Type from the Firestore database.
   * @param {Object} data - contains the target Token Contract ID and Type ID
   */
  async getTargetTypeData ({ commit }, data) {
    console.log(`Getting type data for ${data.type} in collection ${data.contract}.`)
    const type = await getDoc(doc(fb.db, 'token-contracts', data.contract, 'types', data.type))
    if (type.exists()) {
      const copy = type.data()
      copy.id = type.id
      if (!data.return) {
        commit('setTargetTypeData', copy)
      } else {
        return copy
      }
    } else {
      throw new Error(`The target token type ${data.type} does not exist.`)
    }
  },

  async getSeriesTypes ({ commit }, seriesId) {
    const tokenTypes = []
    const snapshot = await getDocs(query(collectionGroup(fb.db, 'types'), where('series.id', '==', seriesId)))
    snapshot.forEach((type) => {
      const path = type.ref.path
      const collection = path.split('/')[1]
      const copy = type.data()
      copy.id = type.id
      copy.collection = collection
      tokenTypes.push(copy)
    })

    const typesFormatted = []
    tokenTypes.forEach((type) => {
      // TODO: This is fine for now, but really anything that's not "active" shouldn't even be pulled to the front end
      // But this will also filter out the associated market data docs, so those will need to be retrieved separately.
      if (type.name && type.active) {
        typesFormatted.push(type)
      }
    })
    tokenTypes.forEach((type) => {
      if (!type.name) {
        const index = typesFormatted.findIndex(item => item.id === type.id)
        if (typesFormatted[index]) {
          typesFormatted[index].marketData = type
        }
      }
    })
    // Sort by seriesOrder if available
    typesFormatted.sort((a, b) => { return a.seriesOrder - b.seriesOrder })
    return typesFormatted
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
