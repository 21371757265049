import Vue from 'vue'
import Vuex from 'vuex'

import fb from '../../firebase_config/firebase'
import { doc, query, getDoc, getDocs } from 'firebase/firestore'
import { signOut } from 'firebase/auth'

import router from '../router'

import MarketSettings from './modules/marketSettings'
import User from './modules/user'
import Web3 from './modules/web3'
import Tokens from './modules/tokens'
import MarketData from './modules/marketData'
import Currency from './modules/currency'
import ClaimsData from './modules/claimsData'
import CollectionGroups from './modules/collectionGroups'
import EventData from './modules/eventData'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isMobileDevice: null,
    marketCollection: {},
    titleLink: '',
    previousRoute: null,
    minting: null, // Is the user currently in the minting process?
    loading: false,
    loadingStatus: '',
    unobtrusiveLoader: false,
    mintingData: {},

    targetToken: {},
    provenanceToken: {},

    mintingCollection: {
      variants: {}
    },

    navOverlay: false, // Is the mobile nav overlay currently visible?
    hideNavigation: true,
    hideFooter: true,
    inPurchaseFlow: false,
    confirmDelist: false,
    targetPurchase: {},
    showingLogin: false,
    showingTokenDetails: false,
    showingAddFunds: false,
    showingSendEth: false,
    showingTopupWallet: false,
    showingBackupWallet: false,
    showingMoonPayUnsupported: false,
    showingTokenProvenance: false,
    reenterEmail: false,
    loginEmail: null,
    showingLandingNotification: false,

    userPortfolio: [],
    notifications: [],
    promotions: [],
    allSeries: []
  },
  getters: {
    minting: state => state.minting,
    loading: state => state.loading,
    loadingStatus: state => state.loadingStatus,
    unobtrusiveLoader: state => state.unobtrusiveLoader,
    mintingData: state => state.mintingData,
    titleLink: state => state.titleLink,
    previousRoute: state => state.previousRoute,
    marketCollection: state => state.marketCollection,

    targetToken: state => state.targetToken,
    provenanceToken: state => state.provenanceToken,

    mintingCollection: state => state.mintingCollection,

    userPortfolio: state => state.userPortfolio,
    promotions: state => state.promotions,
    allSeries: state => state.allSeries,
    notifications: state => state.notifications,
    navOverlay: state => state.navOverlay,
    hideNavigation: state => state.hideNavigation,
    hideFooter: state => state.hideFooter,
    showingLogin: state => state.showingLogin,
    reenterEmail: state => state.reenterEmail,
    loginEmail: state => state.loginEmail,

    inPurchaseFlow: state => state.inPurchaseFlow,
    confirmDelist: state => state.confirmDelist,
    showingTokenDetails: state => state.showingTokenDetails,
    showingAddFunds: state => state.showingAddFunds,
    showingSendEth: state => state.showingSendEth,
    showingTopupWallet: state => state.showingTopupWallet,
    showingBackupWallet: state => state.showingBackupWallet,
    showingMoonPayUnsupported: state => state.showingMoonPayUnsupported,
    showingTokenProvenance: state => state.showingTokenProvenance,
    targetPurchase: state => state.targetPurchase,
    showingLandingNotification: state => state.showingLandingNotification
  },
  mutations: {
    setGlobalState (state, { target, val }) {
      // console.log(`Setting ${target} to ${val}.`)
      state[target] = val
    },
    setMinting (state, val) {
      state.minting = val
    },
    setLoading (state, val) {
      state.loading = val
    },
    setTitleLink (state, val) {
      state.titleLink = val
    },
    setMarketCollection (state, val) {
      state.marketCollection = val
    },

    setShowingLogin (state, val) {
      state.showingLogin = val
    },
    setReenterEmail (state, val) {
      state.showingLogin = val
      state.reenterEmail = val
    },
    setShowingTokenDetails (state, val) {
      state.showingTokenDetails = val
    },
    setShowingTokenProvenance (state, val) {
      state.showingTokenProvenance = val.show
      state.provenanceToken = val.payload
    },
    setTargetPurchase (state, val) {
      state.targetPurchase = val
    },

    setTargetToken (state, val) {
      state.targetToken = val
    },

    setMintingCollection (state, val) {
      state.mintingCollection = val
    },

    setMintingData (state, val) {
      state.mintingData = val
    },

    clearMintingData (state) {
      console.log('Clearing minting data.')
      state.mintingData = {}
      state.mintingCollection = {}
    },

    addToUserPortfolio (state, val) {
      state.userPortfolio.push(val)
    },

    addNotification (state, val) {
      state.notifications.push(val)
    },
    removeNotification (state, index) {
      state.notifications.splice(index, 1)
    }
  },
  actions: {
    /**
     * Used to evaluate whether or not the user is on a mobile device.
     */
    isAndroidOrIos () {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera
      const state = /android/i.test(userAgent) || (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)
      console.log('Checking for Android / iOS:', state)
      return state
    },

    initiateLogin ({ commit }) {
      commit('setShowingLogin', true)
      commit('setGlobalState', { target: 'navOverlay', val: false })
    },

    initiatePurchase ({ commit, dispatch, getters }, listingData) {
      if (getters.currentUser) {
        console.log('A user is present. Proceeding to purchase.')
      } else {
        console.log('No user logged in. Showing login modal.')
        dispatch('initiateLogin')
        return
      }

      commit('setTargetPurchase', listingData)
      commit('setGlobalState', { target: 'inPurchaseFlow', val: true })
    },

    cancelPurchase ({ commit }) {
      commit('setGlobalState', { target: 'inPurchaseFlow', val: false })
    },

    /**
     * This function loads all promotions from the Firestore database.
     * Note that this only loads top level promotion data, and not any subcollections.
     */
    async loadPromotions ({ commit }) {
      const snapshot = await getDocs(fb.promotionsCollection)
      const promotions = [{ name: 'Select Promotion', id: null }]
      snapshot.forEach((promotion) => {
        promotions.push(promotion.data())
      })
      commit('setGlobalState', { target: 'promotions', val: promotions })
    },

    /**
     * Loads all series from the Firestore database.
     */
    async loadAllSeries ({ commit }, includePlaceholder = false) {
      const snapshot = await getDocs(query(fb.seriesCollection))
      const allSeries = includePlaceholder ? [{ name: 'Select Series', id: null }] : []
      snapshot.forEach((series) => {
        allSeries.push(series.data())
      })
      commit('setGlobalState', { target: 'allSeries', val: allSeries })
    },

    logout ({ commit, dispatch, state }) {
      signOut(fb.auth).then(() => {
        commit('setUserProfile', null)
        commit('setUserNearSignature', null)
        commit('setNearConnection', null)
        commit('setCurrentUser', null)
        dispatch('stopUserClaimsListener')
        dispatch('stopUserProfileListener')
        window.sessionStorage.removeItem('selectedWallet')
        window.localStorage.removeItem('liveClaimTermsAgreed')
        window.localStorage.removeItem('emailForSignIn')
        window.localStorage.removeItem('claimEmail')
        if (router.currentRoute.path !== state.MarketSettings.globalSettings.marketHome) {
          router.push(state.MarketSettings.globalSettings.marketHome)
        } else if (router.currentRoute.query.apiKey) {
          router.replace({ apiKey: null }) // Clear the API key if it's in the URL
        }
        commit('setGlobalState', { target: 'navOverlay', val: false })
        console.log('User logged out.')
      })
    },

    async marketCollectionCheck ({ state, commit, dispatch }, collectionId) {
      if (state.marketCollection.id !== collectionId) {
        try {
          const collection = await getDoc(doc(fb.db, 'token-contracts', collectionId))
          const copy = collection.data()
          copy.id = collection.id
          commit('setMarketCollection', copy)
        } catch (err) {
          console.log('Error getting collection data. It may not exist in the Firestore database.')
        }
      }
    }
  },
  modules: {
    MarketSettings,
    User,
    Web3,
    Tokens,
    MarketData,
    Currency,
    ClaimsData,
    CollectionGroups,
    EventData
  }
})
