<template>
  <div v-bind:class="variant">{{text}}<slot></slot></div>
</template>

<script>
export default {
  props: {
    variant: String,
    text: String
  }
}
</script>

<style lang="scss" scoped>
.pc-title-48 {
  @include desktop {
    @include title(48px, 120%);
  }
}

.pc-title-32 {
  @include desktop {
    @include title(32px, 120%);
  }
}

.pc-title-28 {
  @include desktop {
    @include title(28px, 120%);
  }
}

.pc-title-24 {
  @include desktop {
    @include title(24px, 120%);
  }
}

.mobile-title-24 {
  @include mobile {
    @include title(24px, 120%);
  }
}

.mobile-title-28 {
  @include mobile {
    @include title(28px, 120%);
  }
}

.mobile-title-32 {
  @include mobile {
    @include title(32px, 120%);
  }
}

.pc-body-24 {
  @include desktop {
    @include body(24px, 160%);
  }
}

.pc-body-16 {
  @include desktop {
    @include body(16px, 25.6px);
  }
}

.mobile-body-14 {
  @include mobile {
    @include body(14px, 160%);
  }
}

.pc-sub-16 {
  @include desktop {
    @include sub(16px, 150%);
  }
}

.mobile-sub-14 {
  @include mobile {
    @include sub(14px, 160%);
  }
}

.mobile-sub-12 {
  @include mobile {
    @include sub(12px, 160%);
  }
}

.caps {
  text-transform: uppercase;
}

.bold {
  font-weight: bold;
}

.center {
  text-align: center;
}
</style>
