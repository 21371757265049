<template>
  <BlkIcon v-bind="$attrs" viewBox="0 0 24 28">
    <path d="M12.7029 3.68771V1.84085H13.406C13.7939 1.84085 14.1092 1.52561 14.1092 1.13771C14.1092 0.74981 13.7939 0.43457 13.406 0.43457H10.5935C10.2056 0.43457 9.89037 0.74981 9.89037 1.13771C9.89037 1.52561 10.2056 1.84085 10.5935 1.84085H11.2967V3.68771C7.11645 3.9338 3.37125 6.34904 1.42125 10.0546C-0.527578 13.7602 -0.396328 18.2143 1.76694 21.799C3.93138 25.3825 7.81254 27.5728 11.9996 27.5728C16.1867 27.5728 20.0681 25.3826 22.2323 21.799C24.3956 18.2143 24.5269 13.7599 22.578 10.0546C20.628 6.34901 16.8828 3.93401 12.7026 3.68771H12.7029ZM11.9997 26.1595C9.20247 26.1595 6.51993 25.0486 4.54203 23.0704C2.56413 21.0922 1.45293 18.4099 1.45293 15.6127C1.45293 12.8155 2.56386 10.1329 4.54203 8.15501C6.5202 6.17711 9.20253 5.06591 11.9997 5.06591C14.7969 5.06591 17.4795 6.17684 19.4574 8.15501C21.4353 10.1332 22.5465 12.8155 22.5465 15.6127C22.5442 18.4088 21.4321 21.0901 19.455 23.0683C17.4769 25.0452 14.7957 26.1574 11.9994 26.1598L11.9997 26.1595Z" fill="#101010"/>
    <path d="M14.1457 16.0862C14.3179 15.3409 14.0882 14.5605 13.5398 14.0261C12.9914 13.4929 12.205 13.2843 11.4644 13.4753C10.9031 13.6136 10.4203 13.9722 10.1238 14.4691C9.7136 15.1476 9.7007 15.9948 10.0886 16.6862C10.4765 17.3777 11.2054 17.8077 11.9988 17.8112C12.1792 17.8112 12.3585 17.7913 12.5331 17.7503C12.8566 17.6636 13.1542 17.503 13.405 17.2816L13.4378 17.305L18.9035 20.5864C19.0125 20.652 19.1367 20.6872 19.2644 20.6848C19.5797 20.6848 19.8551 20.4751 19.9406 20.1716C20.025 19.8681 19.8984 19.5458 19.63 19.3817L14.1643 16.1003L14.1457 16.0862ZM12.191 16.3909C11.8757 16.4718 11.5429 16.3534 11.3507 16.0921C11.1574 15.8296 11.1433 15.4769 11.3144 15.2003C11.4234 15.0186 11.6003 14.8886 11.8066 14.8394C11.8699 14.83 11.9355 14.83 11.9988 14.8394C12.4101 14.8276 12.7617 15.1312 12.8109 15.539C12.8613 15.9479 12.5929 16.3265 12.191 16.4144L12.191 16.3909Z" fill="#101010"/>
  </BlkIcon>
</template>

<script>
export default { inheritAttrs: false }
</script>
