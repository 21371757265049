// Global Vue imports
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

// Firebase
import { httpsCallable } from 'firebase/functions'
import { getDoc, doc } from 'firebase/firestore'
import fbConfig from '../firebase_config/firebase'

// Utility Libraries
import VueLazyLoad from 'vue-lazyload'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import moment from 'moment'
import axios from 'axios'

// Torus ServiceWorker
import './registerServiceWorker'

const { create } = require('ipfs-http-client')

// console.log('ENVIRONMENT:', process.env)

// Require all components in global folder
const components = require.context(
  '@/components/global',
  true,
  /[A-Z]\w+\.vue$/
)

// Loop through all required files and automatically create components
components.keys().forEach((fileName) => {
  const componentConfig = components(fileName)
  const componentName = upperFirst(
    camelCase(
      fileName.split('/').pop().replace(/\.\w+$/, '')
    )
  )
  Vue.component(
    componentName,
    componentConfig.default || componentConfig
  )
})

// These properties can be accessed anywhere throughout the app
Object.defineProperty(Vue.prototype, '$moment', { value: moment })
Object.defineProperty(Vue.prototype, '$axios', { value: axios })
Object.defineProperty(Vue.prototype, '$fb', { value: fbConfig })

Vue.use(VueLazyLoad)
Vue.config.productionTip = false

Vue.mixin({
  methods: {
    /**
     * Displays a global notification
     * @param {String} message - the message to display
     * @param {String} type - "info", "sucess", "error"
     * @param {Boolean} expires - should the notification disappear automatically
     * @param {String} linkText - link text to display
     * @param {String} routerLink - url for a local router link
     * @param {String} externalLink - url for an external link
     */
    addNotification (message, type, expires = true, linkText, routerLink, externalLink) {
      store.commit('addNotification', {
        message,
        type,
        expires,
        linkText,
        routerLink,
        externalLink,
        id: Math.random() // Add a random ID so Vue will properly remove from array
      })
    },
    getIconUrl (img) {
      return require(`./assets/icons/${img}`)
    },
    truncate (str, maxDecimalDigits = 4) {
      if (str.includes('.')) {
        const parts = str.split('.')
        return parts[0] + '.' + parts[1].slice(0, maxDecimalDigits)
      }
      return str
    },
    wordBreakCheck (stringToCheck, breakThreshold = 13) {
      let wordBreak = false
      const words = stringToCheck.split(' ')
      words.forEach((word) => {
        if (word.length > breakThreshold) {
          wordBreak = true
        }
      })
      return wordBreak
    },
    toFixed (str, maxDecimalDigits = 4) {
      const truncated = this.truncate(str, maxDecimalDigits)
      if (truncated.includes('.')) {
        const parts = truncated.split('.')
        return parts[0] + '.' + parts[1] + '0'.repeat(Math.max(0, maxDecimalDigits - parts[1].length))
      }
      return truncated + '.' + '0'.repeat(maxDecimalDigits)
    },
    getIpfsUrl: (hash) => {
      return `https://ipfs.io/ipfs/${hash}`
    },
    dynamicLink: (url, params) => {
      const segments = url.split('/')
      // Image can only be resized if it's a cloudinary link
      if (segments[2] !== 'res.cloudinary.com') {
        return url
      }
      segments.splice(segments.length - 3, 0, params)
      return segments.join('/')
    },

    /**
     * These utility functions return Cloudinary asset paths for assets based on a predetermined directory structure:
     * i.e. [root]/image/upload/generated-assets/[contract address]/[type id]/image
     * These assets are uploaded at the time of minting
     */
    tokenVideoPath (tokenContract, tokenType, size = 880) {
      const sizeString = size ? `/w_${size}` : ''
      return `${process.env.VUE_APP_CLOUDINARY_ROOT}/video/upload${sizeString}/generated-assets/${tokenContract}/${tokenType}/video.mp4`
    },
    tokenModelPath (tokenContract, tokenType) {
      return `${process.env.VUE_APP_CLOUDINARY_ROOT}/image/upload/generated-assets/${tokenContract}/${tokenType}/model.glb`
    },
    tokenAudioAttributePath (tokenContract, tokenType) {
      return `${process.env.VUE_APP_CLOUDINARY_ROOT}/video/upload/generated-assets/${tokenContract}/${tokenType}/audioAttribute.mp3`
    },
    tokenImagePath (tokenContract, tokenType, size = 780, useThumb = false, extension = 'png') {
      const sizeString = size ? `/w_${size}` : ''
      const root = `${process.env.VUE_APP_CLOUDINARY_ROOT}/image/upload${sizeString}/generated-assets/${tokenContract}/${tokenType}`
      if (useThumb) {
        return `${root}/thumb.${extension}`
      } else {
        return `${root}/image.${extension}`
      }
    },
    tokenImagePathUnclaimed (tokenContract, tokenType, size = 780) {
      return `${process.env.VUE_APP_CLOUDINARY_ROOT}/image/upload/w_${size}/generated-assets/${tokenContract}/${tokenType}/unclaimedImage.png`
    },
    primaryVariantImagePath (tokenContract, primaryVariant, size = 540) {
      return `${process.env.VUE_APP_CLOUDINARY_ROOT}/image/upload/w_${size}/stores/${process.env.VUE_APP_MARKET_ID}/collections/${tokenContract}/${primaryVariant}/image`
    },
    collectionImagePath (tokenContract, size = 780) {
      return `${process.env.VUE_APP_CLOUDINARY_ROOT}/image/upload/w_${size}/generated-assets/${tokenContract}/image`
    },
    logoUrl () {
      return `${process.env.VUE_APP_CLOUDINARY_ROOT}/stores/${process.env.VUE_APP_MARKET_ID}/logo`
    },

    nearExplorer () {
      return process.env.VUE_APP_NEAR_NETWORK === 'mainnet' ? 'https://explorer.near.org' : 'https://explorer.testnet.near.org'
    },

    createJsonBlob (object) {
      const jsonString = JSON.stringify(object, null, 2)
      return new Blob([jsonString], { type: 'application/json' })
    },

    blobToBase64 (blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => {
          resolve(reader.result.split(',')[1])
        }
        reader.onerror = () => {
          reject(reader.error)
        }
        reader.readAsDataURL(blob)
      })
    },

    async addAssetToIpfs (fileData) {
      const base64File = await this.blobToBase64(fileData)
      const { data } = await this.fbCall('addAssetToIpfs', {
        base64File
      })
      return data
    },

    /**
     * Upload an asset directly to IPFS from the client (to avoid Firebase function limits)
     * To execute, we first query the project ID / Secret stored on the server.
     * @param fileData - the file data to upload
     * @returns the IPFS hash of the uploaded media
     */
    async clientSideIpfsUpload (fileData) {
      console.log('Uploading to IPFS from client...')
      const res = await this.fbCall('minting-getInfuraIpfsInfo')
      const { projectId, projectSecret } = res.data
      const auth = `Basic ${Buffer.from(`${projectId}:${projectSecret}`).toString('base64')}`
      const client = create({
        host: 'ipfs.infura.io',
        port: 5001,
        protocol: 'https',
        headers: {
          authorization: auth
        }
      })
      const base64File = await this.blobToBase64(fileData)
      const { cid } = await client.add(Buffer.from(base64File, 'base64'))
      const hash = cid.toString()
      console.log(`Added and pinned asset, hash ${hash}.`)
      return hash
    },

    clientSideCloudinaryUpload (fileData) {
      // TODO: Upload to Cloudinary endpoint: https://cloudinary.com/documentation/vue_image_and_video_upload
      // this.$axios.post(``, 'client_default')
    },

    async checkPromotionClaimOwnership (promotion, email) {
      try {
        const claimRecord = await getDoc(doc(fbConfig.db, 'promotions', promotion, 'claim-records', email))
        if (claimRecord.exists()) {
          console.log(`${email} already has this claim.`)
          return true
        } else {
          return false
        }
      } catch (err) {
        this.addNotification('Error retrieving claim records.', 'error')
      }
    },

    /**
     * Returns JSON blob formatted metadata from the target URL
     * @param {String} url - the URL pointing to the metadata location
     * @returns a JSON object representing the metadata
     */
    getMetadata (url) {
      return new Promise((resolve, reject) => {
        if (url) {
          this.$axios.get(url).then((res) => {
            resolve(res.data)
          }).catch((err) => {
            reject(err)
          })
        } else {
          resolve(null)
        }
      })
    },

    /**
     * Concatenates the formatted token type from the primary and secondary type objects
     * @param {object} typeData - the type document returned from Firestore.
     * @returns the formatted token type.
     */
    formatType (typeData) {
      return typeData.typePrimary.id + '.' + typeData.typeSecondary.id
    },

    /**
     * Utility function used to parse the issuance number from the full Token ID.
     * @param {string} id - the full Token ID from which the issuance # should be calculated.
     * @returns the formatted issuance number.
     */
    formatIssuance (id) {
      const segments = id.split('.')
      if (segments.length > 1) {
        return parseInt(segments.pop())
      } else {
        return id
      }
    },

    /**
     * Utility function used to parse the token type from the full Token ID.
     * @param {string} id - the full Token ID from which the type should be calculated.
     * @returns the token type
     */
    parseTokenType (id) {
      const parts = (id).split('.')
      parts.pop()
      return parts.join('.')
    },

    /**
     * Utility function used to parse the user-friendly secondary type from the full Token ID.
     * @param {string} id - the full Token ID from which the secondary type should be calculated.
     * @param {Object} collection - the collection data used to extract the user-friendly secondary type
     * @returns the token's secondary type name
     */
    formatSecondaryType (id, collection) {
      // console.log(`Getting secondary for ${id} in ${collection.name}`)
      const segments = id.split('.')
      let name = ''
      if (segments[1]) {
        if (collection.variants && collection.variants.secondary) {
          collection.variants.secondary.forEach((variant) => {
            if (variant.id === segments[1]) {
              name = variant.name
            }
          })
        } else {
          return null
        }
      } else {
        return null
      }
      return name
    },

    /**
     * Utility function used to call Firebase functions.
     * @param {String} functionName - the target Firebase function to call
     * @param {Object} data - The data object to pass to the function
     * @param {Number} timeout - timeout, defaults to ~70 sec
     * @returns the response from the Firebase function
     */
    async fbCall (functionName, data, timeout) {
      // console.log(`Calling function ${functionName}.`)
      const call = httpsCallable(fbConfig.functions, functionName, { timeout })
      try {
        const res = await call(data)
        return res
      } catch (err) {
        console.log(err)
        throw err
      }
    },

    /**
     * Uploads an image to Cloudinary which represents a Primary Variant as a whole
     * Used by Mint_Collection and Mint_Token
     * @param {string} collectionId - the ID of the target collection associated with this variant
     * @param {object} variantData - contains information and media related to the variant
     */
    async uploadPrimaryVariantMedia (collectionId, variantData) {
      await this.fbCall('cloudinary-uploadAsset', {
        path: `stores/${process.env.VUE_APP_MARKET_ID}/collections/${collectionId}/${variantData.id}/image`,
        asset: variantData.image.rawData
      })
    },

    /**
     * Sets a cookie
     * @param {String} cname - the name of the cookie
     * @param {String} cvalue - the value of the cookie
     * @param {Number} exdays - the number of days until the cookie expires
     */
    setCookie (cname, cvalue, exdays = 30) {
      const expiryDate = new Date()
      expiryDate.setTime(expiryDate.getTime() + (exdays * 24 * 60 * 60 * 1000)) // 24 hrs, 60 min, 60 sec, 1000 ms
      const expires = `expires=${expiryDate.toUTCString()}`
      document.cookie = `${cname}=${cvalue};${expires};path=/`
    }
  }
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
