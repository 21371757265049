/**
 * Manages CRUD operations for collection groups.
 */
import fb from '../../../firebase_config/firebase'
import { doc, getDoc } from 'firebase/firestore'
const state = () => ({
  featuredEventData: null
})

const getters = {
  featuredEventData: state => state.featuredEventData
}

const mutations = {
  setFeaturedEventData (state, val) {
    state.featuredEventData = val
  }
}

const actions = {
  async getFeaturedEventData ({ state, commit }, promoIds) {
    if (state.featuredEventData !== null) {
      console.log('Already got featured event data.')
      return
    }
    const eventData = {}
    for (let i = 0; i < promoIds.length; i++) {
      const targetId = promoIds[i]
      const promo = await getDoc(doc(fb.db, 'promotions', targetId))
      const promoData = promo.data()
      eventData[targetId] = promoData
    }
    commit('setFeaturedEventData', eventData)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
