<template>
  <transition name="fade">
    <div v-if="errors.length" class="errors">
      <ul>
        <li v-for="(error, index) in errors" :key="`error-${index}`">{{ error }}</li>
      </ul>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ValidationErrors',
  props: {
    errors: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style scoped lang="scss">
.errors {
  margin: $space-m 0;
  border: solid 1px red;
  padding: $space-s;
  width: 100%;
  li {
    color: red;
    font-size: $font-size-xs;
  }
}
</style>
