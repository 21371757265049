<template>
    <div>
      <div v-for="(row, index) in rowsBefore" :key="`row-before-${index}`">
        <router-link :to="row.url">
          <div class="table-row">
            <div class="col-left">{{row.key}}</div>
            <div class="col-right">{{row.value}}</div>
          </div>
          <div class="divider" />
        </router-link>
      </div>
      <v-expand-transition>
        <div v-if="expanded" class="extra-rows">
          <div v-for="(row, index) in rowsAfter" :key="`row-after-${index}`">
            <router-link :to="row.url">
              <div class="table-row">
                <div class="col-left">{{row.key}}</div>
                <div class="col-right">{{row.value}}</div>
              </div>
              <div class="divider" />
            </router-link>
          </div>
        </div>
      </v-expand-transition>
      <div v-if="rowsAfter.length && !expanded" class="show-all" @click="expanded = true">
        Show All
      </div>
    </div>
</template>

<script>
export default {
  name: 'DataTable',
  props: {
    rowData: {
      type: Array,
      default: () => []
    },
    maxRows: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      expanded: false
    }
  },
  computed: {
    rowsBefore () {
      return this.rowData.slice(0, this.maxRows)
    },
    rowsAfter () {
      return this.rowData.slice(this.maxRows, this.rowData.length)
    }
  }
}
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
  color: white;
}
.table-row {
  display: flex;
  justify-content: space-between;

  font-family: Drive Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;

  margin-bottom: 5px;
  margin-top: 16px;
}

.divider {
  height: 1px;
  background: #666666;
  width: 100%;
}

.show-all {
  cursor: pointer;
  font-family: Drive Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;

  text-align: right;
  text-decoration-line: underline;
  text-transform: uppercase;

  margin: 11px 0;

  a {
    color: white;
  }
}
</style>
