<template>
  <div
    class="loader-cover"
    :class="{
      overlay: transparent,
      noCover: transparentFull,
      inverted: invert,
      fixed: fixed,
      unobtrusive: unobtrusive
    }"
    :style="zIndex && `z-index: ${zIndex}`"
  >
    <div class="content" :class="{ center: center, unobtrusive: unobtrusive }">
      <v-progress-linear
        :color="invert ? '#23F6A4' : 'white'"
        indeterminate
        reverse
        :size="small ? 40 : loaderSize"
      ></v-progress-linear>
      <p
        v-if="status"
        v-html="status"
        class="status-message"
      >
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoaderCover',
  props: {
    small: {
      type: Boolean,
      default: false
    },
    transparent: {
      type: Boolean,
      default: false
    },
    transparentFull: {
      type: Boolean,
      default: false
    },
    center: {
      type: Boolean,
      default: false
    },
    fixed: {
      type: Boolean,
      default: false
    },
    unobtrusive: {
      type: Boolean,
      default: false
    },
    loaderSize: {
      type: Number,
      default: 100
    },
    invert: {
      type: Boolean,
      default: false
    },
    status: {
      type: String,
      default: ''
    },
    zIndex: {
      type: Number,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
.loader-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $blk-white;
  display: flex;

  @include media($bp-phone-l) {
    justify-content: center;
  }

  .content {
    &.center {
      @include center-in-parent;
    }
    &.unobtrusive {
      top: auto;
      transform: none;
      left: $space-s;
      bottom: $space-s;
      .status-message {
        position: static;
        transform: none;
        text-align: left;
      }
    }
  }
  .v-progress-linear {
    width: 100px;
    height: 2px !important;
    margin: 0 auto;
  }
  .status-message {
    @include center-in-parent;
    transform: translate(-50%, 0);
    width: 300px;
    padding: $space-s 0;
    text-align: center;
    font-size: $font-size-xs;
  }

  &.fixed {
    @include fill-screen;
    z-index: $z-loader;
  }
  &.unobtrusive {
    pointer-events: none;
    .v-progress-linear {
      margin: 0;
    }
  }

  &.overlay {
    background-color: transparentize($blk-black, 0.5);
  }
  &.noCover, &.unobtrusive {
    background-color: transparent;
  }
  &.inverted {
    background-color: $blk-grey-6;
  }
}
</style>
