<template>
  <button class="simple button" @click="clickHandler">
    <span class="button-label">{{label}}</span>
    <span class="button-icon-wrapper">
      <AddIcon fillColor="#101010" strokeColor="#101010" class="button-icon" />
    </span>
  </button>
</template>

<script>
export default {
  name: 'PlusButton',
  props: {
    label: {
      type: String,
      default: 'click'
    },
    clickHandler: {
      type: Function,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
.button {
  font-family: $font-body;
  font-size: 12px;
  line-height: 15.6px;
  text-transform: none;
  text-decoration: none;

  .button-label {
    text-decoration: underline;
  }

  .button-icon-wrapper {
    height: 13px;
    display: inline-block;
    margin-left: 5px;
    position: relative;
    top: 3px;
    width: 13px;
  }

  .button-icon {
    position: absolute;
    top: 0;
    left: 0;
    margin: -5px 0 0 -5px;
  }
}
</style>
