<!--
  Displays the primary and secondary types of a particular collection to be used for navigation between those types.
-->

<template>
  <div class="type-thumbnails">
    <div v-if="pTypes.length > 1" class="type-group primary-types">
      <h4 class="type-group__heading">
        Works
        <span>{{ pTypes.length }}</span>
      </h4>
      <div class="type-group__thumbs">
        <div
          v-for="(type, index) in pTypes"
          :key="`primary-thumb-${index}`"
          class="thumb"
          :class="{ active: $route.params.primary === type.id }"
          :style="`background-image: url(${tokenImagePath($route.params.collection, type.id + '.' + getSecondaryTypes(type.id)[1].id)})`"
          @click="changePrimaryType(type.id)"
        />
      </div>
    </div>

    <div v-if="sTypes.length > 2" class="type-group secondary-types">
      <h4 class="type-group__heading">
        Variants
        <span>{{ sTypes.length - 1 }}</span>
      </h4>
      <div class="type-group__thumbs">
        <div
          v-for="(type, index) in sTypes"
          :key="`secondary-thumb-${index}`"
          class="thumb"
          :class="{
            active: $route.params.secondary === type.id,
            all: type.id === 'all'
          }"
          :style="type.id !== 'all' && `background-image: url(${tokenImagePath($route.params.collection, $route.params.primary + '.' + type.id, 70)})`"
          @click="changeSecondaryType(type.id)"
        >
          <span v-if="type.id === 'all'">
            ALL
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'TypeThumbnails',

  created () {
    this.targetTypePrimary = this.$route.params.primary
    this.targetTypeSecondary = this.$route.params.secondary
  },

  props: {
    series: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      targetTypePrimary: '',
      targetTypeSecondary: ''
    }
  },

  computed: {
    ...mapGetters([
      'primaryTypes',
      'secondaryTypes'
    ]),
    pTypes () {
      return this.primaryTypes(this.$route.params.collection, this.series?.id, false)
    },
    sTypes () {
      return this.secondaryTypes(this.$route.params.collection, this.targetTypePrimary)
    }
  },

  methods: {
    changePrimaryType (id) {
      this.targetTypePrimary = id
      this.changeTargetType()
    },
    changeSecondaryType (id) {
      this.targetTypeSecondary = id
      this.changeTargetType()
    },
    changeTargetType () {
      // Before changing types, make sure that the target even exists;
      // if it doesn't, fall back to the first type in the array.
      if (!this.sTypes.some(type => type.id === this.targetTypeSecondary)) {
        this.targetTypeSecondary = this.sTypes[0].id
      }
      const newTarget = `/market/${this.$route.params.collection}/${this.targetTypePrimary}/${this.targetTypeSecondary}`
      this.$root.$emit('changeTokenView', newTarget)
    },
    getSecondaryTypes (primaryType) {
      return this.secondaryTypes(this.$route.params.collection, primaryType)
    }
  }
}
</script>

<style lang="scss" scoped>
.type-thumbnails {
  .type-group {
    &:nth-child(1) {
      margin-bottom: $space-l;
    }

    &__heading {
      margin-bottom: $space-m;

      @include media($bp-tablet) {
        text-align: center;
      }

      span {
        display: inline-block;
        margin-left: $space-xs;
        font-size: $font-size-ms;
        font-family: $font-body;
      }
    }

    &__thumbs {
      display: flex;
      flex-wrap: wrap;
      gap: $space-xs;
      max-height: 228px;
      overflow: scroll;

      @include media($bp-tablet) {
        justify-content: center;
      }

      .thumb {
        @include bg-cover-center;

        cursor: pointer;
        position: relative;
        width: 60px;
        height: 60px;
        opacity: .7;
        transition: .3s opacity;

        &:hover {
          opacity: 1;
          transition: .3s opacity;
        }

        &.all {
          background-color: $blk-grey-6;
        }

        &::before {
          @include fill-parent;

          content: '';
          border: solid 2px white;
          opacity: 0;
          transition: .3s all;
        }

        &.active {
          opacity: 1;
          transition: .3s opacity;

          &::before {
            opacity: 1;
            transition: .3s all;
          }
        }

        span {
          @include center-in-parent;

          line-height: 1em;
          font-size: $font-size-s;
        }
      }
    }
  }
}
</style>
