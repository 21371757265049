/**
 * Handles getting / setting user data
 * SECURITY: This file also manages import login / authentication functions.
 */

import CustomAuth from '@toruslabs/customauth'
import nearSeedPhrase from 'near-seed-phrase'
import { keyStores, KeyPair, connect } from 'near-api-js'
import { ethers } from 'ethers'
import moment from 'moment'

import fb from '../../../firebase_config/firebase'
import { doc, query, getDoc, onSnapshot, where, getDocs, setDoc } from 'firebase/firestore'
import { signInWithEmailLink, onAuthStateChanged } from 'firebase/auth'
import { httpsCallable } from 'firebase/functions'

// Set up Torus CustomAuth
const torus = new CustomAuth({
  baseUrl: window.location.origin + '/serviceworker',
  network: process.env.VUE_APP_TORUS_NETWORK,
  enableLogging: process.env.VUE_APP_TORUS_NETWORK === 'testnet'
})

/**
 * Used to generate Torus wallet related data for user based on Firebase login token.
 * @param {object} payload - Contains the FB user object, as well as the user token
 * @returns the result of the associateWallets function
 */
async function getTorusData (payload) {
  await torus.init()
  console.log('Torus initialized. Getting user data.')
  let pKey
  let pubAddress
  try {
    console.log(`Getting Torus data for ${payload.user.email} using verifier ${process.env.VUE_APP_TORUS_VERIFIER}.`)
    // AUTH STEP 4 - Generate Torus wallet data using CustomAuth and associated verifer.
    // Key retrieval differs slightly when using aggregate verifers.
    if (process.env.VUE_APP_TORUS_USE_AGGREGATE_VERIFIER === 'false') {
      console.log('Using standard Web3Auth verifier')
      const { privateKey, publicAddress } = await torus.getTorusKey(
        process.env.VUE_APP_TORUS_VERIFIER,
        payload.user.email,
        { verifier_id: payload.user.email },
        payload.token
      )
      pKey = privateKey
      pubAddress = publicAddress
    } else {
      console.log('Using aggregate Web3Auth verifier')
      const { privateKey, publicAddress } = await torus.getAggregateTorusKey(
        process.env.VUE_APP_TORUS_VERIFIER,
        payload.user.email,
        [
          {
            idToken: payload.token,
            verifier: process.env.VUE_APP_TORUS_SUB_VERIFIER
          }
        ]
      )
      pKey = privateKey
      pubAddress = publicAddress
    }
    console.log('Torus public address: ', pubAddress)
  } catch (err) {
    console.log('Error getting Torus Key', err)
  }
  // AUTH STEP 5 - Generate NEAR wallet data
  const nearData = generateNearData(pKey)
  const walletData = { near: nearData, torus: pubAddress }

  // Set up ethers wallet for signing
  const wallet = new ethers.Wallet(pKey)
  const signPromise = wallet.signMessage(JSON.stringify(walletData))
  const signature = await signPromise

  if (nearData && pubAddress) {
    try {
      const res = await associateWallets(walletData, signature)
      return res
    } catch (err) {
      console.log('Error associating NEAR wallet', err)
      throw (err)
    }
  } else {
    throw new Error('Error with wallet data.')
  }
}

/**
 * Generate a 12 word seed phrase from the Torus private key using XOR
 * @param {string} privateKey - The user's Torus private key
 * @returns An object which contains the user's seed phrase and public and private keys
 */
function twelveWordSeedPhrase (privateKey) {
  const buf = Buffer.from(privateKey, 'hex')
  const result = Buffer.alloc(buf.length / 2)
  for (let i = 0; i < buf.length / 2; i++) {
    result[i] = buf[i] ^ buf[buf.length - 1 - i]
  }
  const seedPhrase = nearSeedPhrase.generateSeedPhrase(result.toString('hex'))
  return seedPhrase
}

/**
 * Generate NEAR wallet data using the user's Torus private key.
 * @param {string} privateKey - The user's Torus private key, used to generate associated NEAR wallet
 * @returns An object which contains the user's NEAR wallet data.
 */
function generateNearData (privateKey) {
  // console.log(`Generate seed phrase from Torus private key ${privateKey}`)
  const seedPhrase = twelveWordSeedPhrase(privateKey)

  const kp = KeyPair.fromString(seedPhrase.secretKey)
  const publicKey = seedPhrase.publicKey
  const message = Buffer.from(publicKey, 'utf-8')
  const signature = Buffer.from(kp.sign(message).signature).toString('hex')
  return { pubKey: publicKey, sig: signature }
}

/**
 * Triggers a Firebase function which ties generated Torus and NEAR data to their Firebase user profile.
 * @param { Object } data - object containing the user's relevant Torus and NEAR wallet data.
 */
async function associateWallets (data, signature) {
  // console.log('Associating wallets with data', data)
  const associate = httpsCallable(fb.functions, 'associateWallets')
  associate({ data, signature }).then((res) => {
    console.log(res)
    return 'Done'
  }).catch((err) => {
    console.log(err)
    throw err
  })
}

const state = () => ({
  currentUser: null,
  userProfile: null,
  userRole: null,
  userProfileUnsub: null,
  userNearSignature: null,
  nearConnection: null,
  userClaims: null,
  userClaimsUnsub: null,
  claimsInitialCheck: false,
  claimsNotification: false,
  initialCheck: false,
  portfolioNeedsRefresh: false
})

const getters = {
  currentUser: state => state.currentUser,
  userProfile: state => state.userProfile,
  userRole: state => state.userRole,
  userNearSignature: state => state.userNearSignature,
  nearConnection: state => state.nearConnection,
  userClaims: state => state.userClaims,
  unclaimed: (state) => {
    if (!state.userClaims) return []
    const unclaimed = []
    state.userClaims.forEach((claim) => {
      if (!claim.claimed) {
        unclaimed.push(claim)
      }
    })
    return unclaimed
  },
  claimsNotification: state => state.claimsNotification,
  portfolioNeedsRefresh: state => state.portfolioNeedsRefresh
}

const actions = {
  /**
   * AUTH STEP 2
   * Used to trigger Firebase login via email link.
   * @param { String } email - the user's email address.
   */
  async loginWithLink ({ commit, dispatch }, email) {
    try {
      commit(
        'setGlobalState',
        { target: 'loadingStatus', val: 'Authenticating via email link...' },
        { root: true }
      )
      await signInWithEmailLink(fb.auth, email, window.location.href)
      window.localStorage.removeItem('emailForSignIn')
      dispatch('userAuthCheck')
      // Hide the login modal after successful login.
      commit(
        'setShowingLogin',
        false,
        { root: true }
      )
    } catch (err) {
      console.log(err)
      commit(
        'addNotification',
        { message: err.code, type: 'error' },
        { root: true }
      )
    }
  },

  /**
   * This function is called when a user is authenticated, and it's detected that they don't yet have a user profile.
   * It initiates the process of generated the user's Torus and NEAR wallets.
   * @param { Object } currentUser - the authenticated Firebase user object
   */
  async firstTimeLogin ({ commit }, currentUser) {
    console.log('No user profile. Setting data...')
    commit(
      'setGlobalState',
      { target: 'loadingStatus', val: 'First Login<br>Generating your user profile.<br>This may take some time.' },
      { root: true }
    )
    commit('setLoading', true, { root: true })
    const idToken = await currentUser.getIdToken(true)
    await getTorusData({ user: currentUser, token: idToken })
  },

  /**
   * AUTH STEP 3 - Check for existing user
   * Used to check for user authentication on load and between routes.
   */
  async userAuthCheck ({ commit, state, dispatch }, requiredRole) {
    function hideLoader () {
      commit('setLoading', false, { root: true })
    }
    return new Promise((resolve, reject) => {
      // Wait for Firebase auth state change.
      const unsubscribe = onAuthStateChanged(fb.auth, async (currentUser) => {
        unsubscribe()
        commit('setCurrentUser', currentUser)
        if (currentUser) {
          const idTokenResult = await currentUser.getIdTokenResult()
          if (idTokenResult?.claims?.role) {
            commit('setUserRole', idTokenResult.claims.role)
          }
          try {
            await dispatch('getUserProfile')
          } catch (err) {
            console.log(err)
          }

          // If there is no user profile, this should mean that it's their first time logging in.
          // If so, a user profile and Torus / NEAR wallets should be generated for them.
          if (!state.userProfile) {
            try {
              await dispatch('firstTimeLogin', currentUser)
            } catch (err) {
              hideLoader()
              console.log('Error with first time login:', err)
              reject(err)
            }
          } else if (requiredRole) {
            if (state.userRole !== requiredRole) {
              console.log('Role mismatch')
              reject(new Error('User does not have the required role for this route.'))
            }
          }
          if (state.userNearSignature === null) {
            try {
              await dispatch('generateNearSignature')
            } catch (err) {
              hideLoader()
              console.log('Error generating NEAR signature', err)
              reject(err)
            }
          }
          dispatch('getBalance', { root: true })
          dispatch('userProfileListener')
          // AUTH STEP 7 - Finished authentication
          resolve('A user is authenticated.')
        } else {
          hideLoader()
          reject(new Error('No Firebase user present.'))
        }
      })
    })
  },

  /**
   * Retrieves the user profile data associated with the current user from the Firestore database.
   * @returns The user data object.
   */
  async getUserProfile ({ commit, state }) {
    const user = await getDoc(doc(fb.db, 'users', state.currentUser.uid))
    if (user.data() === undefined) throw (new Error('No user present.'))
    // console.log('User profile data exists.')
    const userData = user.data()
    userData.id = user.id
    commit('setUserProfile', userData)
    return userData
  },

  /**
   * Set up a listener for the current user's profile data.
   */
  userProfileListener ({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      if (state.userProfileUnsub != null) {
        console.log('A user profile listener has already been set up.')
        resolve()
        return
      }
      console.log('Setting up user profile listener.')
      const userDoc = doc(fb.db, 'users', state.currentUser.uid)
      const unsub = onSnapshot(userDoc, async (user) => {
        const userData = user.data()
        if (userData === undefined) {
          reject(new Error('No user data present.'))
        } else {
          userData.id = user.id
          commit('setUserProfile', userData)
          commit('setLoading', false, { root: true })

          // When user profile data is loaded for the first time, do some initial checks for claims / promos
          if (!state.initialCheck) {
            commit('setInitialCheck', true)
            dispatch('userClaimsListener')
            dispatch('checkUserPromotions')
            dispatch('setupNearConnection')

            // Show the user the top up / backup modals if they haven't seen them already
            if (!state.userProfile.confirmations || !state.userProfile.confirmations.shownTopUp) {
              commit('setGlobalState', { target: 'showingTopupWallet', val: true }, { root: true })
            } else if (!state.userProfile.confirmations || !state.userProfile.confirmations.shownBackup) {
              commit('setGlobalState', { target: 'showingBackupWallet', val: true }, { root: true })
            } else {
              // If the user has seen / confirmed both, check the age of the confirmation
              // If backup confirmation is older than 30, show backup again
              const { days } = moment.duration({
                from: moment.unix(state.userProfile.confirmations.shownBackup / 1000),
                to: new Date()
              })._data
              if (days > 30) {
                commit('setGlobalState', { target: 'showingBackupWallet', val: true }, { root: true })
              }
            }
          }

          resolve(userData)
        }
      }, (err) => {
        console.log(err)
        reject(err)
      })

      commit('setUserProfileUnsub', unsub)
    })
  },

  async setupNearConnection ({ state, commit, dispatch }) {
    const keyStore = new keyStores.InMemoryKeyStore()
    const privateKey = await dispatch('getTorusPrivateKey')
    const seedPhrase = twelveWordSeedPhrase(privateKey)
    const keyPair = KeyPair.fromString(seedPhrase.secretKey)
    const nearNetwork = process.env.VUE_APP_NEAR_NETWORK
    await keyStore.setKey(nearNetwork, state.userProfile.nearId, keyPair)

    const config = {
      networkId: nearNetwork,
      keyStore,
      nodeUrl: `https://rpc.${nearNetwork}.near.org`,
      walletUrl: `https://wallet.${nearNetwork}.near.org`,
      helperUrl: `https://helper.${nearNetwork}.near.org`,
      explorerUrl: `https://explorer.${nearNetwork}.near.org`
    }

    const near = await connect(config)
    // console.log('NEAR CONNECTION', near)
    commit('setNearConnection', near)
  },

  /**
   * CLAIMING STEP 1 - Set up listener to get user's claims.
   */
  userClaimsListener ({ commit, state }) {
    console.log('Setting up user claims listener.')
    if (state.userClaimsUnsub != null) {
      console.log('A claims listener has already been set up.')
      return
    }
    // Only get unclaimed claims that have the current user's email.
    const q = query(fb.claimsCollection, where('email', '==', state.currentUser.email), where('readyToClaim', '==', true))
    const unsub = onSnapshot(q, (claims) => {
      const allClaims = []
      claims.forEach((claim) => {
        const claimData = claim.data()
        claimData.claimId = claim.id
        allClaims.push(claimData)
        // Only do this on the initial check
        if (!state.claimsInitialCheck) {
          if (!claimData.claimed) {
            commit('setClaimsNotification', true)
          }
        }
      })
      commit('setUserClaims', allClaims)
      commit('setClaimsInitialCheck', true)
    })
    commit('setUserClaimsUnsub', unsub)
  },

  stopUserProfileListener ({ state, commit }) {
    state.userProfileUnsub()
    commit('setUserProfileUnsub', null)
  },

  stopUserClaimsListener ({ state, commit }) {
    state.userClaimsUnsub()
    commit('setUserClaimsUnsub', null)
  },

  /**
   * Retrieve the target user's private Torus key from the Torus network.
   * @returns the user's private key
   */
  async getTorusPrivateKey ({ state }) {
    if (!state.currentUser) {
      console.log('No Firebase user present.')
      return null
    }
    const user = state.currentUser
    let idToken = null
    try {
      idToken = await user.getIdToken(true)
    } catch (err) {
      console.log('Error getting user ID token: ', err)
    }
    let pKey
    // Key retrieval differs slightly when using aggregate verifers.
    if (process.env.VUE_APP_TORUS_USE_AGGREGATE_VERIFIER === 'false') {
      const { privateKey } = await torus.getTorusKey(
        process.env.VUE_APP_TORUS_VERIFIER,
        user.email,
        { verifier_id: user.email },
        idToken
      )
      pKey = privateKey
    } else {
      console.log('Getting aggregate key.')
      const { privateKey } = await torus.getAggregateTorusKey(
        process.env.VUE_APP_TORUS_VERIFIER,
        user.email,
        [
          {
            idToken,
            verifier: process.env.VUE_APP_TORUS_SUB_VERIFIER
          }
        ]
      )
      pKey = privateKey
    }
    return pKey
  },

  /**
   * Generate a NEAR twelve word seed phrase given a Torus private key
   * @returns the NEAR seed phrase
   */
  async generateNearSeed ({ dispatch }) {
    const privateKey = await dispatch('getTorusPrivateKey')
    const nearData = twelveWordSeedPhrase(privateKey)
    return nearData.seedPhrase
  },

  async generateNearSignature ({ commit, dispatch }) {
    try {
      const privateKey = await dispatch('getTorusPrivateKey')
      const nearData = generateNearData(privateKey)
      commit('setUserNearSignature', nearData.sig)
    } catch (err) {
      console.log('Error getting Torus private key:', err)
    }
  },

  async updateUserProfile ({ state }, data) {
    try {
      await setDoc(doc(fb.db, 'users', state.currentUser.uid), data, { merge: true })
      return 'Finished updating.'
    } catch (err) {
      console.log(err)
      throw (err)
    }
  },

  /**
   * Gets a target Firebase user's user profile data in Firestore based on their NEAR ID.
   * @param { String } id - the target user's NEAR ID
   * @returns an object which contains the target user's profile data from Firestore.
   */
  async getUserByNearId ({ state }, id) {
    const snapshot = await getDocs(query(fb.usersCollection, where('nearId', '==', id)))
    let data = {
      name: id,
      nearId: id
    }
    snapshot.forEach((user) => {
      data = user.data()
    })
    return data
  },

  /**
   * On login, check to see if the user should receive a free claim.
   */
  async checkUserPromotions ({ commit, state, rootState }) {
    // This guard simply prevents the backend function from being called unnecessarily.
    // Back end functions also include measures to verify that a gift ID is set.
    let promoIds
    if (
      !rootState.MarketSettings.globalSettings ||
      !rootState.MarketSettings.globalSettings.activePromotionIds ||
      !rootState.MarketSettings.globalSettings.activePromotionIds.length
    ) {
      console.log('No promotion ID set for this market.')
      return
    } else {
      promoIds = rootState.MarketSettings.globalSettings.activePromotionIds
    }

    // If there are no flags set for this user, or if the flag for the current promotion hasn't been marked,
    // check to see if they should receive a promotion.
    // Again these checks are superficial and only used to prevent unnecessary calls to the back end.
    // Using for-in loop here because async aware, and want to check sequentially rather than concurrently.
    for (const i in promoIds) {
      const promoId = promoIds[i]
      console.log(`Checking promo ${promoId} for user.`)
      if (!state.userProfile.flags || !state.userProfile.flags[promoId]) {
        console.log(`It looks like this user hasn't received the promotion ${promoId}`)
        try {
          const res = await httpsCallable(fb.functions, 'promotions-checkForUser')({ targetPromo: promoId })
          console.log(res.data)
          if (res.data.claimId) {
            console.log('Generated a new claim for the user.')
            commit('setClaimsNotification', true)
          }
        } catch (err) {
          console.log('Error checking for user promotions:', err)
        }
      } else {
        console.log(`User is marked as already having received the promotion ${promoId}`)
      }
    }
  }
}

const mutations = {
  setCurrentUser (state, val) {
    state.currentUser = val
  },

  setUserProfile (state, val) {
    state.userProfile = val
  },

  setUserRole (state, val) {
    state.userRole = val
  },

  setUserProfileUnsub (state, val) {
    state.userProfileUnsub = val
  },

  setUserNearSignature (state, val) {
    state.userNearSignature = val
  },

  setNearConnection (state, val) {
    state.nearConnection = val
  },

  setUserClaims (state, val) {
    state.userClaims = val
  },

  setUserClaimsUnsub (state, val) {
    state.userClaimsUnsub = val
  },

  setClaimsInitialCheck (state, val) {
    state.claimsInitialCheck = val
  },

  setClaimsNotification (state, val) {
    state.claimsNotification = val
  },

  setInitialCheck (state, val) {
    state.initialCheck = val
  },

  setPortfolioNeedsRefresh (state, val) {
    state.portfolioNeedsRefresh = val
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
