<!--
  Root App component
  Any component that should be viewable from any route should be present here (i.e. global modals)
  Note that a number of important initializing actions also occur in created()
-->

<template>
  <v-app id="app" class="blkmnt">
    <!-- Global Loader -->
    <transition name="fade">
      <LoaderCover
        v-if="loading"
        fixed
        center
        transparent
        :unobtrusive="unobtrusiveLoader"
        :status="loadingStatus"
        :zIndex="75"
      />
    </transition>

    <!-- Global App Navigation -->
    <transition name="fade">
      <template v-if="!hideNavigation">
        <Navigation />
      </template>
    </transition>

    <transition name="fade">
      <template v-if="!hideNavigation">
        <MobileNavigation />
      </template>
    </transition>

    <!-- Router Content -->
    <div class="blk-container" ref="central-container">
      <transition name="fade-slow" mode="out-in">
        <router-view />
      </transition>
    </div>

    <!-- Global Modals -->
    <transition name="fade-slow" mode="out-in">
      <TokenDetailModal v-if="showingTokenDetails" />
    </transition>
    <transition name="fade-slow" mode="out-in">
      <PurchaseModal v-if="inPurchaseFlow" />
    </transition>
    <transition name="fade-slow" mode="out-in">
      <LoginModal v-if="showingLogin" />
    </transition>
    <transition name="fade-slow" mode="out-in">
      <HasClaimsModal v-if="showHasClaimsModal" />
    </transition>
    <transition name="fade-slow" mode="out-in">
      <TopupWalletModal
        v-if="showingTopupWallet"
        @topUpWallet="showMoonPayModal"
      />
    </transition>
    <transition name="fade-slow" mode="out-in">
      <MoonPayModal
        v-if="showingMoonPay"
      />
    </transition>
    <transition name="fade-slow" mode="out-in">
      <BackupWalletModal v-if="showingBackupWallet" />
    </transition>
    <transition name="fade-slow" mode="out-in">
      <MoonPayUnavailableModal v-if="showingMoonPayUnsupported" />
    </transition>
    <transition name="fade-slow" mode="out-in">
      <TokenProvenanceModal v-if="showingTokenProvenance" />
    </transition>
    <transition name="fade-slow" mode="out-in">
      <LandingNotificationModal v-if="showingLandingNotification" />
    </transition>

    <!-- Notifications -->
    <transition-group name="fade">
      <Notification
        v-for="(notification, index) in notifications"
        :key="`notification-${notification.id}`"
        :message="notification.message"
        :linkText="notification.linkText"
        :routerLink="notification.routerLink"
        :externalLink="notification.externalLink"
        :type="notification.type"
        :expires="notification.expires"
        :index="index"
        :bottom="getNotificationPosition(index)"
        @reportHeight="setNotificationHeight"
        @remove="deleteNotificationHeight"
      />
    </transition-group>

    <footer v-if="!hideFooter" class="footer">
      <MarketSocials />
      <FooterNavigation />
      <FooterDisclaimer />
    </footer>

    <!-- In development environments, display a note about testnet transactions -->
    <div v-if="developmentEnvironment" class="dev-banner">
      <span class="version">v{{ packageVersion }}</span>
      <span>TEST ENV:</span> All transactions are testnet only.
    </div>
  </v-app>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { isSignInWithEmailLink } from 'firebase/auth'
import Navigation from '@/components/Navigation'
import MobileNavigation from '@/components/Navigation_Mobile'

import LoginModal from '@/components/modals/LoginModal'
import TokenDetailModal from '@/components/modals/TokenDetailModal'
import PurchaseModal from '@/components/modals/PurchaseModal'
import HasClaimsModal from '@/components/modals/HasClaimsModal'
import TopupWalletModal from '@/components/modals/TopupWalletModal'
import BackupWalletModal from '@/components/modals/BackupWalletModal'
import MoonPayModal from '@/components/modals/MoonPayModal'
import MoonPayUnavailableModal from '@/components/modals/MoonPayUnavailableModal'
import TokenProvenanceModal from '@/components/modals/TokenProvenanceModal'
import LandingNotificationModal from '@/components/modals/LandingNotificationModal'

import Notification from '@/components/Notification'

const EXCHANGE_RATE_POLL_INTERVAL = 15000
const BALANCE_POLL_INTERVAL = 15000

export default {
  async created () {
    console.log('PACKAGE VERSION:', process.env.PACKAGE_VERSION || '')
    try {
      await this.getBlkDrpSettings()
    } catch (err) {
      this.addNotification('Error retrieving BLKDRP settings.', 'error')
    }
    this.pollForNearUsdExchangeRate()
    this.pollBalance()
    this.checkForSignInLink()
    try {
      await this.userAuthCheck()
    } catch (err) {
      console.log(err.message)
      // this.addNotification(err.message, 'error')
    }
    await this.marketCollectionsListeners()

    if (this.globalSettings && this.globalSettings.useCollectionGroups) {
      this.getCollectionGroups()
    }
    if (
      this.globalSettings?.landingNotification?.shown &&
      window.sessionStorage.getItem('shownLandingNotification') !== (this.globalSettings.landingNotification.id || 'default')
    ) {
      console.log('Show landing notification.')
      this.setGlobalState({ target: 'showingLandingNotification', val: true })
    }
  },
  components: {
    Navigation,
    MobileNavigation,
    LoginModal,
    TokenDetailModal,
    PurchaseModal,
    HasClaimsModal,
    TopupWalletModal,
    BackupWalletModal,
    MoonPayModal,
    MoonPayUnavailableModal,
    TokenProvenanceModal,
    LandingNotificationModal,
    Notification
  },
  data () {
    return {
      setBackRouteTimeout: 0,
      connectedWallet: null,
      scrollLock: false,
      notificationHeights: []
    }
  },
  methods: {
    ...mapMutations([
      'setLoading',
      'setReenterEmail',
      'setConnectedWallet',
      'setGlobalState',
      'setShowingMoonPay',
      'removeNotification'
    ]),
    ...mapActions([
      'isAndroidOrIos',
      'userAuthCheck',
      'getEthUsdExchange',
      'getNearUsdExchange',
      'getBalance',
      'getGlobalSettings',
      'getBlkDrpSettings',
      'loginWithLink',
      'connectWallet',
      'marketCollectionsListeners',
      'getCollectionGroups'
    ]),
    pollForNearUsdExchangeRate () {
      this.getNearUsdExchange()
      setInterval(this.getNearUsdExchange, EXCHANGE_RATE_POLL_INTERVAL)
    },

    /**
     * Get the user's NEAR balance at regular intervals.
     * TODO: This may be too frequent in the event that there are a large number of users.
     */
    pollBalance () {
      this.getBalance()
      setInterval(this.getBalance, BALANCE_POLL_INTERVAL)
    },

    /**
     * On load, the app checks the URL to see if the user is using a login link.
     * If so, initiate login flow.
     */
    async checkForSignInLink () {
      if (isSignInWithEmailLink(this.$fb.auth, window.location.href)) {
        const email = window.localStorage.getItem('emailForSignIn')
        if (email) {
          this.setLoading(true)
          this.loginWithLink(email)
        } else {
          this.setReenterEmail(true)
        }
      }
    },

    // Record the height of the notification so following ones will be positioned correctly
    setNotificationHeight (index, value) {
      this.notificationHeights[index] = value + 12 // margin * 2
    },
    deleteNotificationHeight (index) {
      this.notificationHeights.splice(index, 1)
    },

    // Add heights of all previous notifications
    getNotificationPosition (index) {
      let total = 0
      if (index > 0) {
        for (let i = 0; i < index; i++) {
          total += this.notificationHeights[i]
        }
      }
      return total
    },

    // Disable / enable body scrolling when modals are shown / hidden
    setScrollLock (val) {
      this.scrollLock = val
      if (val) {
        document.body.style.top = `-${window.scrollY}px`
        document.body.classList.add('locked')
      } else {
        document.body.classList.remove('locked')
        const scrollY = document.body.style.top
        document.body.style.position = ''
        document.body.style.top = ''
        window.scrollTo(0, parseInt(scrollY || '0') * -1)
      }
    },

    async showMoonPayModal () {
      this.setShowingMoonPay('buy')
    }
  },
  computed: {
    ...mapGetters([
      'globalSettings',
      'currentUser',
      'claimsNotification',
      'notifications',
      'loading',
      'loadingStatus',
      'unobtrusiveLoader',
      'showingLogin',
      'inPurchaseFlow',
      'showingTokenDetails',
      'showingTopupWallet',
      'showingBackupWallet',
      'showingMoonPay',
      'showingMoonPayUnsupported',
      'showingTokenProvenance',
      'hideNavigation',
      'hideFooter',
      'showingLandingNotification'
    ]),

    developmentEnvironment () {
      return process.env.NODE_ENV === 'development'
    },
    packageVersion () {
      const version = process.env.PACKAGE_VERSION
      return version
    },

    // TODO: Not really currently in use, but other modals would need to be added if used
    modalOpen () {
      if (
        this.showingLogin ||
        this.showingTokenDetails ||
        this.inPurchaseFlow
      ) {
        return true
      } else {
        return false
      }
    },

    showHasClaimsModal () {
      if (
        this.claimsNotification &&
        this.$route.path !== '/portfolio' &&
        !window.sessionStorage.getItem('shownHasClaims')
      ) {
        return true
      } else {
        return false
      }
    },

    emailFormatted () {
      return this.currentUser.email.replace('@', '%40')
    }
  },
  watch: {
    value: function (val) {
      this.text = val
    },
    // When a modal is opened or closed, set scrolling behavior
    modalOpen: function (val) {
      // this.setScrollLock(val)
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/rendered.scss";

.v-application {
  display: block !important;
  .v-application--wrap {
    flex: unset;
    display: block;
    position: relative;
    padding-bottom: 200px;

    p {
      margin-bottom: 0;
    }
  }
}

.theme--dark {
  &.v-application {
    background-color: $bg-black !important;
  }
}
.theme--light {
  &.v-application {
    background-color: $blk-white !important;
  }
  .theme--light.v-icon {
    color: $blk-black;
  }
}

body {
  &.locked {
    position: fixed;
    width: 100%;
  }
}

#app {
  font-family: $font-market;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#nav,
.mobile-navigation {
  .sign-up {
    font-size: $font-size-xs;
    line-height: 1.6em;
  }
  .router-link-active {
    text-decoration: underline;
  }
}
span.symbol {
  font-family: $font-body;
}

.title-link {
  text-decoration: none;
  color: inherit;
  &.ignore {
    cursor: auto;
    pointer-events: none;
  }
}

.table-title {
  margin-bottom: $space-l;
}

.data-table {
  font-size: $font-size-ms;
  width: 100%;
  margin-bottom: $space-ll;
  border-collapse: collapse;
  table-layout: fixed;

  @include media($bp-phone-l) {
    font-size: $font-size-s;
  }
  tr {
    td {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding: $space-xs * 2 0 $space-xs;
      border-bottom: solid 1px $blk-grey-5;

      &:nth-child(1) {
        text-align: left;
      }
      &:nth-child(2) {
        text-align: right;
      }
    }
  }
}

.blk-caption {
  @include caption;
}

header {
  margin-top: $space-xxl;
  margin-bottom: $space-xl;
  transition: 0.6s all;
  max-width: $container-max;
  .avatar-container {
    display: inline-block;
    width: 0;
    transition: 0.3s width;

    &.shown {
      width: 99px;
      transition: 0.3s width;

      .header-image {
        transform: scale(1);
        transition: 0.3s transform;
      }
    }
  }
  .header-image {
    @include bg-cover-center;
    vertical-align: middle;
    display: inline-block;
    width: 64px;
    height: 64px;
    background-color: $blk-grey-1;
    border-radius: 50%;
    margin-right: $space-ml;
    transform: scale(0);
    transition: 0.3s transform;
  }
  .wallet-address {
    @include normal-text;
    display: inline-block;
    vertical-align: bottom;
    transform: translateY(-9px);
    margin: 0 $space-m;
  }
  h1 {
    vertical-align: middle;
    display: inline-block;
  }

  &.narrow {
    max-width: $form-max;
    margin: $space-xxl calc(#{$container-max - $form-max} * 0.5) $space-xl
      calc(#{$container-max - $form-max} * 0.5);
    transition: 0.6s all;
  }
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  padding-bottom: 44px;
}

.dev-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: red;
  color: black;
  font-size: 12px;
  padding: $space-s;

  span {
    display: inline-block;
    font-weight: bold;
    &.version {
      margin-right: $space-s;
    }
  }
}

.market-container {
  position: relative;
}

.form-container {
  max-width: $form-max;
  margin: 0 auto $space-xxxl;
}

.collection-icon {
  @include bg-cover-center;
  border-radius: 22%;
}

.v-progress-circular {
  @include center-in-parent;

  .v-progress-circular__overlay {
    stroke: $blk-white;
  }
}

.v-select {
  .v-input__slot {
    &::before,
    &::after {
      border-color: $blk-grey-5 !important;
    }
  }
  .v-select__slot {
    .v-label {
      color: $blk-green;
    }
    input {
      border-bottom: none;

      &::placeholder {
        font-size: 18px;
        text-transform: uppercase;
      }
    }
  }
}

.v-input--checkbox {
  .v-icon.v-icon {
    font-size: 20px;
    &::after {
      display: none;
    }
  }
  .v-label {
    font-size: 12px;
  }
  .theme--dark.v-label {
    color: $blk-white;
  }
}

.theme--dark,
.theme--light {
  .v-data-table.blkmnt {
    background-color: transparent;
    .v-data-table-header {
      background-color: transparent;
      th {
        text-align: left;
        border: none !important;

        @include media($bp-phone-l) {
          // padding: 0;
          margin: 0;
        }
      }
    }
    tr {
      cursor: pointer;

      &:hover {
        border-radius: 0 !important;
      }

      td {
        border-radius: 0 !important;

        @include media($bp-phone-l) {
          // margin: 0;

          .owner-name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 120px;
          }
        }
      }
    }

    .v-data-footer {
      border: none;
    }
  }
}

.theme--dark.v-data-table.blkmnt {
  tbody tr {
    &:nth-of-type(even) {
      background-color: $blk-grey-6;

      &.expanded-row:hover {
        background-color: $blk-grey-6 !important; // no hover effect when expanded
      }
    }
    &:nth-of-type(odd) {
      background-color: $blk-black;

      &.expanded-row:hover {
        background-color: $blk-black !important; // no hover effect when expanded
      }
    }
  }
  .v-data-table-header {
    th {
      color: white;
      font-weight: normal;
      font-size: $font-size-ms;

      @include media($bp-phone-l) {
        padding: 0;
        margin: 0;
      }
    }
  }
  tr {
    td {
      color: $blk-white;
      border-bottom: solid 1px black !important;

      @include media($bp-phone-l) {
        &:not(:first-child) {
          padding: 0;
        }

        &.image-column {
          padding-right: 0;
        }

        &.details-column {
          display: none;
        }
      }
    }
  }
  .v-data-footer {
    color: $blk-white;
  }
  .v-data-footer__pagination {
    color: white;
    font-family: Drive Mono;
    font-size: 16px;
    line-height: 120%;
  }
}

.theme--light {
  .v-data-table.blkmnt {
    color: $blk-black;
    tbody tr:nth-of-type(odd) {
      background-color: transparentize($blk-grey-1, 0.75);
    }
  }
}

.owner-wrapper {
  align-items: center;
  display: flex;

  .avatar {
    position: relative;
    flex-shrink: 0;
  }
  .owner-name {
    line-height: 20px;
    overflow: hidden;
    margin-left: 15px;
    position: relative;
    text-align: left;
    text-overflow: ellipsis;
    max-width: 220px;
    white-space: nowrap;
  }
}

.blkmnt {
  .info-icon {
    margin-left: -$space-ml;

    @include media($bp-phone-l) {
      margin-left: -$space-s;
    }
  }
  .v-tooltip__content {
    background-color: $blk-grey-6;
    border: solid 1px $white-transp;
    border-radius: $radius-ml;
    opacity: 1 !important;
    margin-top: 5px;
    padding: $space-l;
    color: $blk-grey-2;

    &::before {
      background-color: $blk-grey-6;
      border: solid 1px $white-transp;
      border-radius: 8px;
      position: absolute;
      margin-left: -12px;
      left: 50%;
      width: 24px;
      height: 24px;
      top: -9px;
      content: "";
      transform: rotate(45deg);
    }

    &::after {
      background-color: $blk-grey-6;
      position: absolute;
      margin-left: -15px;
      left: 50%;
      width: 30px;
      height: 30px;
      top: 0px;
      content: "";
    }
  }
}
</style>
