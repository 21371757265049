/**
 * Handles setting up API calls for cryptocurrency exchange rates.
 */
const axios = require('axios')

const state = () => ({
  ethUsdExchangeRate: 1,
  nearUsdExchangeRate: 1
})

const getters = {
  ethUsdExchangeRate: state => state.ethUsdExchangeRate,
  nearUsdExchangeRate: state => state.nearUsdExchangeRate
}

const actions = {
  async getEthUsdExchange ({ commit }) {
    const getEthUsdExchangeRate = async function (currencyPair = 'ETH-USD') {
      const url = `https://api.coinbase.com/v2/prices/${encodeURIComponent(
        currencyPair
      )}/spot`
      console.log('URL:', url)

      return await axios.get(url)
    }

    const price = await getEthUsdExchangeRate()
    commit('setEthUsdExchangeRate', price.data)
  },
  async getNearUsdExchange ({ commit }) {
    const res = await axios.get('https://helper.mainnet.near.org/fiat')
    commit('setNearUsdExchangeRate', res.data.near.usd)
  }
}

const mutations = {
  setEthUsdExchangeRate (state, val) {
    state.ethUsdExchangeRate = val
  },
  setNearUsdExchangeRate (state, val) {
    state.nearUsdExchangeRate = val
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
