<!--
Set extending attributes for Tokens during the minting process.
Attributes are essentially off-chain metadata.
-->

<template>
  <div class="attributes-container">
    <h4>Attributes</h4>
    <div v-for="(attribute, index) in attributes" :key="`attribute-${index}`" class="attribute-single input-row">
      <input
        v-model="attribute.trait_type"
        class="small key"
        type="text"
        placeholder="Eg. Message from the Artist"
        @input="update"
        :disabled="disabled"
      >
      <input
        v-model="attribute.value"
        class="small value"
        type="text"
        placeholder="Eg. (link to video on ipfs)"
        @input="update"
        :disabled="disabled"
      >
      <button v-if="!disabled" type="button" class="del" @click="removeAttribute(index)">
        <CloseIcon color="#f4f4f4" />
      </button>
    </div>
    <div v-if="!disabled" class="btn-container">
      <button type="button" class="text small" @click="addAttribute">
        Add Attribute<AddIcon />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExtendingAttributes',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      attributes: this.value || []
    }
  },
  methods: {
    addAttribute () {
      this.attributes.push({})
    },
    removeAttribute (index) {
      this.attributes.splice(index, 1)
    },
    clearAttributes () {
      this.attribute = []
    },
    update () {
      this.$emit('input', this.attributes)
    }
  },
  watch: {
    value: function (val) {
      this.attributes = val
    }
  }
}
</script>

<style lang="scss" scoped>
.attributes-container {
  h4 {
    margin: $space-xl 0 $space-ml 0;
  }
}
.attribute-single {
  position: relative;
  input {
    display: inline-block;

    &.key {
      width: calc(33.3% - #{$space-m});
      margin-right: $space-m;
      &::placeholder {
        @include small-text-demi;
        color: $blk-green;
      }
    }
    &.value {
      width: 66.6%;
    }
  }
}
</style>
