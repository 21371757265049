/**
 * Handles getting aggregated claims data.
 * These collections maintain information about the total number of claims in the system, as well as which claims have been processed.
 */
import fb from '../../../firebase_config/firebase'
import { doc, collection, onSnapshot } from 'firebase/firestore'

const state = () => ({
  claimDataCollections: {},
  claimDataCollectionsUnsub: null,

  claimsByCollection: {},
  claimsCollectionTarget: null,
  claimsByCollectionUnsub: null,

  claimDataSales: {},
  claimDataSalesUnsub: null,

  claimsBySale: {},
  claimsSaleTarget: null,
  claimsBySaleUnsub: null,

  claimsMetadata: null,
  claimsMetadataUnsub: null
})

const getters = {
  claimDataCollections: state => state.claimDataCollections,
  claimDataCollectionsUnsub: state => state.claimDataCollectionsUnsub,

  claimsByCollection: state => state.claimsByCollection,
  claimsByCollectionPrimary: (state) => (typePrimary, sum) => {
    const types = {}
    Object.keys(state.claimsByCollection).forEach((type) => {
      if (typePrimary === type.split('.')[0]) {
        types[type] = state.claimsByCollection[type]
      }
    })
    if (sum) {
      let claimed = 0
      Object.keys(types).forEach((type) => {
        claimed += types[type].claimed
      })
      return { claimed }
    }
    return types
  },
  claimsByCollectionUnsub: state => state.claimsByCollectionUnsub,

  claimDataSales: state => state.claimDataSales,
  claimDataSalesUnsub: state => state.claimDataSalesUnsub,

  claimsBySale: state => state.claimsBySale,
  claimsBySaleUnsub: state => state.claimsBySaleUnsub,

  claimsMetadata: state => state.claimsMetadata
}

const mutations = {
  setClaimDataCollections (state, val) {
    state.claimDataCollections = val
  },
  setClaimDataCollectionsUnsub (state, val) {
    state.claimDataCollectionsUnsub = val
  },

  setClaimsByCollection (state, val) {
    state.claimsByCollection = val
  },
  setClaimsCollectionTarget (state, val) {
    state.claimsCollectionTarget = val
  },
  setClaimsByCollectionUnsub (state, val) {
    state.claimsByCollectionUnsub = val
  },

  setClaimDataSales (state, val) {
    state.claimDataSales = val
  },
  setClaimDataSalesUnsub (state, val) {
    state.claimDataSalesUnsub = val
  },

  setClaimsMetadata (state, val) {
    state.claimsMetadata = val
  },
  setClaimsMetadataUnsub (state, val) {
    state.claimsMetadataUnsub = val
  }
}

const actions = {
  /**
   * Gets data about claims by grouped by collection.
   */
  async claimDataCollectionsListener ({ commit, state }) {
    if (state.claimDataCollectionsUnsub != null) {
      console.log('Listener for claims data by collection set up. Returning.')
      return
    }
    console.log('Setting up listener for claims data by collection.')
    const unsub = onSnapshot(fb.claimsDataCollections, (collectionsDocs) => {
      const collections = {}
      collectionsDocs.forEach((collection) => {
        collections[collection.id] = collection.data()
      })
      commit('setClaimDataCollections', collections)
      return 'Got claims data by collection.'
    })
    commit('setClaimDataCollectionsUnsub', unsub)
  },
  /**
   * Gets data about claims group by sale.
   */
  async claimDataSalesListener ({ commit, state }) {
    if (state.claimDataSalesUnsub != null) {
      console.log('Listener for claims data by sale set up. Returning.')
      return
    }
    console.log('Setting up listener for claims data by sale.')
    const unsub = this._vm.$fb.claimsDataSales.onSnapshot((salesDocs) => {
      const sales = {}
      salesDocs.forEach((sale) => {
        sales[sale.id] = sale.data()
      })
      commit('setClaimDataSales', sales)
      return 'Got claims data by sales.'
    })
    commit('setClaimDataSalesUnsub', unsub)
  },

  claimsByCollectionListener ({ commit, state }, targetCollection) {
    if (state.claimsCollectionTarget !== targetCollection) {
      console.log(`Setting up a new claims listener for collection ${targetCollection}.`)
      if (state.claimsByCollectionUnsub) state.claimsByCollectionUnsub()
      commit('setClaimsCollectionTarget', targetCollection)
    } else {
      console.log(`Listener for collection ${targetCollection} already set up. Returning.`)
      return
    }
    const unsub = onSnapshot(collection(fb.db, 'claim-data-collections', targetCollection, 'types'), (typeDocs) => {
      const types = {}
      typeDocs.forEach((type) => {
        types[type.id] = type.data()
      })
      commit('setClaimsByCollection', types)
      return 'Got claims data by target collection.'
    })
    commit('setClaimsByCollectionUnsub', unsub)
  },

  claimsMetadataListener ({ commit, state }) {
    if (state.claimsMetadataUnsub != null) {
      console.log('A claims metadata listener has already been set up.')
      return
    }
    console.log('Setting up claims metadata listener.')
    const unsub = onSnapshot(doc(fb.db, 'claims', 'metadata'), (metadata) => {
      commit('setClaimsMetadata', metadata.data())
      return 'Got claims metadata.'
    })
    commit('setClaimsMetadataUnsub', unsub)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
