<!--
  Used to display the value of a cryptocurrency and it's associated value in USD.
-->
<template>
  <span>
    {{cryptoDisplayed}}
    <NearIcon
      v-if="type == 'near'"
      :color="iconColor"
      :size="iconSize"
    />
    <span v-else class="symbol">
      {{ symbols[type] }}
    </span>
    <span class="usd" v-if="!hideUsd">
      (${{formattedUsdPrice}})
    </span>
  </span>
</template>

<script>
import { mapGetters } from 'vuex'
import { utils } from 'near-api-js'

export default {
  name: 'CryptoFiat',
  props: {
    /**
     * The amount of cryptocurrency to display in lowest divisble units (i.e. Wei)
     */
    crypto: {
      type: String,
      default: '0'
    },
    type: {
      type: String,
      default: 'near'
    },
    /**
     * Should the equivalent value in USD be displayed next to the cryptocurrency?
     */
    hideUsd: {
      type: Boolean,
      default: false
    },
    /**
     * The fixed number of digits to appear after the decimal point in Eth value.
     */
    digitsAfterDecimal: {
      type: String,
      default: null
    },

    iconColor: {
      type: String,
      default: 'white'
    },
    iconSize: {
      type: Number,
      default: 11
    }
  },
  data () {
    return {
      symbols: {
        near: 'N',
        eth: 'Ξ'
      }
    }
  },
  computed: {
    ...mapGetters([
      'nearUsdExchangeRate'
    ]),
    /**
     * The amount of cryptocurrency that is actually displayed to the user (i.e. Wei value in ETH)
     * Decimals are also truncated using a helper function.
     */
    cryptoDisplayed () {
      if (Number.isNaN(this.crypto) || this.crypto == null) return '-'
      // console.log('Raw:', this.crypto)
      // console.log('Formatted:', utils.format.formatNearAmount(this.crypto))
      if (this.digitsAfterDecimal) {
        return this.toFixed(utils.format.formatNearAmount(this.crypto), +this.digitsAfterDecimal)
      }

      return this.truncate(utils.format.formatNearAmount(this.crypto))
    },
    /**
     * The equivalent price in USD to display, based on exchange rates retrieved from an an external API.
     */
    formattedUsdPrice () {
      if (!this.nearUsdExchangeRate) return '0'
      const exchangeRate = this.nearUsdExchangeRate || 0
      return (this.cryptoDisplayed * exchangeRate).toLocaleString('en-US',
        { style: 'decimal', minimumFractionDigits: 2 })
    }
  }
}
</script>

<style>
</style>
