<template>
  <Modal
    class="topup-wallet-modal"
    title="Welcome"
    subTitle="Top Up To Transact Faster!"
    hideActions
    @close="doLater"
  >
    <div class="message">
      <p>
        Top Up your profile wallet with NEAR tokens to ensure you have funds to
        make purchases on the market.<br /><br />Our MoonPay integration makes
        it fast, easy and secure to convert your USD to NEAR.
        <a href="" target="_blank">Learn More</a>
      </p>
    </div>
    <button class="standard option" @click="topUpWallet">
      Top Up My Wallet
    </button>
    <p class="action-link" @click="doLater">
      No thanks, I'll do it later
    </p>
  </Modal>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'

export default {
  methods: {
    ...mapMutations(['setGlobalState']),
    ...mapActions(['updateUserProfile']),
    async topUpWallet () {
      this.$router.push('/profile')
      await this.updateUser()
    },
    doLater () {
      this.setGlobalState({ target: 'showingBackupWallet', val: true })
      this.updateUser()
    },
    async updateUser () {
      this.setGlobalState({ target: 'showingTopupWallet', val: false })
      await this.updateUserProfile({
        confirmations: {
          shownTopUp: Date.now()
        }
      })
    }
  }
}
</script>
