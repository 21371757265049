<template>
  <BlkIcon v-bind="$attrs" viewBox="0 0 19 20">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.00004 17.2206C2.22036 17.2206 2.40004 17.0409 2.40004 16.8206V14.6402C2.40004 14.4402 2.50004 14.2605 2.6602 14.1605C4.8398 12.7808 7.3602 12.0401 9.9398 12.0401H10.0398C12.6194 12.0401 15.1398 12.78 17.3194 14.1605C17.4796 14.2605 17.5796 14.4605 17.5796 14.6402V16.8198C17.5796 17.0401 17.7593 17.2198 17.9796 17.2198C18.1999 17.2198 18.3796 17.0401 18.3796 16.8198V14.6402C18.3796 14.1605 18.1397 13.7199 17.7397 13.48C15.4601 12.0207 12.7999 11.2402 10.0601 11.2402H9.96012C7.22032 11.2402 4.56012 12.0199 2.23972 13.48C1.83972 13.7402 1.59988 14.1597 1.59988 14.6402V16.8198C1.59988 17.0401 1.77957 17.2206 1.99988 17.2206L2.00004 17.2206Z" fill="white"/>
    <path d="M9.6398 1.59981C8.06012 1.59981 6.8 2.85997 6.8 4.42021V7.16001C6.8 8.73969 8.06016 9.99981 9.6398 9.99981H10.3796C11.9398 9.99981 13.2 8.73965 13.2 7.17941V4.42001C13.2 2.85985 11.9399 1.59961 10.3602 1.59961L9.6398 1.59981ZM12.4 4.42021V7.16001C12.4 8.27955 11.4797 9.19981 10.3602 9.19981H9.62036C8.52036 9.19981 7.59996 8.27949 7.59996 7.17941V4.42001C7.59996 3.32001 8.52028 2.39961 9.63976 2.39961H10.3796C11.4796 2.39961 12.4 3.31993 12.4 4.42001V4.42021Z" fill="white"/>
    </svg>
  </BlkIcon>
</template>

<script>
export default { inheritAttrs: false }
</script>
