<template>
  <Modal
    title="Remove from Market"
    subTitle="Are you sure?"
    confirmLabel="Remove"
    :onConfirm="confirmDelist"
    compact
    @close="$emit('close')"
  >
    <p>Please confirm that you want to remove this token from the market.</p>
  </Modal>
</template>

<script>
export default {
  methods: {
    confirmDelist () {
      this.$emit('confirm')
    }
  }
}
</script>

<style>

</style>
