<template>
  <BlkIcon v-bind="$attrs" viewBox="0 0 19 20">
    <path d="M17.9965 4.4085C18.2575 4.23377 18.3449 3.88544 18.1713 3.59497L17.5325 2.54885C17.1546 1.91007 16.6032 1.35749 15.9645 0.980824C15.3257 0.602993 14.57 0.399902 13.8155 0.399902H7.71466C6.17499 0.399902 4.78043 1.21343 3.99647 2.54885L0.94663 7.86574C0.190967 9.17281 0.190967 10.8282 0.94663 12.1352L3.99647 17.451C4.75213 18.758 6.17493 19.5999 7.71466 19.5999H13.844C14.6858 19.5999 15.471 19.3673 16.1677 18.9033C16.7191 18.5254 17.2138 18.0319 17.5326 17.4805L18.1714 16.4343C18.3461 16.1734 18.2587 15.7956 17.9966 15.6208C17.7357 15.4461 17.3579 15.5335 17.1831 15.7955L16.5148 16.87C16.2822 17.3057 15.9339 17.654 15.4982 17.9161C15.0047 18.2361 14.3942 18.4097 13.8133 18.4097L7.71479 18.4086C6.61081 18.4086 5.56584 17.7981 5.01327 16.8405L1.93496 11.5826C1.38355 10.6239 1.38355 9.43366 1.93496 8.47497L4.98481 3.15924C5.53622 2.20049 6.58234 1.59122 7.68633 1.59122H13.8156C14.367 1.59122 14.8901 1.73645 15.3837 1.9974C15.8489 2.25836 16.255 2.66569 16.5455 3.15924L17.1843 4.20537C17.3579 4.49583 17.7062 4.5832 17.9967 4.40846L17.9965 4.4085Z" />
    <path d="M12.6825 14.111C12.4499 14.3436 12.4499 14.6919 12.6825 14.9245C12.7983 15.0402 12.9435 15.0992 13.0887 15.0992C13.2339 15.0992 13.3792 15.0414 13.4949 14.9245L16.893 11.5264C17.7349 10.6845 17.7349 9.31842 16.893 8.47654L13.4949 5.07728C13.2623 4.84468 12.914 4.84468 12.6814 5.07728C12.4488 5.30987 12.4488 5.6582 12.6814 5.8908L16.0795 9.28891C16.109 9.31841 16.1373 9.37627 16.1952 9.40464H8.7318C8.41183 9.40464 8.15088 9.6656 8.15088 9.98556C8.15088 10.3055 8.41184 10.5665 8.7318 10.5665H16.1682C16.1387 10.596 16.1103 10.6538 16.0525 10.6822L12.6825 14.111Z" />
  </BlkIcon>
</template>

<script>
export default { inheritAttrs: false }
</script>
