<!--
  Displays all social links set in Firestore marketSettings.
-->

<template>
  <div class="socials-container">
    <div class="icons">
      <template v-if="globalSettings && globalSettings.newsletter">
        <router-link to="/newsletter">
          <img src="@/assets/icons/email.svg" alt="Newsletter">
        </router-link>
      </template>
      <template v-if="globalSettings && globalSettings.marketSocials && globalSettings.marketSocials.length > 0">
        <a
          v-for="(social, index) in globalSettings.marketSocials"
          :key="`social-${index}`"
          :href="social.url"
          target="_blank"
        >
          <template v-if="['medium', 'discord'].includes(social.icon)">
            <img :src="getIconUrl(`${social.icon}.svg`)">
          </template>
          <v-icon v-else size="20">mdi-{{ social.icon }}</v-icon>
        </a>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'MarketSocials',
  computed: {
    ...mapGetters([
      'globalSettings'
    ])
  }
}
</script>

<style lang="scss" scoped>
.icons {
  display: block;
  padding: $space-m 0;

  a {
    vertical-align: middle;
    padding: 0 10px;

    i {
      height: 20px;
    }

    &:first-child {
      padding-left: 0
    }

    &:last-child {
      padding-right: 0;
    }

    img {
      vertical-align: middle;
      height: 20px;
    }
  }
}
a {
  text-decoration: none;
}
</style>
