<template>
  <div class="media-picker">
    <label>{{ label + (required ? '*' : '') }}</label>
    <v-checkbox
      v-if="!required"
      v-model="activated"
      hide-details
      dense
      color="#23F6A4"
    />
    <template v-if="activated || required">
      <button
        v-if="!disabled"
        class="text small browse-button"
        type="button"
        @click="browse"
      >
        Browse Media
      </button>
      <label
        class="hitbox"
        :for="`file-selection-${uid}`"
        :class="{
          'disabled': disabled,
          'hidden': hitboxDisabled,
          'unfilled': !file.rawData & !src
        }"
      >
        <input
          type="file"
          :id="`file-selection-${uid}`"
          ref="file-selection"
          @change="onFileChange"
          :disabled="disabled"
        >
        <img
          v-if="mode =='image' && (file.rawData || src)"
          class="preview"
          :class="{ invalid: !validImage }"
          :src="displayedSrc"
        />
        <video
          v-if="mode == 'video' && (file.rawData || src)"
          class="preview"
          :src="displayedSrc"
          controls
        />
        <h4 v-if="mode == 'unknown'">Unknown file type</h4>
      </label>
      <SpatialViewer
        v-if="mode == 'model' && file.rawData || src"
        ref="spatialViewer"
        :src="displayedSrc"
        :settings="viewerSettings"
      />
      <p class="filename" v-if="file && file.fileData">
        {{ file.fileData.name }}
      </p>
      <p v-if="error" class="error">
        {{ this.error }}
      </p>
    </template>
  </div>
</template>

<script>
export default {
  name: 'MediaPicker',
  props: {
    src: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    requiredWidth: {
      type: Number,
      default: null
    },
    requiredHeight: {
      type: Number,
      default: null
    },
    maxWidth: {
      type: Number,
      default: null
    },
    maxHeight: {
      type: Number,
      default: null
    },
    label: {
      type: String,
      default: 'Media Picker'
    },
    uid: {
      type: String,
      default: 'uid'
    },
    viewerSettings: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      file: {
        rawData: null,
        fileData: null
      },
      mode: 'image',
      activated: false,
      validImage: true,
      error: ''
    }
  },
  computed: {
    displayedSrc () {
      if (this.file.rawData) {
        return this.file.rawData
      } else if (this.src) {
        return this.src
      } else {
        return null
      }
    },
    hitboxDisabled () {
      if (this.mode === 'model' && (this.file.rawData || this.src)) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    validateImage (readerResult) {
      console.log('Validating image...')
      return new Promise((resolve, reject) => {
        if (this.requiredWidth || this.requiredHeight || this.maxWidth || this.maxHeight) {
          const image = new Image()
          image.src = readerResult
          image.onload = () => {
            if (this.requiredWidth && image.width !== this.requiredWidth) {
              resolve(`Image should be ${this.requiredWidth}px wide.`)
            }
            if (this.requiredHeight && image.height !== this.requiredHeight) {
              resolve(`Image should be ${this.requiredHeight}px tall.`)
            }
            if (this.maxWidth && image.width > this.maxWidth) {
              resolve(`Image should be no wider than ${this.maxWidth}px.`)
            }
            if (this.maxHeight && image.height > this.maxHeight) {
              resolve(`Image should be no taller than ${this.maxHeight}px.`)
            }
            resolve(true)
          }
        } else {
          resolve(true)
        }
      })
    },
    onFileChange (e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) return

      const reader = new FileReader()
      reader.onload = async () => {
        this.validImage = true
        this.error = ''
        // If set, validate the image's dimensions
        const validationCheck = await this.validateImage(reader.result)
        if (validationCheck !== true) {
          this.validImage = false
          this.error = validationCheck
        }
        this.file.rawData = reader.result // Raw file data
      }
      reader.readAsDataURL(files[0])
      this.file.fileData = files[0]
      const fileExtension = this.file.fileData.name.split('.').pop()
      const fileType = this.file.fileData.type
      console.log(fileType)
      if (fileType === 'video/mp4') {
        this.mode = 'video'
      } else if (fileType.match('image.*')) {
        this.mode = 'image'
      } else if (fileExtension === 'glb') {
        this.mode = 'model'
      } else {
        this.mode = 'unknown'
      }

      this.$emit('input', this.file)
    },
    browse () {
      this.$refs['file-selection'].click()
    }
  },
  watch: {
    viewerSettings: {
      handler () {
        // When viewer settings are updated, apply them to the viewer element
        this.$refs.spatialViewer.applySettings()
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.media-picker {
  position: relative;
  margin-top: $space-l;
  label {
    display: inline-block;
  }
  .v-input--checkbox {
    display: inline-block;
    margin: 0 0 0 $space-s;
    padding: 0;
    vertical-align: middle;
  }
}
.browse-button {
  position: absolute;
  top: 0;
  right: 0;
}
.preview {
  position: absolute;
  right: 0;
  max-width: 100%;
  max-height: 420px;

  &.invalid {
    border: solid 2px red;
  }
}
.error {
  color: red;
}
.filename {
  position: absolute;
  bottom: 0;
  right: 0;
  border: solid 1px $blk-green;
  padding: $space-xs;
  background-color: transparentize($color: $blk-grey-5, $amount: 0.5);
  color: $blk-green;
  font-size: $font-size-xs;
}
.hitbox {
  cursor: pointer;
  position: relative;
  margin: $space-m 0;
  width: 100%;
  height: 420px;
  border-radius: 10px;
  &.unfilled {
    background-color: $blk-grey-6;
  }

  &.disabled {
    cursor: auto;
  }

  &.hidden {
    display: none;
  }

  input {
    display: none;
  }
}
</style>
