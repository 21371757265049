<!--
  Component used to display a collection of tokens (or similar data type)
-->
<template>
  <div class="table-container">
    <TextInput v-model="search" icon="magnify" class="small" />
    <v-data-table
      v-model="selected"
      class="blkmnt token-table"
      :headers="computedHeaders"
      :items="data && data.length > 0 ? data : []"
      :search="search"
      :show-select="selectable"
      :light="light"
      @update:page="updatePage"
      @update:items-per-page="updateItemsPerPage"
      :footer-props="{
        itemsPerPageOptions: itemsPerPageOptions
      }"
    >
      <template v-slot:[`item.active`]="{ item }">
        <v-checkbox v-model="item.active" @click="updateActive(item)"></v-checkbox>
      </template>
      <template v-slot:[`item.featured`]="{ item }">
        <v-checkbox v-model="item.featured" @click="updateFeatured(item)"></v-checkbox>
      </template>
      <template v-slot:[`item.image`]="{ item }">
        <div
          class="thumbnail"
          v-lazy:background-image="item.image"
        />
      </template>
      <template v-slot:[`item.created`]="{ item }">
        {{ item.created ? $moment(item.created.toDate()).format('MM.DD.yyyy') : 'Unavailable' }}
      </template>
      <template v-slot:[`item.claimedTimestamp`]="{ item }">
        {{ item.claimedTimestamp ? $moment(item.claimedTimestamp.toDate()).format('MM.DD.yyyy, hh:mm') : '-' }}
      </template>
      <template v-slot:[`item.internalNotes`]="{ item }">
        <span class="internal-notes">{{ item.internalNotes }}</span>
      </template>
      <template v-slot:[`item.details`]="{ item }">
        <button class="text small" @click="viewItem(item)">{{ actionText }}</button>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { doc, updateDoc } from 'firebase/firestore'
export default {
  name: 'TokenTable',
  props: {
    data: {
      type: Array,
      default: () => []
    },
    mode: {
      type: String,
      default: 'collectionContract'
    },
    additionalHeaders: {
      type: Array,
      default: () => []
    },
    hideDefaultHeaders: {
      type: Boolean,
      default: false
    },
    hideActionButton: {
      type: Boolean,
      default: false
    },
    actionText: {
      type: String,
      default: 'View'
    },
    selectable: {
      type: Boolean,
      default: false
    },
    light: {
      type: Boolean,
      default: false
    },
    itemsPerPageOptions: {
      type: Array,
      default: () => [5, 10, 15, -1]
    }
  },
  data () {
    return {
      search: '',
      selected: []
    }
  },
  computed: {
    computedHeaders () {
      const headers = this.hideDefaultHeaders ? [] : [
        {
          text: '',
          value: 'image',
          sortable: false
        },
        {
          text: 'Name',
          value: 'name'
        }
      ]

      const formatted = headers.concat(this.additionalHeaders)
      if (!this.hideActionButton) {
        formatted.push({
          text: '',
          value: 'details',
          sortable: false
        })
      }
      return formatted
    }
  },

  methods: {
    async updateActive (item) {
      try {
        await updateDoc(
          doc(this.$fb.db, 'token-contracts', item.tokenAddress, 'types', item.id),
          { active: item.active }
        )
      } catch (err) {
        console.log(err)
        this.addNotification(`Error updating active status for ${item.id}.`, 'error')
      }
      this.addNotification(`Active status for ${item.id} updated.`, 'success')
    },

    async updateFeatured (item) {
      try {
        await updateDoc(
          doc(this.$fb.db, 'token-contracts', item.tokenAddress, 'types', item.id),
          { featured: item.featured }
        )
      } catch (err) {
        console.log(err)
        this.addNotification(`Error updating featured status for ${item.id}.`, 'error')
      }
      this.addNotification(`Featured status for ${item.id} updated.`, 'success')
    },

    updatePage (page) {
      this.$emit('updatePage', page)
    },

    updateItemsPerPage (items) {
      this.$emit('updateItemsPerPage', items)
    },

    viewItem (item) {
      this.$emit('view', item)
    }
  },

  watch: {
    selected: function (val) {
      this.$emit('selection', this.selected)
    }
  }
}
</script>

<style lang="scss" scoped>
.v-data-table {
  .thumbnail {
    @include bg-cover-center;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    margin: $space-s 0;
  }
  .internal-notes {
    display: inline-block;
    white-space: nowrap;
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
