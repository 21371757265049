<!--
  This component is used in a variety of view to present a token's media and associted data.
  It displays:
  - Data about an individual token based on its ID and specific issuance #
  - Data about a particular token "type"
  - Data about a token collection
-->

<template>
  <div class="token-instance">
    <div v-bind:class="mode === 'portfolio' ? 'media-info-portfolio' : 'media-info'">
      <div v-if="contract && targetData" class="media">
        <MediaContainer
          v-if="(contractId && typeId) || mode === 'collection'"
          autoplay
          :mode="mode"
          :showUI="mode === 'portfolio'"
          :class="{ 'spatial-content': targetData && targetData.model }"
          :modelPath="
            mode !== 'collection-primary' &&
            targetData &&
            targetData.model &&
            contractId &&
            typeId ?
            tokenModelPath(contractId, typeId) : ''"
          :videoPath="
            mode !== 'collection-primary' &&
            targetData &&
            targetData.video &&
            contractId &&
            typeId ?
            tokenVideoPath(contractId, typeId) : ''"
          :imagePath="targetImagePath"
          :viewerSettings="targetData.viewerSettings"
        />
        <TypeThumbnails v-if="mode !== 'portfolio'" :series="targetData.series" />
      </div>
      <div v-if="contract && targetData" class="info">
        <div class="summary">
          <template>
            <h2 v-if="mode !== 'collection'" class="name" :class="{ break: breakName }">
              {{ displayedName }}
            </h2>
          </template>

          <h4 v-if="mode === 'portfolio'" class="byline">
            #{{ displayedId }} <span v-if="!targetData.hideCap">of {{ targetData.cap }}</span>
          </h4>

          <h4 v-if="marketCollection.collectionGroup && mode !== 'portfolio'" class="byline">
            By
            <router-link :to="`/artists/${marketCollection.collectionGroup.id}`">
              {{ marketCollection.collectionGroup.name }}
            </router-link>
          </h4>

          <p v-if="targetData.description" class="description">{{ targetData.description }}
            <span v-if="targetData.companionUrl">
              <br>
              <br>This artwork drops as a companion to the limited edition print available for a limited time at <a :href="targetData.companionUrl" target="_blank">1xrun.com</a>
            </span>
          </p>

          <!-- Hiding attributes table for now -->
          <!-- <table v-if="targetData.attributes" class="attributes-table">
            <tr v-for="(attribute, index) in targetData.attributes" :key="`attribute-${index}`">
              <td>{{ attribute.trait_type }}:</td>
              <td>{{ attribute.value }}</td>
            </tr>
          </table> -->
          <div v-if="mode === 'portfolio'" class="attributes-container">
            <div
              v-for="(attribute, index) in targetData.attributes"
              :key="`attribute-${index}`"
              class="attributes-container__single"
            >
              <template v-if="attribute.trait_type === 'audio'">
                <h4>Audio Track</h4>
                <audio
                  :src="tokenAudioAttributePath(contractId, typeId)"
                  controls
                />
              </template>
            </div>
          </div>

          <div class="social">
            <SocialActions
              :mediaForDownload="targetData.video || targetData.image"
              :mediaName="targetData.name"
            />
          </div>
        </div>

        <div class="details-container">
          <div class="market-data-container">
            <h4 @click="showingDetails = !showingDetails">
              Details
              <div class="expander" :class="{ open: showingDetails }">
                <v-icon>mdi-chevron-down</v-icon>
              </div>
            </h4>
            <v-expand-transition>
              <div v-if="mode !== 'collection' && showingDetails">
                <table class="data-table">
                  <tr v-if="mode === 'portfolio'">
                    <td>Artist</td>
                    <td>{{ marketCollection.collectionGroup.name }}</td>
                  </tr>
                  <tr>
                    <td>Publisher</td>
                    <td>{{ targetData.publisher || '1XRUN' }}</td>
                  </tr>
                  <tr>
                    <td>Collection</td>
                    <td>{{ marketCollection.name }}</td>
                  </tr>
                  <tr v-if="targetData.series">
                    <td>Series</td>
                    <td>{{ targetData.series.name }}</td>
                  </tr>
                  <tr v-if="targetData.typePrimary">
                    <td>Artwork</td>
                    <td>{{ targetData.typePrimary.name }}</td>
                  </tr>
                  <tr v-if="mode !== 'collection' && targetMintCap">
                    <td>Minted / Cap</td>
                    <td>{{ targetMintCap.minted }} / {{ targetMintCap.cap }}</td>
                  </tr>
                  <tr v-if="mode !== 'collection-primary'">
                    <td>Royalities</td>
                    <td>{{ `${ (this.totalRoyalties * 100).toFixed(1) }%` }}</td>
                  </tr>
                  <tr v-if="mode === 'portfolio'">
                    <td>Provenance</td>
                    <td><p class="text-action" @click="showTokenProvenanceModal">View Details</p></td>
                  </tr>
                  <tr v-if="marketCollection">
                    <td>Contract</td>
                    <td>
                      <a
                        :href="`${nearExplorer()}/accounts/${marketCollection.id}`"
                        target="_blank"
                      >
                        {{ marketCollection.id }}
                      </a>
                    </td>
                  </tr>
                </table>
              </div>
            </v-expand-transition>
          </div>

          <div class="market-data-container">
            <h4 @click="showingMarketInsights = !showingMarketInsights">
              Market Insights
              <div class="expander" :class="{ open: showingMarketInsights }">
                <v-icon>mdi-chevron-down</v-icon>
              </div>
            </h4>
            <v-expand-transition>
              <div v-if="showingMarketInsights">
                <table class="data-table market-details">
                  <!-- <tr>
                    <td>Total Minted</td>
                    <td>{{ (mode === 'collection' || mode === 'collection-primary') && targetMarketData ? targetMarketData.cap : targetData.cap }}</td>
                  </tr> -->
                  <tr>
                    <td>{{ mode === 'portfolio' ? 'Total on Market' : 'On Market' }}</td>
                    <td>{{ targetMarketData ? targetMarketData.onMarket : 0 }}</td>
                  </tr>
                  <tr>
                    <td>Lowest Ask</td>
                    <td v-if="targetMarketData && targetMarketData.lowest">
                      <CryptoFiat :crypto="targetMarketData.lowest" />
                    </td>
                    <td v-else>-</td>
                  </tr>
                  <tr>
                    <td>Highest Sale</td>
                    <td v-if="targetMarketData && targetMarketData.highestSale">
                      <CryptoFiat :crypto="targetMarketData.highestSale" />
                    </td>
                    <td v-else>-</td>
                  </tr>

                  <tbody
                    v-if="!saleData && mode !== 'contract' && showAskingPriceInput"
                    class="price-data"
                  >
                    <tr class="price-input">
                      <td>Your Ask</td>
                      <td>
                        <div class="asking-price-input-wrapper">
                          <div class="asking-formatted">
                            <span class="asking-formatted-list-price">
                              {{ listPrice }}
                            </span>
                            <NearIcon color="white" :size="12" />
                            (${{ listPriceFormattedUsd }})
                          </div>
                          <TextInput v-model="listPrice" class="asking-price-input" type="number" :min="0" left mono />
                        </div>
                      </td>
                    </tr>
                    <tr class="dimmed">
                      <td>Royalties</td>
                      <td>
                        <CryptoFiat :crypto="royaltiesCut" iconColor="#666" />
                      </td>
                    </tr>
                    <tr class="dimmed">
                      <td>Your Take</td>
                      <td>
                        <CryptoFiat :crypto="totalTake" iconColor="#666" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </v-expand-transition>

            <!-- If showing a single token, and sale data is available, display here. -->
            <table v-if="mode !== 'contract' && saleData" class="data-table">
              <tr>
                <td>Asking Price</td>
                <td v-if="saleData && saleData.conditions[0].price">
                  <CryptoFiat :crypto="saleData.conditions[0].price" />
                </td>
                <td v-else>-</td>
              </tr>
              <tr v-if="mode == 'market'">
                <td>Owner</td>
                <td>{{ displayedOwner ? displayedOwner.name : token.ownerAddress }}</td>
              </tr>
            </table>
          </div>

          <div class="market-data-container" v-if="showAskingRecipientInput">
            <NearAddressInput
              v-model="sendRecipient"
              title="Send Token"
              label="Recipient Address"
              sideBySide
              @checkingAddress="checkingAddressUpdate"
              @validAddress="validAddressUpdate"
            />
          </div>

          <div class="market-data-container">
            <div v-if="singleTokenView" class="actions">
              <!-- Show UI related to listing if the user is the owner of the token -->
              <template v-if="userProfile && token.ownerAddress == userProfile.nearId">
                <div class="actions-container">
                  <button
                    v-if="!saleData && !showAskingRecipientInput && !showAskingPriceInput"
                    class="standard"
                    :disabled="!canSend"
                    @click="toggleAskingRecipient"
                  >
                    <v-icon>mdi-call-made</v-icon>
                    Send Token
                  </button>
                  <button
                    v-if="!saleData && !showAskingPriceInput && !showAskingRecipientInput"
                    class="standard"
                    :disabled="!canList"
                    @click="toggleAskingPrice"
                  >
                    <img class="icon" src="../../assets/icons/dollar.svg" />
                    Add to Market
                  </button>
                </div>
                <div v-if="showAskingPriceInput" class="actions-container">
                  <button
                    class="standard cancel-button"
                    @click="toggleAskingPrice"
                  >
                    <v-icon>mdi-close</v-icon>
                    Cancel
                  </button>
                  <button
                    class="standard publish-button"
                    @click="listToken"
                    :disabled="!listPrice || !Number(listPrice)"
                  >
                    <v-icon v-if="!loadingMarketStatus">mdi-check-circle-outline</v-icon>
                    {{ loadingMarketStatus ? '...' : 'Publish' }}
                  </button>
                </div>

                <div v-if="showAskingRecipientInput" class="actions-container">
                  <button
                    class="standard cancel-button"
                    @click="toggleAskingRecipient"
                  >
                    <v-icon>mdi-close</v-icon>
                    Cancel
                  </button>
                  <button
                    class="standard publish-button spinner-button"
                    @click="sendToken"
                    :disabled="sendingToken || !validAddress"
                  >
                      <v-icon v-if="!sendingToken && !checkingAddress">mdi-check-circle-outline</v-icon>
                      <v-progress-circular
                        v-if="sendingToken"
                        indeterminate
                      />
                      <span v-bind:style="{'margin-left': sendingToken ? '32px' : '0px'}">
                        {{ sendingToken ? 'Sending ' : (!checkingAddress ? 'Are you sure?' : 'Validating...') }}
                      </span>
                  </button>
                </div>

                <div v-else-if="saleData" class="actions-container">
                  <button class="standard" @click="confirmDelist = true">
                    {{ loadingMarketStatus ? '...' : 'Delist' }}
                  </button>
                </div>

                <div v-if="!saleData" class="action-errors">
                  <p v-if="!canSend" class="error small">
                    A minimum balance of approximately {{ sendMinimum }} NEAR is required to send a token to another wallet.
                  </p>
                  <p v-if="!canList" class="error small">
                    A minimum balance of approximately {{ listMinimum }} NEAR is required to list a token on the market.
                  </p>
                  <MoonPayTopUpButton v-if="!canSend || !canList" />
                </div>
              </template>
              <!-- Otherwise show the purchase button -->
              <template v-else>
                <button class="standard inverted" @click="attemptPurchase">
                  {{ loadingMarketStatus ? '...' : 'Buy Now' }}
                </button>
              </template>
            </div>
          </div>

          <LoaderCover
            v-if="loadingMarketStatus && !loadingToken"
            small
            center
            transparentFull
          />
        </div>
      </div>
    </div>

    <TokenHistoryTable
      v-if="tokenId && mode !== 'portfolio'"
      ref="historyTable"
      :token="tokenId"
      :contract="contractId"
      class="token-history-table"
    />

    <transition name="fade-slow" mode="out-in">
      <DelistModal v-if="confirmDelist" @confirm="delistToken" @close="confirmDelist = false" />
    </transition>

    <LoaderCover v-if="loadingToken" fixed center transparent />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { utils } from 'near-api-js'
import TypeThumbnails from '@/components/TypeThumbnails'
import DelistModal from '@/components/modals/DelistModal'
import BigNumber from 'bignumber.js'

export default {
  name: 'TokenSingle',
  props: {
    mode: {
      type: String,
      default: 'market'
    }
  },

  components: {
    TypeThumbnails,
    DelistModal
  },

  data () {
    return {
      tokenDataLoaded: false,
      listPrice: '1',
      displayedOwner: null,

      contractId: '',
      contract: {},
      tokenId: '',
      typeId: '',
      token: {},

      saleData: null,

      loadingToken: true,
      loadingMarketStatus: true,

      showingDetails: false,
      showingMarketInsights: true,

      sendingToken: false,
      showAskingPriceInput: false,
      showAskingRecipientInput: false,
      sendRecipient: '',
      sendTimeout: null,
      sendError: '',
      validAddress: null,
      checkingAddress: false,

      confirmDelist: false,
      sendMinimum: 0.1,
      listMinimum: 0.5
    }
  },

  mounted () {
    this.init()
  },

  computed: {
    ...mapGetters([
      'userProfile',
      'userNearSignature',
      'profileBalanceFormatted',
      'nearConnection',
      'targetTypeData',
      'marketCollection',
      'nearUsdExchangeRate',
      'marketData',
      'marketDataByType',
      'marketDataByTypePrimary',
      'globalSettings',
      'claimsByCollectionPrimary',
      'claimsByCollection'
    ]),

    canList () {
      return this.profileBalanceFormatted >= this.listMinimum
    },

    canSend () {
      return this.profileBalanceFormatted >= this.sendMinimum
    },

    // In this case, the component is displaying media for a token with of a specific ID,
    // not of a group of tokens or types
    singleTokenView () {
      return this.mode !== 'contract' && this.mode !== 'collection' && this.mode !== 'collection-primary'
    },

    targetData () {
      if (this.mode === 'collection') {
        return this.marketCollection.metadata
      } else {
        return this.targetTypeData
      }
    },

    displayedName () {
      return this.mode === 'collection-primary' && this.targetData.series.name !== this.targetData.typePrimary.name
        ? this.targetData.series.name + ' - ' + this.targetData.typePrimary.name
        : this.targetData.name
    },

    targetImagePath () {
      if (this.mode === 'collection') {
        return this.collectionImagePath(this.marketCollection.id)
      }
      if (this.mode === 'collection-primary' && this.targetData?.typePrimary?.hasImage) {
        return this.primaryVariantImagePath(this.contractId, this.$route.params.primary)
      }
      return this.tokenImagePath(this.contractId, this.typeId, this.mode === 'portfolio' ? 1080 : 880)
    },

    targetMintCap () {
      let mintCap = {}
      if (this.$route.params.secondary === 'all') {
        mintCap = {
          minted: this.claimsByCollectionPrimary ? this.claimsByCollectionPrimary(this.$route.params.primary, true).claimed : '-',
          cap: this.targetMarketData ? this.targetMarketData.cap : '-'
        }
      } else {
        mintCap = {
          minted: this.claimsByCollection[this.typeId] ? this.claimsByCollection[this.typeId].claimed : '-',
          cap: this.targetTypeData ? this.targetTypeData.cap : '-'
        }
      }
      return mintCap
    },

    breakName () {
      return this.wordBreakCheck(this.displayedName)
    },

    targetMarketData () {
      if (this.mode === 'collection' && this.marketData) {
        return this.marketData[this.$route.params.collection]
      } else if (this.mode === 'collection-primary') {
        return this.marketDataByTypePrimary(this.$route.params.collection, this.$route.params.primary, true)
      } else {
        return this.marketDataByType(this.$route.params.collection, this.typeId)
      }
    },

    totalRoyalties () {
      if (!this.targetTypeData || !this.targetTypeData.properties) return null
      let total = 0
      this.targetTypeData.properties.forEach((prop) => {
        if (['royalty', 'market-royalty', 'creator-royalty', 'blkdrp-royalty'].includes(prop.id)) {
          total += parseFloat(prop.value)
        }
      })
      return total / 100.0
    },

    royaltiesCut () {
      if (!this.listPriceFormatted || !this.totalRoyalties) return '0'
      const cut = new BigNumber(this.listPriceFormatted).multipliedBy(new BigNumber(this.totalRoyalties))
      return cut.toFixed()
    },

    // TODO: Figure out why this breaks on large numbers
    totalTake () {
      if (!this.listPriceFormatted || !this.royaltiesCut) return '0'
      const total = new BigNumber(this.listPriceFormatted).minus(new BigNumber(this.royaltiesCut))
      return total.toFixed()
    },

    currentPage () {
      return window.location.href
    },

    displayedId () {
      if (this.token && this.token.id) {
        return this.formatIssuance(this.token.id)
      } else {
        return ''
      }
    },

    listPriceFormatted () {
      if (!this.listPrice) return '0'
      const formatted = this.listPrice.toString()
      return utils.format.parseNearAmount(formatted).toString()
    },

    listPriceFormattedUsd () {
      if (!this.nearUsdExchangeRate) return '0'
      const exchangeRate = this.nearUsdExchangeRate || 0
      return (this.listPrice * exchangeRate).toLocaleString('en-US',
        { style: 'decimal', minimumFractionDigits: 2 })
    },

    listingData () {
      const listingData = {
        chainId: process.env.VUE_APP_NEAR_NETWORK,
        marketContract: this.globalSettings ? this.globalSettings.marketContract : '',
        tokenContract: this.contractId,
        tokenId: this.token.id,
        seller: this.userProfile ? this.userProfile.nearId : '',
        prices: [
          {
            ftContract: 'near'
          }
        ]
      }
      console.log('Listing data: ', listingData)
      return listingData
    }
  },
  methods: {
    ...mapActions([
      'initiatePurchase',
      'getTargetTypeData',
      'getUserByNearId',
      'claimsByCollectionListener'
    ]),
    ...mapMutations([
      'setShowingTokenProvenance'
    ]),

    /**
     * Initialize the view based on URL params
     * Note that this method is also called externally when new types are loaded.
     */
    async init () {
      if (
        !this.marketData ||
        !this.marketData[this.marketCollection.id] ||
        !this.marketData[this.marketCollection.id].types
      ) {
        return
      }
      this.claimsByCollectionListener(this.marketCollection.id)
      this.tokenDataLoaded = true
      // Get all URL parameters
      const primary = this.$route.params.primary || null
      let secondary = this.$route.params.secondary || null

      // If viewing all of a primary type, default to the lowest rarity variant
      if (this.$route.params.secondary === 'all') {
        let lowestOrder = 99 // Arbitrarily high number
        this.marketData[this.marketCollection.id].types.forEach((type) => {
          if (type.typePrimary.id === primary && type.typePrimary.order < lowestOrder) {
            secondary = type.typeSecondary.id
            lowestOrder = type.typePrimary.order
          }
        })
      }
      const issuance = this.$route.params.issuance || null
      this.tokenId = this.$route.params.token || null
      this.contractId = this.$route.params.collection || null

      if (issuance) {
        this.tokenId = primary + '.' + (secondary !== '-' ? secondary + '.' : '') + issuance
      }
      if (this.mode !== 'portfolio') {
        this.typeId = primary + (secondary !== '-' ? '.' + secondary : '')
      } else if (this.tokenId) {
        const segments = this.tokenId.split('.')
        this.typeId = segments[0] + '.' + segments[1]
      }

      try {
        await this.getData()
      } catch (err) {
        console.log(err)
        this.addNotification('Error getting token data.', 'error')
      }
      if (this.mode !== 'contract' && this.mode !== 'collection') {
        // In contract mode, we don't need to get the type data again (the Market_Contract view retrieves it)
        try {
          await this.getTargetTypeData({
            type: this.typeId,
            contract: this.$route.params.collection
          })
        } catch (err) {
          console.log(err)
          this.addNotification('Error getting token data.', 'error')
        }
        try {
          await this.getSaleData()
        } catch (err) {
          console.log('Error getting sale data. Sale data simply may not exist for this token.')
        }
      }

      this.loadingToken = false
      this.loadingMarketStatus = false
      this.$emit('loaded')
    },

    /**
     * Get data about the target token based on its ID (assuming this isn't a view of multiple tokens)
     */
    async getData () {
      if (this.tokenId && this.mode !== 'collection') {
        try {
          const token = await this.fbCall('getToken', { tokenContract: this.contractId, token: this.tokenId })
          this.token = token.data
        } catch (err) {
          console.log(err)
        }
        try {
          this.displayedOwner = await this.getUserByNearId(this.token.ownerAddress)
        } catch (err) {
          console.log(err)
        }
      } else if (this.mode === 'collection') this.token = {}
    },

    /**
     * Used to send a token to another NEAR wallet
     */
    async sendToken () {
      console.log(`Attempting to send token ${this.tokenId} to ${this.sendRecipient}.`)
      try {
        this.sendingToken = true
        await this.fbCall('transferToken', {
          transfer: {
            tokenAddress: this.contractId,
            tokenId: this.tokenId,
            sender: this.userProfile.nearId,
            receiver: this.sendRecipient,
            price: '0'
          },
          signature: this.userNearSignature
        })
        this.addNotification('Token transferred successfully.', 'success')
      } catch (err) {
        console.log(err)
        this.addNotification(err.message, 'error')
      }

      this.sendingToken = false
      this.$router.push('/portfolio')
    },

    /**
     * Used to list the target token on the market (assuming a single token view)
     */
    async listToken () {
      if (!this.listPrice || !Number(this.listPrice)) {
        return
      }
      this.loadingMarketStatus = true
      this.listingData.prices[0].price = this.listPriceFormatted
      console.log('Listing token with data:')
      console.log(this.listingData)
      try {
        const listingData = { listing: this.listingData, signature: this.userNearSignature }
        console.log(listingData)
        await this.fbCall('listToken', listingData)
      } catch (err) {
        console.log(err)
        this.loadingMarketStatus = false
        this.addNotification(err.message, 'error')
        return
      }
      this.showAskingPriceInput = false
      this.addNotification(`Token ${this.listingData.tokenId} successfully listed.`, 'success', true)
      await this.refreshTokenData()
    },

    async delistToken () {
      this.confirmDelist = false
      this.loadingMarketStatus = true
      this.listingData.prices[0].price = this.saleData.conditions[0].price
      console.log('Delisting token with data:')
      console.log(this.listingData)
      try {
        await this.fbCall('delistToken', { listing: this.listingData, signature: this.userNearSignature })
      } catch (err) {
        console.log(err)
        this.addNotification('Error removing token from market.', 'error')
      }
      try {
        await this.refreshTokenData()
      } catch (err) {
        console.log('Error refrefreshing token data.', err)
      }
    },

    async getSaleData () {
      if (this.token.id) {
        const settings = {
          marketContract: this.globalSettings ? this.globalSettings.marketContract : '',
          tokenContract: this.contractId,
          tokenId: this.token.id
        }
        try {
          const res = await this.fbCall('getSaleData', settings)
          this.saleData = res.data
          console.log(this.saleData)
        } catch (err) {
          this.saleData = null
          console.log('Error getting sale data', err)
          // this.addNotification('Error getting market data.', 'error')
        }
      }
      this.loadingMarketStatus = false
    },

    async refreshTokenData () {
      try {
        this.getSaleData()
      } catch (err) {
        console.log('Error getting sale data on refresh', err)
      }
      console.log('Refreshing history table.')
      if (this.$refs.historyTable) this.$refs.historyTable.init()
    },

    attemptPurchase () {
      this.listingData.prices[0].price = this.saleData.conditions[0].price
      console.log('Initiating purchase with data:')
      console.log(this.listingData)
      this.initiatePurchase(this.listingData)
    },

    toggleAskingPrice () {
      this.showAskingPriceInput = !this.showAskingPriceInput
    },

    toggleAskingRecipient () {
      this.showAskingRecipientInput = !this.showAskingRecipientInput
      if (!this.showAskingRecipientInput) {
        this.sendingToken = false
      }
    },

    showTokenProvenanceModal () {
      this.setShowingTokenProvenance({
        show: true,
        payload: { tokenId: this.tokenId, contractId: this.contractId }
      })
    },

    checkingAddressUpdate (val) {
      this.checkingAddress = val
    },

    validAddressUpdate (val) {
      this.validAddress = val
    }
  },

  watch: {
    // If market data hasn't been loaded (in the case of landing on this page initially),
    // We need to wait until the relevant market data is present
    marketData: function () {
      if (this.tokenDataLoaded) return
      this.init()
    }
  }
}
</script>

<style lang="scss" scoped>
.text-action {
  text-decoration: underline;
  cursor: pointer;
}

.token-history-table {
  padding-bottom: $space-xl;
}

.token-instance {
  position: relative;

  .info {
    h4 {
      margin-bottom: $space-ms;
      &.collection-name {
        margin: 0;
      }
      &.byline {
        margin-top: $space-s;
      }
    }

    .description {
      white-space: pre-wrap;
    }

    @include media($bp-tablet) {
      text-align: center;
      h4 {
        margin-bottom: $space-s;
      }
      h4.accented::after {
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
      }
    }

    .attributes-table {
      width: 100%;
      font-size: 12px;
      margin-top: $space-s;
      td:first-child {
        text-align: left;
      }
      td:last-child {
        text-align: right;
      }
    }

    .attributes-container {
      &__single {
        audio {
          width: 100%;
        }
      }
    }

    .market-data-container {
      position: relative;
      padding-bottom: $space-ml;

      h4 {
        cursor: pointer;
        position: relative;
        .expander {
          position: absolute;
          top: 0;
          right: 0;
          transition: 0.3s all;

          &.open {
            transform: rotateZ(180deg);
            transition: 0.3s all;
          }
        }
      }

      .errors {
        margin-top: $space-m;
      }
    }

    .recipient-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .field-container {
        width: 50%;
        margin-bottom: 0px;
        ::v-deep input {
          font-size: $font-size-ms;
        }
      }
    }

    .data-table {
      .price-data {
        td:first-child {
          border-bottom: none;
        }
        tr.dimmed {
          color: $blk-grey-4;
        }
      }
      tr.price-input {
        td {
          width: 50%;
        }
      }
    }

    .actions-container {
      display: flex;
      flex-direction: row;
      justify-content: space-around;

      button {
        width: 45%;
      }

      .spinner-button {
        position: relative;
      }

      ::v-deep .v-progress-circular {
        position: absolute;
        left: 30%;
        width: $space-m !important;
        height: $space-m !important;
        top: 50%;
        .v-progress-circular__overlay {
          stroke: $blk-white;
        }
      }

      @include media($bp-tablet) {
        flex-direction: column;
        padding: 0 $space-mm;
        button {
          width: 100%;
          margin-bottom: $space-ss;
        }

        ::v-deep .v-progress-circular {
          left: 33%;
        }
      }
    }

    .action-errors {
      width: 95%;
      margin: $space-m auto 0;

      @include media($bp-tablet) {
        width: calc(100% - #{$space-mm * 2});
      }

      p.error {
        margin-bottom: $space-s;
      }

      ::v-deep .moonpay {
        width: 100%;
      }
    }

    .publish-actions {
      justify-content: space-between;
      display: flex;
      gap: $space-s;

      .button-standard {
        flex: 1;
      }
    }

    .asking-price-input-wrapper {
      font-family: $font-body;
      position: relative;

      .field-container {
        margin: 0;

        ::v-deep input {
          font-size: $font-size-ms;
          font-family: $font-body;
          border-bottom: none;
          padding: 0;
        }
      }
    }

    .asking-formatted {
      right: 0;
      bottom: 0;
      position: absolute;
    }

    .asking-formatted-list-price {
      opacity: 0;
      pointer-events: none;
    }
  }
}
</style>
