/**
 * This file initializes the connection between the website front-end and Firebase.
 * It also sets up utility variables for accessing databases, etc...
 */
import { initializeApp, getApp } from 'firebase/app'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
import { getFirestore, connectFirestoreEmulator, collection } from 'firebase/firestore'
import { getAnalytics } from 'firebase/analytics'
import { getAuth } from 'firebase/auth'
import * as firebaseJSON from '../firebase.json'

function createFirebaseApp () {
  try {
    return getApp()
  } catch {
    return initializeApp({
      apiKey: process.env.VUE_APP_FB_API_KEY,
      authDomain: process.env.VUE_APP_FB_AUTH_DOMAIN,
      projectId: process.env.VUE_APP_FB_PROJECT_ID,
      storageBucket: process.env.VUE_APP_FB_STORAGE_BUCKET,
      messagingSenderId: process.env.VUE_APP_FB_MSG_SENDER_ID,
      appId: process.env.VUE_APP_FB_APP_ID,
      measurementId: process.env.VUE_APP_MEASUREMENT_ID
    })
  }
}

const firebaseApp = createFirebaseApp()

const root = firebaseApp

const db = getFirestore(firebaseApp)
const auth = getAuth(firebaseApp)
const functions = getFunctions()
const analytics = getAnalytics(firebaseApp)
if (process.env.NODE_ENV === 'development' && process.env.VUE_APP_USE_EMULATORS === 'true') {
  const firebaseEmulator = firebaseJSON.emulators
  console.log('Using Firebase emulator suite.')
  // NOTE: Auth emulators don't work with Web3Auth
  // connectAuthEmulator(auth, 'http://localhost:' + firebaseEmulator.auth.port)
  connectFunctionsEmulator(functions, 'localhost', firebaseEmulator.functions.port)
  connectFirestoreEmulator(db, 'localhost', firebaseEmulator.firestore.port)
}

const marketData = collection(db, 'market-data')
const marketSettings = collection(db, 'market-settings')
const claimsCollection = collection(db, 'claims')
const claimsDataCollections = collection(db, 'claim-data-collections')
const claimsDataSales = collection(db, 'claim-data-sales')
const tokenContracts = collection(db, 'token-contracts')
const usersCollection = collection(db, 'users')
const collectionGroups = collection(db, 'collection-groups')
const seriesCollection = collection(db, 'series')
const promotionsCollection = collection(db, 'promotions')

export default {
  root,
  db,
  auth,
  functions,
  analytics,

  marketData,
  marketSettings,
  claimsCollection,
  claimsDataCollections,
  claimsDataSales,
  tokenContracts,
  usersCollection,
  collectionGroups,
  seriesCollection,
  promotionsCollection
}
