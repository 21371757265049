<template>
  <svg class="blk-icon icon-add" width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M26.25 18C26.25 17.3096 25.6904 16.75 25 16.75C24.3096 16.75 23.75 17.3096 23.75 18H26.25ZM23.75 32C23.75 32.6904 24.3096 33.25 25 33.25C25.6904 33.25 26.25 32.6904 26.25 32H23.75ZM23.75 18V32H26.25V18H23.75Z" :fill="fillColor"/>
    <path d="M18 23.75C17.3096 23.75 16.75 24.3096 16.75 25C16.75 25.6904 17.3096 26.25 18 26.25V23.75ZM32 26.25C32.6904 26.25 33.25 25.6904 33.25 25C33.25 24.3096 32.6904 23.75 32 23.75V26.25ZM18 26.25H32V23.75H18V26.25Z" :fill="fillColor"/>
    <circle cx="25" cy="25" r="17.0833" :stroke="strokeColor" stroke-width="2.5"/>
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      default: '#F4F4F4'
    },
    strokeColor: {
      type: String,
      default: '#F4F4F4'
    }
  }
}
</script>

<style lang="scss">
.icon-add {
  width: 21px;
  height: 21px;
  vertical-align: middle;
}
</style>
