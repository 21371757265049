<template>
  <div class="disclaimer">
    <p class="small">
      This project is in public beta.
      <v-icon
        role="button"
        @click="showInfo"
      >
        mdi-help-circle-outline
      </v-icon>
    </p>
    <transition name="fade">
      <Modal
        v-if="showingModal"
        compact
        title="Disclaimer"
        subTitle="This project is in public beta."
        cancelLabel="Close"
        @close="showingModal = false"
      >
        <p>This project is still under active development, please use at your own risk. Should you have any issues or find any bugs feel free to let us know by contacting us at <a href="mailto:support@1xrun.com">support@1xrun.com</a>.</p>
      </Modal>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'FooterDisclaimer',
  data () {
    return {
      showingModal: false
    }
  },
  methods: {
    showInfo () {
      this.showingModal = true
    }
  }
}
</script>

<style lang="scss" scoped>
.disclaimer p {
  color: $blk-grey-4;
  margin: $space-m 0 0;
  .v-icon {
    font-size: $font-size-m;
    color: $blk-grey-4;
  }
}

::v-deep .central p {
  color: white;
}
</style>
