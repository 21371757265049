/**
 * Handles global Vuetify settings for the application.
 */

import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: true,
    disable: true
  },
  breakpoint: {
    thresholds: {
      sm: 768,
      md: 1024
    }
  }
})
