/**
 * Manages CRUD operations for collection groups.
 */
import fb from '../../../firebase_config/firebase'
import { collection, addDoc, getDocs, query, where, orderBy } from 'firebase/firestore'
const state = () => ({
  collectionGroups: null,
  targetCollectionGroup: null,
  targetGroupCollections: null,
  targetGroupTypes: null
})

const getters = {
  collectionGroups: state => state.collectionGroups,
  targetCollectionGroup: state => state.targetCollectionGroup,
  targetGroupCollections: state => state.targetGroupCollections,
  targetGroupTypes: state => state.targetGroupTypes
}

const mutations = {
  setCollectionGroups (state, val) {
    state.collectionGroups = val
  },
  setTargetCollectionGroup (state, val) {
    state.targetCollectionGroup = val
  },
  setTargetGroupCollections (state, val) {
    state.targetGroupCollections = val
  },
  setTargetGroupTypes (state, val) {
    state.targetGroupTypes = val
  }
}

const actions = {
  /**
   * Add a new document to Firestore representing the Collection Group
   * @param {Object} data - the data to be added to the new document
   * @returns the added Firestore document
   */
  async addNewCollectionGroup ({ commit }, data) {
    const res = await addDoc(fb.collectionGroups, data)
    return res
  },

  async getCollectionGroups ({ commit }) {
    const collectionGroups = []
    const snapshot = await getDocs(query(fb.collectionGroups))
    snapshot.forEach((collectionGroup) => {
      collectionGroups.push(collectionGroup.data())
    })
    commit('setCollectionGroups', collectionGroups)
  },

  /**
   * Get a target collection group by its ID
   * @param {String} target - the ID of the target collection group
   */
  async getCollectionGroup ({ commit }, target) {
    const snapshot = await getDocs(query(fb.collectionGroups, where('id', '==', target)))
    snapshot.forEach((collectionGroup) => {
      commit('setTargetCollectionGroup', collectionGroup.data())
    })
  },

  /**
   * Get all of the collections associated with the target collection group ID
   * @param {String} target - the ID of the target collection group
   */
  async getTargetGroupCollections ({ commit, state }, target) {
    console.log(`Getting all collections in group ${target}`)
    const collections = []
    const snapshot = await getDocs(query(fb.tokenContracts, where('collectionGroup.id', '==', target)))
    snapshot.forEach((collection) => {
      const copy = collection.data()
      copy.id = collection.id
      collections.push(copy)
    })
    commit('setTargetGroupCollections', collections)
  },

  async getTargetGroupTypes ({ commit, dispatch, state }, target) {
    console.log(`Getting all types in group ${target}`)
    // Get collections if they aren't already set
    await dispatch('getTargetGroupCollections', target)
    const types = []
    for (const coll of state.targetGroupCollections) {
      const snapshot = await getDocs(
        query(
          collection(fb.db, 'token-contracts', coll.id, 'types'),
          where('active', '==', true),
          orderBy('order')
        )
      )
      snapshot.forEach((type) => {
        const copy = type.data()
        copy.id = type.id
        copy.collection = coll.id
        copy.collectionName = coll.name
        types.push(copy)
      })
    }
    commit('setTargetGroupTypes', types)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
