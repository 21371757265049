/**
 * Handles getting data from global market-data collection.
 * This data is generated by a scheduled Firebase function which calculates the relevant data,
 * based on token contracts and types in the Firestore database, as well as data retrieved from the Collab Land API.
 */

import { getDocs, query, doc, collection, onSnapshot, getDoc } from 'firebase/firestore'
import fb from '../../../firebase_config/firebase'

const BigNumber = require('bignumber.js')

const state = () => ({
  marketData: {},
  marketCollectionListenersUnsub: {},
  marketListenersActive: false
})

const getters = {
  marketData: state => state.marketData,
  marketListenerUnsub: state => state.marketListenerUnsub,

  /**
   * Get market data specific to the target collection
   * @param {String} collection - The ID of the target collection
   * @returns The data object for the target collection
   */
  marketDataByCollection: (state) => (collection) => {
    return state.marketData[collection].types
  },

  /**
   * Gets the total number of tokens listed for all types in the collection
   * @param {String} collection - The target collection
   * @returns The number of listed tokens in the collection for all types
   */
  totalInCollection: (state) => (collection) => {
    console.log(`Getting total for ${collection}`)
    if (!state.marketData[collection]) return 0
    return state.marketData[collection].onMarket
  },

  /**
   * Get market data for a specific token type.
   * @param {String} collection - the target parent collection for the type
   * @param {String} tokenType - the target type for which data should be retrieved
   */
  marketDataByType: (state) => (collection, tokenType) => {
    if (!state.marketData[collection]) return {}
    return state.marketData[collection].types.find((type) => {
      return type.id === tokenType
    })
  },

  /**
   * Get market data for a specific primary type, and sum all data if specified
   * @param {String} collection - the target parent collection for the type
   * @param {String} typePrimary - the target primary type
   * @param {Boolean} sum - should the data be consolidated?
   */
  marketDataByTypePrimary: (state) => (collection, typePrimary, sum = false) => {
    // console.log(`Filtering ${collection} by ${typePrimary}.`)
    if (!state.marketData[collection]) return []
    const types = state.marketData[collection].types.filter((type) => {
      return type.typePrimary.id === typePrimary
    })
    if (sum) {
      let lowest = null
      let onMarket = 0
      let cap = 0
      let highestSale = null
      types.forEach((type) => {
        onMarket += type.onMarket
        cap += type.cap
        if (type.lowest) {
          const low = new BigNumber(type.lowest)
          if (!lowest || low.isLessThan(lowest)) {
            lowest = low
          }
        }
        if (type.highestSale) {
          const high = new BigNumber(type.highestSale)
          if (!highestSale || high.isGreaterThan(highestSale)) {
            highestSale = high
          }
        }
      })
      const summed = {
        onMarket: onMarket,
        cap: cap,
        lowest: lowest ? lowest.toFixed() : 0,
        highestSale: highestSale ? highestSale.toFixed() : 0
      }
      return summed
    }
    return types
  },

  /**
   * Get all primary types in target collection.
   * @param { String } collection - The ID of the target collection
   * @returns an array of objects representing the primary types.
   */
  primaryTypes: (state) => (collection, seriesId = null, includeAll = true) => {
    if (!state.marketData[collection]) return []
    const primaryTypes = []
    if (includeAll) primaryTypes.push({ name: 'Full Collection', id: 'all' })
    state.marketData[collection].types.forEach((type) => {
      let alreadyAdded = false
      primaryTypes.forEach((pType) => {
        if (pType && pType.id === type.typePrimary.id) {
          alreadyAdded = true
        }
      })
      if (!alreadyAdded) {
        if (!seriesId || seriesId === type.series?.id) {
          primaryTypes.push(type.typePrimary)
        }
      }
    })
    return primaryTypes
  },

  /**
   * Get all secondary types for a primary type in target collection.
   * @param { String } collection - The ID of the target collection
   * @param { String } typePrimary - The target primary type
   * @returns an array of objects representing the secondary types.
   */
  secondaryTypes: (state) => (collection, typePrimary, includeAll = true) => {
    if (!state.marketData[collection]) return []
    const secondaryTypes = []
    if (includeAll) secondaryTypes.push({ name: 'All Variants', id: 'all' })
    state.marketData[collection].types.forEach((type) => {
      if (type.typePrimary.id === typePrimary) {
        secondaryTypes.push(type.typeSecondary)
      }
    })
    return secondaryTypes
  }
}

const mutations = {
  updateMarketData (state, payload) {
    state.marketData[payload.target] = payload.val
    state.marketData = { ...state.marketData }
  },
  setMarketListenerUnsub (state, payload) {
    state.marketCollectionListenersUnsub[payload.target] = payload.val
  },
  setMarketListenersActive (state, val) {
    state.marketListenersActive = val
  }
}

const actions = {
  /**
   * Sets up listeners for each subcollection in the market-data collection
   */
  async marketCollectionsListeners ({ commit, state }) {
    if (state.marketListenersActive) {
      console.log('Market listeners set up. Returning.')
      return
    }
    console.log('Setting up market collections listener.')
    const collections = await getDocs(query(fb.marketData))
    collections.forEach((coll) => {
      const unsub = onSnapshot(collection(fb.db, 'market-data', coll.id, 'types'), async (marketDataSnapshot) => {
        // Also get higher level collection data
        const collectionMarketData = await getDoc(doc(fb.db, 'market-data', coll.id))
        const collectionData = collectionMarketData.data()
        const types = []
        marketDataSnapshot.forEach((type) => {
          const copy = type.data()
          copy.id = type.id
          types.push(copy)
        })
        // Sort by order
        types.sort((a, b) => { return a.typeSecondary.order - b.typeSecondary.order })
        collectionData.types = types
        commit('updateMarketData', {
          target: coll.id,
          val: collectionData
        })
      })
      commit('setMarketListenerUnsub', {
        target: coll.id,
        val: unsub
      })
    })
    commit('setMarketListenersActive', true)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
