<template>
  <BlkIcon v-bind="$attrs" viewBox="0 0 19 20">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 7.23926C9.20862 7.23926 8.5422 7.83144 8.4508 8.61738C8.35862 9.40332 8.87034 10.1338 9.63986 10.3158V12.6908C8.87032 12.8736 8.35862 13.6041 8.4508 14.39C8.54221 15.176 9.20862 15.7689 10 15.7689C10.7914 15.7689 11.4571 15.1768 11.5492 14.39C11.6414 13.6041 11.1305 12.8736 10.3602 12.6908V10.3158C11.1297 10.1338 11.6414 9.40332 11.5492 8.61738C11.4578 7.83144 10.7914 7.23926 10 7.23926ZM10.8399 14.2073C10.8399 14.5471 10.6352 14.8534 10.3211 14.9838C10.0078 15.1135 9.6461 15.0416 9.40626 14.8018C9.16564 14.5612 9.09376 14.2002 9.22423 13.8862C9.35392 13.5721 9.66017 13.3674 10 13.3674C10.4641 13.3682 10.8399 13.744 10.8399 14.2072L10.8399 14.2073ZM10 9.63926C9.66018 9.63926 9.35392 9.43458 9.22424 9.1205C9.09378 8.80722 9.16565 8.4455 9.40628 8.20566C9.64612 7.96504 10.0078 7.89316 10.3211 8.02363C10.6352 8.15331 10.8399 8.45957 10.8399 8.79941C10.8399 9.26347 10.4641 9.63925 10 9.63925L10 9.63926Z" fill="white"/>
        <path d="M18.0172 3.11133H1.98277C1.44059 3.11211 1.00073 3.55195 0.99995 4.09493V15.9043C1.00073 16.4473 1.44057 16.8871 1.98277 16.8879H18.0172C18.5601 16.8871 18.9992 16.4473 19 15.9043V4.09493C18.9992 3.55197 18.5601 3.11211 18.0172 3.11133ZM18.2804 5.97073C18.2781 8.78553 15.3686 10.8145 11.0796 11.1059V11.8278C14.4718 11.6067 17.082 10.3426 18.2804 8.44821V15.9044C18.2797 16.0497 18.1625 16.1677 18.0172 16.1677H1.98277C1.83746 16.1677 1.72027 16.0497 1.72027 15.9044V8.44821C2.91793 10.3427 5.52887 11.6068 8.92027 11.8278V11.1059C4.63127 10.8145 1.72187 8.78553 1.72027 5.97073V4.09495C1.72027 3.94964 1.83746 3.83167 1.98277 3.83167H18.0172C18.1625 3.83167 18.2797 3.94964 18.2804 4.09495V5.97073Z" fill="white"/>
        </svg>
  </BlkIcon>
</template>

<script>
export default { inheritAttrs: false }
</script>
