<template>
  <button class="button standard moonpay" @click="showMoonPayModal('buy')">
    <p>Top-Up With</p>
    <img src="@/assets/icons/moonpay.svg" />
  </button>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  name: 'MoonPayTopUpButton',
  methods: {
    ...mapMutations([
      'setShowingMoonPay'
    ]),
    showMoonPayModal (mode) {
      this.setShowingMoonPay(mode)
    }
  }
}
</script>

<style lang="scss" scoped>
button {
  margin-top: $space-m;
  display: flex;
  justify-content: center;

  p {
    margin-right: 5px;
  }
}
</style>
