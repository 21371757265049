<template>
  <BlkIcon v-bind="$attrs" viewBox="0 0 24 24">
    <rect x="0.475586" y="0.000488281" width="5.05243" height="5.05243" fill="white"/>
    <rect x="0.475586" y="6.31641" width="5.05243" height="5.05243" fill="white"/>
    <rect x="6.79102" y="0.000488281" width="5.05243" height="5.05243" fill="white"/>
    <rect x="6.97168" y="6.31641" width="5.05243" height="5.05243" fill="white"/>
    <rect x="0.475586" y="12.6313" width="5.05243" height="5.05243" fill="white"/>
    <rect x="0.475586" y="18.9473" width="5.05243" height="5.05243" fill="white"/>
    <rect x="6.97168" y="12.6313" width="5.05243" height="5.05243" fill="white"/>
    <rect x="6.97168" y="18.9473" width="5.05243" height="5.05243" fill="white"/>
    <rect x="13.1069" y="0.000488281" width="5.05243" height="5.05243" fill="white"/>
    <rect x="13.1069" y="6.31641" width="5.05243" height="5.05243" fill="white"/>
    <rect x="19.603" y="0.000488281" width="5.05243" height="5.05243" fill="white"/>
    <rect x="19.603" y="6.31641" width="5.05243" height="5.05243" fill="white"/>
    <rect x="13.1069" y="12.6313" width="5.05243" height="5.05243" fill="white"/>
    <rect x="13.1069" y="18.9473" width="5.05243" height="5.05243" fill="white"/>
    <rect x="19.603" y="12.6313" width="5.05243" height="5.05243" fill="white"/>
    <rect x="19.603" y="18.9473" width="5.05243" height="5.05243" fill="white"/>
  </BlkIcon>
</template>

<script>
export default { inheritAttrs: false }
</script>
