<template>
  <BlkIcon v-bind="$attrs" viewBox="0 0 24 24">
    <rect x="0.237305" y="0.000488281" width="11.368" height="11.368" fill="white"/>
    <rect x="12.8687" y="0.000488281" width="11.3682" height="11.3682" fill="white"/>
    <rect x="0.237305" y="12.6318" width="11.368" height="11.368" fill="white"/>
    <rect x="12.8687" y="12.6318" width="11.3682" height="11.3682" fill="white"/>
  </BlkIcon>
</template>

<script>
export default { inheritAttrs: false }
</script>
