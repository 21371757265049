export default {
  autoRotate: false,
  backgroundColor: '0x000000',
  model: {
    scale: 1 // 0.03 for card, box
  },
  camera: {
    position: {
      x: 0, // -1.2 for box
      y: 0,
      z: 10
    },
    fov: 85,
    near: 0.05,
    far: 20
  },
  ground: {
    disabled: true,
    position: {
      y: -0.5 // -0.2 for card, -0.5 for box
    },
    mirror: {
      color: '0x777777'
    },
    overlay: {
      color: '0x000000',
      opacity: 0.8
    }
  },
  controls: {
    minDistance: 0.1,
    maxDistance: 2,
    maxPolarAngle: 0.5
  },
  animation: {
    enabled: false,
    index: 0
  },
  postProcessing: {
    enabled: false,
    bloom: {
      enabled: false,
      threshold: 0,
      strength: 0,
      radius: 0
    },
    gammaCorrection: {
      enabled: false
    }
  },
  lights: [
    {
      color: '0x4e449b',
      intensity: 3,
      distance: 50,
      animate: false,
      animationRate: {
        x: 0.1,
        y: 0.3,
        z: 0.2
      },
      animationScale: {
        x: 30,
        y: 40,
        z: 30
      }
    },
    {
      color: '0x298acb',
      intensity: 3,
      distance: 50,
      animate: false,
      animationRate: {
        x: 0.1,
        y: 0.2,
        z: 0.3
      },
      animationScale: {
        x: 30,
        y: 40,
        z: 30
      }
    }
  ]
}
