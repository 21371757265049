<template>
  <div class="modal">
    <div class="hitbox" @click="closeModal"></div>
    <div class="content" :class="{ large: large, compact: compact }">
      <div class="top">
        <slot name="title">
          <Typography variant="pc-title-32 mobile-title-32">{{title}}</Typography>
        </slot>
      </div>
      <div class="banner blk-caption" v-if="subTitle">
        <slot name="subtitle-icon"></slot>
        <slot name="subtitle-content"></slot>
        <Typography
          v-if="!hasSubtitleContent"
          variant="pc-sub-16 mobile-sub-14"
        >
          {{subTitle}}
        </Typography>
      </div>
      <div class="central" :class="{ noTitle: noTitle }">
        <slot></slot>
      </div>
      <div v-if="!hideActions" class="actions">
        <button v-if="cancelLabel" class="standard modal-cancel" @click="modalCancel">
          <img src="@/assets/icons/close.svg" width="16" height="16" />
          {{cancelLabel}}
        </button>
        <button
          v-if="onConfirm && confirmLabel"
          class="standard modal-confirm"
          @click="onConfirm"
          :disabled="confirmDisabled"
        >
          <img src="@/assets/icons/check-icon.svg" width="16" height="16" />
          {{confirmLabel}}
        </button>
      </div>
      <div class="close-wrapper" @click="closeModal">
        <CloseIcon color="#f4f4f4" class="close" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  created () {
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') this.closeModal()
    })
  },
  methods: {
    closeModal () {
      if (this.noClose) {
        this.$emit('attemptClose')
        return
      }
      if (this.onCancel) {
        this.onCancel()
      }
      this.$emit('close')
    },
    modalCancel () {
      if (this.onCancel) {
        this.onCancel()
      }
      this.cancelAction ? this.$emit(this.cancelAction) : this.$emit('close')
    }
  },
  props: {
    title: {
      type: String,
      default: null
    },
    subTitle: {
      type: String,
      default: null
    },
    cancelLabel: {
      type: String,
      default: 'Cancel'
    },
    cancelAction: {
      type: String,
      default: ''
    },
    onCancel: {
      type: [Function, Object],
      default: null
    },
    noClose: {
      type: Boolean,
      default: false
    },
    confirmLabel: {
      type: String,
      default: 'Confirm'
    },
    onConfirm: {
      type: [Function, Object],
      default: null
    },
    confirmDisabled: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    },
    compact: {
      type: Boolean,
      default: false
    },
    hideActions: {
      type: Boolean,
      default: false
    },
    noTitle: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasSubtitleContent () {
      return !!this.$slots['subtitle-content'] && !!this.$slots['subtitle-content'][0]
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  @include fill-screen;
  z-index: $z-modal;
  background-color: transparentize($color: $blk-grey-6, $amount: 0.1);

  @include media($bp-phone-l) {
    display: flex;
    justify-content: center;
  }

  .hitbox {
    @include fill-parent;
  }

  .content {
    @include center-in-parent;
    text-align: center;
    width: 100%;
    max-width: $modal-max;
    background-color: $blk-black;
    border: solid 1px white;
    border-radius: $space-ms;
    color: $blk-white;
    overflow: hidden;

    &.large {
      max-width: 550px;
    }
    &.compact .central {
      min-height: 0;
    }

    .top {
      padding: $space-xl $space-ms $space-ms $space-ms;
    }

    @include media($bp-phone-l) {
      position: fixed;
      left: 50%;
      top: auto;
      transform: translateX(-50%);
      bottom: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom: none;
    }

    @include media-height(720px) {
      .data-table {
        // margin-bottom: 0;
        tr td {
          padding: $space-xs 0;
        }
      }
    }
  }

  .close-wrapper {
    cursor: pointer;
    position: absolute;
    top: $space-m;
    right: $space-m;

    // Close button hitbox
    &::after {
      @include center-in-parent;
      content: '';
      width: 200%;
      height: 200%;
    }
  }

  .modal-bubble {
    border: solid 1px $blk-white;
    border-radius: 100px;
    padding: $space-s $space-ms;
    font-size: $font-size-xs;
    .left, .right {
      display: inline-block;
      width: 50%;
    }
    .left {
      text-align: left;
    }
    .right {
      text-align: right;
      color: $blk-grey-3;
    }
  }

  button.standard.option {
    @include caption;
    font-weight: bold;
    width: 100%;
    border: solid 1px $blk-white;
    border-radius: 4px;
    padding: $space-s $space-ms;
    color: $blk-white;
    margin-bottom: $space-ms;

    @include media($bp-phone-l) {
      font-size: 16px;
    }
  }

  .banner {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $space-ms;
    background-color: transparentize($color: $blk-grey-5, $amount: 0.75);
    color: white;

    .avatar {
      margin-right: 20px;

      &.external {
        border: none;
        border-radius: unset;
      }
    }

    &:after {
      width: 0;
      height: 0;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-top: 15px solid transparentize($color: $blk-grey-5, $amount: 0.75);
      left: calc(50% - 15px);
      position: absolute;
      bottom: -15px;
      content: "";
    }
  }

  .central {
    padding: $space-l;
    min-height: 300px;

    @include media($bp-phone-l) {
      padding: $space-m $space-ms;
    }
    @include media-height(720px) {
      // padding: $space-m $space-ms 0;
    }
    &.noTitle {
      padding-top: 0;
    }
    .message {
      display: flex;
      align-items: center;
      font-size: 12px;
      margin: $space-ml 0;

      p {
        display: inline-block;
        vertical-align: middle;
      }

      img.icon {
        display: inline-block;
        vertical-align: middle;
        height: 18px;
        margin-right: 8px;
      }
    }

    .modal-confirm {
      @include caption;
      color: $blk-grey-2;
      background-color: $blk-grey-6;
      border: dashed 1px $blk-grey-2 !important;
      padding: $space-xs $space-ml;
      margin: 0 10px;
      width: auto;
    }
  }
  .actions {
    display: flex;
    justify-content: center;
    padding: $space-ms $space-ms $space-l;

    @include media($bp-phone-l) {
      padding-left: 0;
      padding-right: 0;
    }

    @include media-height(720px) {
      padding: $space-s 0 $space-m;
    }

    .modal-cancel,
    .modal-confirm {
      @include caption;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      border: solid 1px $blk-white !important;
      color: $blk-white;
      border-radius: 4px;
      padding: $space-xs * 1.5 $space-ml;
      flex-grow: 1;
      max-width: 50%;
      font-size: 18px;
      img {
        margin-right: 8px;
      }
    }

    .modal-cancel {
      & + .modal-confirm {
        margin-left: 10px;
      }
    }

    .modal-confirm:disabled {
      border: solid 1px $blk-grey-4 !important;
      background-color: $blk-grey-6;
      color: $blk-grey-4;
      img {
        opacity: 0.5;
      }
    }
  }
}
</style>
