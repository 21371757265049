<template>
  <Modal
    hideActions
    noTitle
    class="token-detail-modal"
    @close="closeDetails"
  >
    <div v-if="targetTypeData" class="intro">
      <h4 class="issuance">#{{ issuance }} of {{ targetTypeData.cap }}</h4>
      <p v-if="marketCollection">{{ marketCollection.name }}</p>
      <p>{{ targetTypeData.name }}</p>
    </div>
    <table class="data-table">
      <tr>
        <td>Owner</td>
        <td>
          <div v-if="owner" class="owner-wrapper">
            <Avatar
              :size="30"
              :seed="owner.nearId"
              :image="owner.image ? dynamicLink(owner.image, 'ar_1.0,c_fill,w_40') : ''"
            />
            <div class="owner-name">{{ owner.name }}</div>
          </div>
        </td>
      </tr>
      <tr>
        <td>Ask</td>
        <td>
          <CryptoFiat :crypto="targetToken.conditions[0].price" />
        </td>
      </tr>
    </table>
    <div class="social">
      <SocialActions
        v-if="targetTypeData"
        inverted
        :mediaForDownload="targetTypeData.video || targetTypeData.image"
        :mediaName="targetTypeData.name"
      />
      <button
        class="standard option buy-button"
        @click="attemptPurchase"
        :disabled="userProfile && targetToken.seller === userProfile.nearId"
      >
        Buy Now
        <CryptoFiat :crypto="targetToken.conditions[0].price" hideUsd />
      </button>
    </div>
    <TokenHistoryTable
      inverted
      compact
      showAll
      :token="targetToken.tokenId"
      :contract="targetToken.nftContract"
    />
  </Modal>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
  async created () {
    try {
      await this.getTargetTypeData({
        type: this.targetToken.tokenType,
        contract: this.$route.params.collection
      })
    } catch (err) {
      console.log(err)
      this.addNotification('Error getting token info.', 'error')
    }
    try {
      const user = await this.getUserByNearId(this.targetToken.seller)
      this.owner = user
    } catch (err) {
      this.addNotification('Error getting owner info.', 'error')
    }
  },
  data () {
    return {
      owner: null
    }
  },
  computed: {
    ...mapGetters([
      'userProfile',
      'targetToken',
      'targetTypeData',
      'marketCollection',
      'globalSettings'
    ]),
    issuance () {
      if (!this.targetToken.tokenId) return ''
      const segments = this.targetToken.tokenId.split('.')
      return segments.pop()
    },
    listingData () {
      const listingData = {
        chainId: process.env.VUE_APP_NEAR_NETWORK,
        marketContract: this.globalSettings ? this.globalSettings.marketContract : '',
        tokenContract: this.targetToken.nftContract,
        tokenId: this.targetToken.tokenId,
        seller: this.targetToken.seller,
        prices: [
          {
            ftContract: 'near',
            price: this.targetToken.conditions[0].price
          }
        ]
      }
      console.log('Listing data: ', listingData)
      return listingData
    }
  },
  methods: {
    ...mapActions([
      'getTargetTypeData',
      'getUserByNearId',
      'initiatePurchase'
    ]),
    ...mapMutations([
      'setShowingTokenDetails'
    ]),
    attemptPurchase () {
      console.log('Initiating purchase with data:')
      console.log(this.listingData)
      this.initiatePurchase(this.listingData)
    },
    closeDetails () {
      this.$router.replace({ 'query.token': null })
      this.setShowingTokenDetails(false)
    }
  }
}
</script>

<style lang="scss">
.modal.token-detail-modal .central {
  .intro {
    margin-bottom: $space-m;
    .issuance {
      margin-bottom: $space-ml;
    }
    p {
      @include caption;
    }
  }
  .data-table {
    .owner-wrapper {
      justify-content: flex-end;
      .owner-name {
        max-width: calc(100% - 45px);
      }
    }
  }
  .social {
    display: flex;
    align-items: center;
    margin-bottom: $space-m;
    .icons {
      flex: 1;
      text-align: left;
      a {
        text-decoration: none;
        padding-right: $space-s;
      }
    }
    button.buy-button {
      flex: 2;
      margin-bottom: 0 !important;
    }
  }
  .token-history-table {
    // Fixes a strange see-through header issue on Chrome
    .v-data-table-header {
      &::before {
        z-index: 10;
        content: '';
        position: absolute;
        top: -1px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: $blk-grey-6;
      }
    }
  }
}
</style>
