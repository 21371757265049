<!--
  Header layout element commonly used in Distro views.
-->
<template>
  <div class="item-header">
    <div
      class="icon token-icon"
      :style="`background-image: url(${headerImage})`"
    />
    <div class="info">
      <h3 class="accented">{{ data ? data.name : 'Token Name' }}</h3>
      <p>{{ data ? data.description : 'Token Description' }}</p>
    </div>
    <div class="actions">
      <button class="text" @click="backAction">
        <BackIcon color="#f4f4f4" />
        {{ backLabel }}
      </button>
      <button v-if="settingsLabel" class="text">
        <SettingsIcon />
        {{ settingsLabel }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ItemHeader',
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    backLabel: {
      type: String,
      default: 'Back'
    },
    settingsLabel: {
      type: String,
      default: ''
    }
  },
  computed: {
    headerImage () {
      return this.$route.params.type
        ? this.tokenImagePath(this.$route.params.collection, this.$route.params.type, 200)
        : this.collectionImagePath(this.$route.params.collection, 200)
    }
  },
  methods: {
    backAction () {
      this.$emit('back')
    }
  }
}
</script>

<style lang="scss" scoped>
.item-header {
  display: flex;
  padding: 0 0 $space-xl;
  @include media($bp-tablet) {
    padding: 0;
  }
  .icon {
    @include bg-cover-center;
    width: 172px;
    min-width: 172px;
    height: 172px;
    display: inline-block;
    border-radius: $radius-ml;
  }
  .info {
    padding: 0 $space-m;

    h3 {
      margin-bottom: $space-ml;
    }
  }
  .actions {
    padding: 0;
    margin-left: auto;
    button {
      display: block;
      margin-bottom: $space-xs;
      white-space: nowrap;
    }
  }
}
</style>
