<!--
  Maintains various social functions associated with viewing NFTs.
-->
<template>
  <div class="icons" :class="{ inverted: inverted }">
    <a :href="`https://twitter.com/intent/tweet?text=Check%20out%20this%20NFT&url=${currentPage}`" target="_blank">
      <v-icon>mdi-twitter</v-icon>
    </a>
    <v-icon v-if="mediaForDownload" @click="downloadMedia">mdi-instagram</v-icon>
  </div>
</template>

<script>
import filedownload from 'js-file-download'
export default {
  name: 'SocialActions',
  props: {
    /**
     * Use an inverted color scheme (light icons)?
     */
    inverted: {
      type: Boolean,
      default: false
    },
    /**
     * The media to download for Instagram sharing.
     */
    mediaForDownload: {
      type: String,
      default: ''
    },
    /**
     * The name of the file to download for Instagram sharing.
     */
    mediaName: {
      type: String,
      default: 'download'
    }
  },
  computed: {
    currentPage () {
      const collection = this.$route.params.collection
      let primary = this.$route.params.primary
      let secondary = this.$route.params.secondary
      const token = this.$route.params.token
      if (token) {
        primary = token.split('.')[0]
        secondary = token.split('.')[1]
      }
      return `${window.location.origin}/market/${collection}/${primary}/${secondary}`
    }
  },
  methods: {
    /**
     * Download target NFT media for Instagram sharing.
     */
    async downloadMedia () {
      const target = this.mediaForDownload
      try {
        const res = await this.$axios.get(target, {
          responseType: 'blob'
        })
        const ext = res.data.type.split('/')[1]
        filedownload(res.data, `${this.mediaName}.${ext}`)
      } catch (err) {
        console.log(err)
        this.addNotification('Error downloading media.', 'error')
      }
    }
  }
}
</script>
