/**
 * Helper function which determines whether or not a link in the global navigation should appear "active"
 * @param {string} routeName - the currently active route, as reported by vue-router
 * @param {string} linkName - the route which should be marked active when true
 * @returns true or false, based on whether or not the menu item should be active
 */
const isCurrentRoute = (routeName, linkName) => {
  const matchers = {
    profile: ['profile'],
    about: ['about'],
    portfolio: ['portfolio'],
    market: ['market', 'collection'],
    drops: ['drops']
  }

  if (matchers[linkName]) {
    const lowerCasedRouteName = routeName ? routeName.toLowerCase() : ''
    return matchers[linkName].some((matcher) =>
      lowerCasedRouteName.startsWith(matcher)
    )
  }

  return false
}

/**
 * Simple regex email validation
 * @param {string} email - the string to validate
 * @returns whether or not the email is valid
 */
const isEmail = (email) => {
  return /^\S+@\S+\.\S+$/.test(email.toLowerCase())
}

/**
 * This function takes a flat list of probabilities which should add up to 100%,
 * and converts to a list of "stepped" probabilities
 * i.e.
 * 25% 25% 25% 25% becomes 25, 50, 75, 100
 * 33% 33% 33% 1% becomes 1, 34, 67, 100
 */
function formatProbabilities (chances) {
  // Create a copy of the input chances object
  const formatted = JSON.parse(JSON.stringify(chances))
  // Keep track of the number of counts of each chance (in case there are multiple chances with the same value)
  const counts = {}
  // Compare each chance to every other chance...
  for (const cI in chances) {
    const currentChance = chances[cI].chance
    for (const cJ in chances) {
      const comparedChance = chances[cJ].chance
      // When a chance is greater than what it's compared to, add the comparison to it
      if (currentChance > comparedChance) {
        formatted[cI].chance += comparedChance
      }
    }
    // If this probability has already occured, offset by the amount * number of previous occurences
    if (counts[currentChance]) {
      formatted[cI].chance += (currentChance * counts[currentChance])
    }
    // Increment the count
    counts[currentChance] ? counts[currentChance]++ : counts[currentChance] = 1
  }
  return formatted
}

/**
 * Generates "chancesFormatted" from the chances defined by the user in ManagePromotions.vue
 * @param {object} chances - the object which defines the probabilities for all types in the promotion
 * @returns an object which includes the formatted probabilities for each type
 */
const formatChances = (chances) => {
  // Format the primary and secondary type chances using the formatProbabilities subroutine
  const chancesFormatted = formatProbabilities(chances)
  for (const primary in chancesFormatted) {
    chancesFormatted[primary].secondary = formatProbabilities(chancesFormatted[primary].secondary)
  }
  return chancesFormatted
}

module.exports = {
  isCurrentRoute,
  isEmail,
  formatChances
}
