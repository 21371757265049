<template>
  <div class="modal-container">
    <transition name="fade">
      <div v-if="!signing" class="moonpay-modal">
        <div class="header">
          <div class="header-content">
            <img
              v-if="globalSettings && globalSettings.useLogo"
              :src="logoUrl()"
              class="logo"
            />
            <h1 v-else class="logo">
              {{ globalSettings && globalSettings.marketName }}
            </h1>
            <div class="buy-tagline">
              <p>{{ moonPayMode == 'buy' ? 'Buy' : 'Sell' }} <strong>NEAR</strong> Tokens instantly with</p>
              <img src="@/assets/images/moonpay_logo.svg" />
            </div>
          </div>
        </div>
        <iframe
          v-if="moonPayUrl"
          allow="accelerometer; autoplay; camera; gyroscope; payment"
          frameborder="0"
          height="100%"
          :src="moonPayUrl"
          width="100%"
        >
          <p>Your browser does not support the iframe element.</p>
        </iframe>

        <div class="close-wrapper" @click="closeModal">
          <CloseIcon color="black" class="close" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
const MOONPAY_CURRENCY = 'near'
const REDIRECT_URL = encodeURIComponent(`${window.location.origin}/about?modal=moonPayUnsupported`)
export default {
  name: 'MoonPayModal',
  data () {
    return {
      signing: true,
      moonPayUrl: ''
    }
  },
  async created () {
    this.setLoading(true)
    const targetUrl = this.moonPayMode === 'buy' ? this.moonPayBuyUrl : this.moonPaySellUrl
    try {
      const res = await this.fbCall('signMoonPayUrl', targetUrl)
      this.moonPayUrl = res.data
    } catch (err) {
      console.log(err)
      this.addNotification('Error signing MoonPay URL', 'error')
    }
    this.signing = false
    this.setLoading(false)
  },
  computed: {
    ...mapGetters([
      'globalSettings',
      'userProfile',
      'currentUser',
      'moonPayMode'
    ]),
    /**
     * Formats the user email for a URL
     */
    urlEmail () {
      return this.currentUser.email.replace('@', '%40')
    },
    /**
     * Computes MoonPay widget buy url from environment variables and authenticated user.
     */
    moonPayBuyUrl () {
      const url =
        process.env.VUE_APP_MOONPAY_BUY_URL +
        `?apiKey=${process.env.VUE_APP_MOONPAY_API_KEY}` +
        `&currencyCode=${MOONPAY_CURRENCY}` +
        `&walletAddress=${this.userProfile.nearId}` +
        // `&email=${this.urlEmail}` +
        `&unsupportedRegionRedirectUrl=${REDIRECT_URL}` +
        '&skipUnsupportedRegionScreen=true'
      return url
    },
    /**
     * Computes MoonPay widget sell url from environment variables and authenticated user.
     */
    moonPaySellUrl () {
      const url =
        process.env.VUE_APP_MOONPAY_SELL_URL +
        `?apiKey=${process.env.VUE_APP_MOONPAY_API_KEY}` +
        `&baseCurrencyCode=${MOONPAY_CURRENCY}` +
        `&refundWalletAddress=${this.userProfile.nearId}` +
        // `&email=${this.urlEmail}` +
        `&unsupportedRegionRedirectUrl=${REDIRECT_URL}` +
        '&skipUnsupportedRegionScreen=true'
      return url
    }
  },
  methods: {
    ...mapMutations([
      'setLoading',
      'setShowingMoonPay'
    ]),
    closeModal () {
      this.setShowingMoonPay(false)
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.moonpay-modal {
  z-index: 200;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  max-width: $modal-max;
  height: 80vh;
  background-color: white;
  border-radius: $space-ms;
  overflow: hidden;

  @include media($bp-phone-l) {
    transform: translateX(-50%);
    height: 90vh;
    top: auto;
    bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .header {
    position: relative;
    height: 25%;
    .header-content {
      @include center-in-parent;
      width: 100%;
      text-align: center;
      color: black;
      font-size: $font-size-xs;
      padding: $space-ms $space-ms 0;
      .buy-tagline {
        margin-top: $space-s;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        p {
          white-space: nowrap;
        }
        img {
          transform: translateY(-7%);
        }
      }
    }
    .logo {
      filter: invert(1);
      height: 40px;
    }
  }

  iframe {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 75%;
  }
}
.close-wrapper {
  cursor: pointer;
  position: absolute;
  top: $space-ms;
  right: $space-ms;
}
</style>
