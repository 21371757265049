<!--
  Used to display an NFTs media.
  The media player prioritizes video, and falls back to an image if none is available.
-->
<template>
  <div class="media-container" :class="{ square: square }">
    <div class="media">
      <video
        v-if="videoPath"
        ref="tokenVideo"
        :src="videoPath"
        :autoplay="autoplay"
        loop
        muted
        controls
        playsinline
      ></video>
      <SpatialViewer
        v-else-if="modelPath"
        :src="modelPath"
        ref="spatialViewer"
        :square="!mode || (mode !== 'portfolio' && mode !== 'fullscreen')"
        :settings="viewerSettings"
        :background="background"
      />
      <img
        v-else
        :src="imagePath"
        @click="viewInstance"
      />
    </div>
    <div v-if="showUI" class="ui">
      <router-link :to="`/viewer/${$route.params.collection}/${$route.params.token}`" class="view-fullscreen">
        <v-icon>mdi-overscan</v-icon>
      </router-link>
    </div>
  </div>
</template>

<script>
import default3dViewerSettings from '../../common/default3dViewerSettings'
export default {
  name: 'MediaContainer',
  created () {
    this.$root.$on('media-play', () => {
      const tv = this.$refs.tokenVideo
      if (tv) tv.pause()
    })
  },
  beforeDestroy () {
    // Remove the listener, otherwise it will be triggered multiple times upon returning.
    this.$root.$off('media-play')
  },
  props: {
    /**
     * The path to the image to display.
     */
    imagePath: {
      type: String,
      default: ''
    },
    /**
     * The path to the video to display.
     */
    videoPath: {
      type: String,
      default: ''
    },
    /**
     * Path to a 3D model to display using three.js
     */
    modelPath: {
      type: String,
      default: ''
    },
    /**
     * In the case of a video media, should the media automatically play on load?
     */
    autoplay: {
      type: Boolean,
      default: false
    },
    /**
     * Should the media player occupy the same width / height, or should its size be determined by the media?
     */
    square: {
      type: Boolean,
      default: false
    },

    mode: {
      type: String,
      default: ''
    },

    background: {
      type: Boolean,
      default: false
    },

    showUI: {
      type: Boolean,
      default: false
    },

    viewerSettings: {
      type: Object,
      default: () => default3dViewerSettings
    }
  },
  computed: {
    cloudinaryRoot () {
      return process.env.VUE_APP_CLOUDINARY_ROOT
    }
  },
  methods: {
    /**
     * The view event, generally used to link to another view of the associated NFT
     */
    viewInstance () {
      this.$emit('view')
    },
    refreshSpatialViewer () {
      this.$refs.spatialViewer.init()
    },
    toggleVideo () {
      const tv = this.$refs.tokenVideo
      if (tv.paused) {
        this.$root.$emit('media-play')
        tv.play()
      } else {
        tv.pause()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.media-container {
  position: relative;
  flex: 1 1 auto;
  display: flex;
  align-items: center;

  // 3D content should fill available space
  &.spatial-content {
    .media {
      height: 100%;
    }
  }
  .media {
    width: 100%;
    text-align: center;
  }
  &.square {
    padding-top: 100%;

    .media {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;

      img, video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  img {
    max-width: 100%;
  }
  video {
    width: 100%;
  }
  .ui {
    @include fill-parent;
    color: white;
    pointer-events: none;
    a,
    button {
      pointer-events: all;
    }
    .view-fullscreen {
      text-decoration: none;
      padding: $space-s;
      position: absolute;
      top: 0;
      right: 0;
      font-size: 0;
    }
  }
}
</style>
