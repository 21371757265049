<template>
  <div v-if="siteEnabled" class="links">
    <router-link to="/about">About</router-link>
    <router-link to="/1xfund">1XFUND</router-link>
    <router-link to="/privacy">Privacy</router-link>
    <router-link to="/terms">Terms</router-link>
  </div>
  <div v-else class="links">
    <a @click="initiateLogin">
      Login
    </a>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'FooterNavigation',
  computed: {
    ...mapGetters(['siteEnabled'])
  },
  methods: {
    ...mapActions(['initiateLogin'])
  }
}
</script>

<style lang="scss" scoped>
.links > a {
  padding: 12px;
  font-family: Drive Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 160%;
  color: #666666;
  text-transform: uppercase;
  text-decoration: none;
}
</style>
