<template>
  <Modal
    title="Welcome"
    subTitle="You Have Claims"
    hideActions
    :onCancel="setStorage"
    @close="setClaimsNotification(false)"
  >
    <p class="message">You have unclaimed tokens.<br>Be sure to visit your portfolio to confirm and claim your tokens.</p>
    <button class="standard option" @click="goToPortfolio">Go To Portfolio</button>
    <p class="action-link" @click="doLater">No thanks, I'll do it later.</p>
  </Modal>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  methods: {
    ...mapMutations([
      'setClaimsNotification'
    ]),
    doLater () {
      this.setStorage()
      this.setClaimsNotification(false)
    },
    goToPortfolio () {
      this.setClaimsNotification(false)
      this.setStorage()
      this.$router.push('/portfolio?viewing=claims')
    },
    setStorage () {
      window.sessionStorage.setItem('shownHasClaims', true)
    }
  }
}
</script>

<style lang="scss" scoped>
.modal .central .message {
  margin-top: 0 !important;
}
</style>
