<!--
  Displays a single token in the User's Portfolio or on the Market
-->
<template>
  <div class="token-card">
    <MediaContainer
      :imagePath="imagePath"
      :size="320"
      :square="square"
      @view="viewInstance"
    />
    <div v-if="tokenData" class="text-container" :class="{ 'large-mobile': largeOnMobile }">
      <div class="token-text" @click="viewInstance">
        <h4 v-if="tokenData.title" class="token-title" :class="{ break: breakTitle }">
          {{ tokenData.title }}
        </h4>
        <div class="issuance">
          <p class="secondary-type" v-if="tokenData.secondaryType">
            {{ tokenData.secondaryType }} {{ globalSettings.labels && globalSettings.labels.typeSecondary }}
          </p>
          <p
            v-if="!['series', 'collection-group', 'category', 'market', 'market-primary'].includes(mode)"
            class="default-hidden"
            :class="{ visible: tokenData.id }"
          >
            #{{ displayedId }}<span v-if="tokenData.issuance">/{{ tokenData.issuance }}</span>
          </p>

          <div
            v-if="mode == 'series' || mode == 'market' || mode == 'market-primary'"
            class="market-info"
          >
            <p v-if="globalSettings.useCollectionGroups && tokenData.collectionGroup">
              By
              <router-link :to="`/artists/${tokenData.collectionGroup.id}`">
                {{ tokenData.collectionGroup.name }}
              </router-link>
            </p>
            <p v-if="mode == 'market'">
              {{
                marketDataByType(
                  tokenData.tokenAddress,
                  `${tokenData.typePrimary.id}.${tokenData.typeSecondary.id}`).onMarket
              }} pieces on Market
            </p>
            <p v-if="mode == 'market-primary'">
              {{
                marketDataByTypePrimary(
                  tokenData.tokenAddress,
                  tokenData.typePrimary.id,
                  true
                ).onMarket
              }} pieces on Market
            </p>
            <p v-if="mode == 'series' && marketData && marketData.series && marketData.series[tokenData.id]">
              {{ marketData.series[tokenData.id].onMarket }} pieces on Market
            </p>
          </div>
        </div>
        <p v-if="media === 'collection' && capTotal && !['collection-group'].includes(mode)">
          Collection of {{ capTotal }} Tokens
        </p>
      </div>
      <router-link
        v-if="tokenData.id && !['collection-group', 'category'].includes(mode)"
        class="listed-indicator default-hidden"
        :class="{ visible: mode == 'portfolio' && listed }"
        :to="`/market/${tokenData.tokenAddress}/${tokenData.id.replaceAll('.', '/')}`"
      >
        On Market
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'TokenCard',

  props: {
    tokenData: {
      type: Object,
      default: () => {
        /**
         * {
         *  title: 'Token Name',
         *  id: '1234',
         *  collection: 'the.collection.id',
         *  issuance: 100
         * }
        */
      }
    },
    media: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: 'market'
    },
    listed: {
      type: Boolean,
      default: false
    },
    square: {
      type: Boolean,
      default: false
    },
    staggered: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: 0
    }
  },

  computed: {
    ...mapGetters([
      'totalInCollection',
      'marketData',
      'marketDataByType',
      'marketDataByTypePrimary',
      'globalSettings'
    ]),
    largeOnMobile () {
      return this.staggered && this.index % 5 === 0
    },
    breakTitle () {
      return this.wordBreakCheck(this.tokenData.title)
    },
    totalListed () {
      return this.totalInCollection(this.tokenData.id)
    },
    capTotal () {
      if (this.marketData && this.marketData[this.tokenData.id]) {
        return this.marketData[this.tokenData.id].cap
      } else {
        return null
      }
    },
    imagePath () {
      if (this.media === 'collection') {
        return this.collectionImagePath(this.tokenType, 360)
      }
      if (this.mode === 'series') {
        return this.tokenData.image
      }
      if ((this.mode === 'collection-group' || this.mode === 'market-primary') && this.tokenData.typePrimary.hasImage) {
        // If it's available, show the image for the primary type as a whole
        return this.primaryVariantImagePath(this.tokenData.tokenAddress, this.tokenData.typePrimary.id)
      }
      return this.tokenImagePath(this.tokenData.tokenAddress, this.tokenType, 360, true)
    },
    displayedId () {
      if (this.tokenData.id) {
        return this.formatIssuance(this.tokenData.id)
      } else {
        return null
      }
    },
    tokenType () {
      if (this.tokenData.id) {
        if (this.media !== 'collection') {
          const segments = this.tokenData.id.split('.')
          if (segments.length > 2) {
            segments.pop()
            return segments.join('.')
          } else {
            return this.tokenData.id
          }
        } else {
          return this.tokenData.id
        }
      } else {
        return null
      }
    }
  },

  methods: {
    viewInstance () {
      this.$emit('view')
    },
    toggleVideo () {
      const tv = this.$refs.tokenVideo
      tv.paused ? tv.play() : tv.pause()
    }
  }
}
</script>

<style lang="scss" scoped>
.token-card {
  // display: flex;
  // flex-flow: column;
  cursor: pointer;
  // height: 100%;
  // width: 100%;

  &.no-link {
    cursor: auto;
  }
}

.text-container {
  text-align: center;

  .token-title {
    font-family: $font-title;
    font-style: normal;
    font-weight: bold;
    font-size: $font-size-ml;
    line-height: 120%;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px auto 0;
    max-width: 280px;

    &.break {
      word-break: break-all;
    }

    @include desktop {
      height: 60px;
      margin-top: 25px;
    }
  }

  @include media($bp-phone-l) {
    &:not(.large-mobile) {
      .token-title {
        font-size: 14px;
        height: 44px;
        max-width: 160px;
      }
      .issuance {
        font-size: 12px;
      }
    }
  }

  .issuance {
    font-family: Drive Mono;
    font-size: 16px;
    color: $blk-grey-1;
    margin-bottom: 10px;

    @include desktop {
      line-height: 150%;
      margin: 20px;
    }
  }

  .secondary-type {
    text-transform: uppercase;
  }

  .market-info {
    text-transform: uppercase;
  }

  .listed-indicator {
    padding: 5px 10px;

    border: 2px solid #23F6A4;
    box-sizing: border-box;
    border-radius: 4px;

    font-family: Drive Mono;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-decoration: none;
    text-transform: uppercase;
    color: #23F6A4;
  }

  .token-text {
    font-size: $font-size-xs;
    p {
      line-height: 1.6em;
    }
  }

  .default-hidden {
    opacity: 0;
    pointer-events: none;

    &.visible {
      opacity: 1;
      pointer-events: all;
      transition: 0.3s all;
    }
  }

  a {
    color: inherit;
  }
}
</style>
